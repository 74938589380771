import _ from "lodash";

//         "event": {
//             "selectedTeamId": "11",
//             "competitionId": "1",
//             "competitionName": "Bundesliga",
//             "type": "match",
//             "homeTeamId": "11",
//             "awayTeamId": "20",
//             "side": "home",
//             "eventId": "1000",
//             "dateObj": "202130203023"
//         },
export const getEventFromMatchday = (matchday) => {
  const event = {
    selectedTeamId: matchday.teamId,
    competitionId: matchday.competitionId,
    competitionName: matchday.competitionName,
    type: "match",
    homeTeamId: matchday.home.id,
    awayTeamId: matchday.away.id,
    eventId: matchday.eventId,
    date: matchday.date,
    time: matchday.time,
    start_date: matchday.start_date,
    stage: matchday?.stage,
    home: matchday?.home,
    away: matchday?.away,
    ...matchday,
  };

  let side = "home";

  if (event.awayTeamId == event.selectedTeamId) {
    side = "away";
  }

  _.set(event, "side", side);

  return event;
};

export const getEventFromMatchdayHeimspiel = (matchday) => {
  const event = {
    ...matchday,
    teamId: matchday?.country.id,
  };

  delete event?.eventId
  return event;
};
