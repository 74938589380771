import { axios } from "../axios";
import _ from "lodash";

import {
  getEventFromMatchday,
  getEventFromMatchdayHeimspiel,
} from "../../components/shared/functions/getEventFromMatchday";

const getApiSetup = (setupId) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData = require("../../testData/api/apiSetup.json");
      resolve(testData);
    });
  }

  return axios({
    url: `/setup${setupId ? "/" + setupId : ""}`,
    method: "get",
  });
};

const getGlobalMatchday = () => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData = require("../../testData/api/globalMatchday.json");
      resolve(testData);
    });
  }

  return axios({
    url: "/events",
    method: "get",
  });
};

const getGlobalMatchdays = (params) => {
  const { cid, tid, mid, feedType } = params;

  if (process.env.MOCK_API) {
    if (_.isEmpty(cid) && _.isEmpty(tid)) {
      return new Promise((resolve) => {
        const testData = require("../../testData/api/globalMatchdays-comp.json");
        resolve(testData);
      });
    }

    if (_.isEmpty(tid)) {
      // console.log("globalMatchdays-teams-1");
      return new Promise((resolve) => {
        const testData = require("../../testData/api/globalMatchdays-teams-1.json");
        resolve(testData);
      });
    }

    return new Promise((resolve) => {
      // console.log("globalMatchdays-matchdays-fc-bayern");
      const testData = require("../../testData/api/globalMatchdays-matchdays-fc-bayern.json");
      resolve(testData);
    });
  }

  const paramsArray = [];

  paramsArray.push(feedType);

  if (cid) {
    paramsArray.push(cid);
  }
  if (tid) {
    paramsArray.push(tid);
  }
  if (mid) {
    paramsArray.push(mid);
  }

  return axios({
    url: `/events/${paramsArray.join("/")}`,
    method: "get",
  });
};

const getSelectedLiveData = (params) => {
  const { mid } = params;

  return axios({
    url: `/actions/livematch/${mid}`,
    method: "get",
  });
};

const setGlobalMatchdays = (selectedMatchday, feedType) => {
  // return axios.get(API_URL + "templates/all");

  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  if (!selectedMatchday) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  if (selectedMatchday) {
    // const currentEvent = {
    //   "selectedTeamId": selectedMatchday.teamId,
    //   "competitionId": selectedMatchday.competitionId,
    //   "competitionName": selectedMatchday.competitionName,
    //   "type": selectedMatchday.type,
    //   "homeTeamId": selectedMatchday.home.id,
    //   "awayTeamId": selectedMatchday.away.id,
    //   "eventId": selectedMatchday.eventId,
    //   "date": selectedMatchday.date,
    //   "time": selectedMatchday.time
    // };
    let parsedEvent = null;

    selectedMatchday.feedType = feedType;

    if (feedType === "heimspiel") {
      parsedEvent = getEventFromMatchdayHeimspiel(selectedMatchday);
    } else {
      parsedEvent = getEventFromMatchday(selectedMatchday);
    }

    return axios({
      url: `/setup/2`,
      method: "patch",
      data: {
        data: {
          event: parsedEvent,
        },
      },
    });
  }
};

const getTemplatesByMatchday = (mid) => {
  // return axios.get(API_URL + "templates/all");

  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData =
        require("../../testData/api/templatesByMatchday_1000.js").default;

      // console.log("testData getTemplatesByMatchday", testData);
      setTimeout(() => {
        resolve(testData);
      }, 200);
    });
  }

  return axios({
    url: "/templates",
    method: "get",
    params: {
      filter: {
        "event.eventId": mid,
      },
    },
  });
};

const getTemplatesByFilter = (filter, projection, sort) => {
  // return axios.get(API_URL + "templates/all");

  return axios({
    url: "/templates",
    method: "get",
    params: {
      filter: JSON.stringify(filter),
      projection: JSON.stringify(projection),
      sort: JSON.stringify(sort),
    },
  });
};

const getTimelinesByMatchday = (mid) => {
  // return axios.get(API_URL + "templates/all");
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData =
        require("../../testData/api/timelinesByMatchday_1000").default;
      setTimeout(() => {
        resolve({
          data: {
            docs: testData,
          },
        });
      }, 200);
    });
  }

  return axios({
    url: "/timelines",
    method: "get",
    params: {
      filter: {
        "event.eventId": mid,
      },
    },
  });
};

const getTimelinesBySeason = (filter, limit = 0) => {
  // return axios.get(API_URL + "templates/all");
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData =
        require("../../testData/api/timelinesByMatchday_1000").default;
      setTimeout(() => {
        resolve({
          data: {
            docs: testData,
          },
        });
      }, 200);
    });
  }

  return axios({
    url: "/timelines",
    method: "get",
    params: {
      filter: JSON.stringify(filter),
      limit: limit,
    },
  });
};

const getTemplateById = ({ id, template_name }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const testData = require("../../testData/api/templates/index").default;
      const templateData = _.cloneDeep(_.get(testData, template_name));

      if (id == 0) {
        templateData.uuid = "0";
      }

      resolve(templateData);
    });
  }

  // console.log("SEND QUERY", `/templates/${template_name}/${id}`);

  return axios({
    url: `/templates/${template_name}/${id}`,
    method: "get",
  });
};

const setTemplateById = ({ id, template_name, data }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(id);
    });
  }

  if (id == 0) {
    return axios({
      url: `/templates/${template_name}`,
      method: "post",
      data,
    });
  } else {
    return axios({
      url: `/templates/${template_name}/${id}`,
      method: "patch",
      data,
    });
  }
};

const apiPrepareMatchday = (data = {}) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axios({
    url: `/actions/prepareMatchday`,
    data,
    method: "post",
    timeout: 60 * 5 * 1000,
  });
};

const apiPrepareVideos = (data = {}) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axios({
    url: `/actions/syncMatchVideos`,
    data,
    method: "post",
    timeout: 60 * 10 * 1000,
  });
};

const apiSyncMatchdayTimelines = (data = {}) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axios({
    url: `/actions/syncMatchdayTimelines`,
    method: "post",
    data: data,
    timeout: 60 * 1 * 1000,
  });
};

const apiGetClosestMatch = ({ competitionId }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axios({
    url: `/actions/closestMatch/${competitionId}`,
    method: "get",
  });
};

const setApiSetup = ({ id, data }) => {
  return axios({
    url: `/setup/${id}`,
    method: "patch",
    data,
  });

  // if (id == 0) {
  //     return axios({
  //         url: `/setup/${setupId}`,
  //         method: 'post',
  //         data
  //     })
  // } else {
  //     return axios({
  //         url: `/setup/${setupId}`,
  //         method: 'post',
  //         data
  //     })
  // }
};

const setTimelineById = ({ uuid, data }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(uuid);
    });
  }

  if (!uuid) {
    return axios({
      url: `/timelines`,
      method: "post",
      data,
    });
  } else {
    return axios({
      url: `/timelines/${uuid}`,
      method: "patch",
      data,
    });
  }
};

const deleteTemplateById = ({ uuid }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(uuid);
    });
  }

  return axios({
    url: `/templates`,
    method: "delete",
    data: {
      uuid,
    },
  });
};

const deleteTimelineById = ({ uuid }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(uuid);
    });
  }

  return axios({
    url: `/timelines`,
    method: "delete",
    data: {
      uuid,
    },
  });
};

const createScreenshot = ({ id, template_name, actions }) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(id);
    });
  }

  return axios({
    url: `/screenshot`,
    method: "post",
    data: {
      actions: [...actions],
    },
  });
};

const uploadMedia = ({ uuid, data, group }) => {
  if (!uuid || uuid == 0) {
    // return axios({
    //   url: `/upload/multi/${group}`,
    //   method: "post",
    //   timeout: 60 * 5 * 1000,
    //   data,
    // });
    return axios({
      url: `/upload/${group}`,
      method: "post",
      timeout: 60 * 5 * 1000,
      data,
    });
  } else {
    return axios({
      url: `/upload/${uuid}/${group}`,
      method: "patch",
      timeout: 60 * 5 * 1000,
      data,
    });
  }
};

const deleteMediaByUuid = ({ uuid }) => {
  return axios({
    url: `/upload`,
    method: "delete",
    data: {
      uuid,
    },
  });
};

const getVideoProductionsByFilter = (filter, projection, sort) => {
  const body = {
    url: "/videos",
    method: "get",
    params: {
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    },
  };

  if (projection) {
    body.params.projection = JSON.stringify(projection);
  }
  return axios(body);
};

const getAuthorizedCompetitions = (filter, projection, sort) => {
  const body = {
    url: "/feeds/activated",
    method: "get",
  };

  return axios(body);
};

const getCompetitionTeams = (cid) => {
  const body = {
    url: `/feeds/teams/${cid}`,
    method: "get",
  };

  return axios(body);
};

const getAllUserStatus = () => {
  const body = {
    url: `/users/status`,
    method: "get",
  };

  return axios(body);
};

export {
  getApiSetup,
  setApiSetup,
  getGlobalMatchday,
  getGlobalMatchdays,
  getTemplatesByMatchday,
  getTemplatesByFilter,
  getTemplateById,
  getTimelinesByMatchday,
  setGlobalMatchdays,
  setTemplateById,
  setTimelineById,
  createScreenshot,
  deleteTemplateById,
  deleteTimelineById,
  uploadMedia,
  deleteMediaByUuid,
  getTimelinesBySeason,
  getVideoProductionsByFilter,
  apiPrepareMatchday,
  apiSyncMatchdayTimelines,
  apiGetClosestMatch,
  getSelectedLiveData,
  getAuthorizedCompetitions,
  getCompetitionTeams,
  apiPrepareVideos,
  getAllUserStatus,
};
