import _ from "lodash";
import parseMediaUrl from "./parseMediaUrl";

export default (serverAssets, templateAssets) => {
  const Images = [];

  _.each(_.get(serverAssets, "fields", []), (item) => {
    if (item.type === "media") {
      if (item?.value?.type === "image") {
        const imageUrl = _.get(item, "value.url");
        Images.push(parseMediaUrl(imageUrl));
      }
    }
  });

  // console.log("serverAssets", serverAssets);

  _.each(templateAssets, (item, key) => {
    // const imageUrl = _.get(item, "default");
    Images.unshift(item);
  });

  _.each(_.get(serverAssets, "dynamic.assets", {}), (item, key) => {
    const imageUrl = _.get(item, "url");
    Images.push(parseMediaUrl(imageUrl));
  });

  // console.log("##Images", Images);

  return Images;
};
