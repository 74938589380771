import _ from "lodash";

export default (props) => {
  const { item } = props;
  if (!_.isEmpty(item.data)) {
    return _.map(item.data, (text, textLabel) => {
      return (
        <div key={textLabel + item._id}>
          <span className={"label"}>{textLabel}</span>:{" "}
          <span className={"value"}>{text}</span>
        </div>
      );
    });
  } else {
    return (
      <div key={item.label + item._id}>
        <span className={"label"}>Label</span>:{" "}
        <span className={"value"}>{item.label}</span>
      </div>
    );
  }
};
