import React from "react";

import "./styles.scss";
import { PlayerTriko } from "../PlayerTriko/Index";
import arrowIn from "../../../assets/global/arrow-in.png";
import arrowOut from "../../../assets/global/arrow-out.png";
import goalIconWhite from "../../../assets/global/ball_white.png";
import goalIconRed from "../../../assets/global/ball_red.png";
import i18n from "i18next";

const Card = (props) => {
  return (
    <div className={props.type === "yellow" ? "card-yellow" : "card-red"}></div>
  );
};

const PitchTactics = React.forwardRef((props, ref) => {
  const {
    image,
    id,
    styles,
    formation = "442",
    players = [],
    trikot = null,
    pitch = null,
    teamId = null,
    side = "home",
    kits = null,
    cards = [],
    substitutes = [],
    goals = [],
  } = props;

  const lng = i18n?.language;
  const className = props.className || "";

  const mainLineup =
    players &&
    players.filter(
      (p) => p?.position !== "Substitute" && p?.formationPlace !== "1",
    );
  const goalKeeper = _.find(players, { formationPlace: "1" });

  //diamond formation fallback
  const finalFormation = formation === "343d" ? "31213" : formation;
  const totalLines = finalFormation.length;

  const getLines = () => {
    const outputLines = [];

    if (!mainLineup.length) {
      return outputLines;
    }

    let output = [];
    let currentLine = 0;
    let currentLinePlayers = 0;

    const gkLine = [goalKeeper];
    outputLines.push(gkLine);

    _.forEach(mainLineup, (p) => {
      let playersPerLine = parseInt(finalFormation[currentLine]);

      if (playersPerLine > currentLinePlayers) {
        output.push(p);
        ++currentLinePlayers;
      }

      if (playersPerLine === currentLinePlayers) {
        currentLinePlayers = 0;
        ++currentLine;
        outputLines.push(output);
        output = [];
      }
    });

    return outputLines.reverse();
  };

  const playerLines = getLines();

  return (
    <div id={id} ref={ref} className={`comp-pitch-tactics ${className}`}>
      <div className={`formation-block f-${formation} ft-${totalLines}`}>
        {pitch && (
          <div className={`lineup-pitch`}>
            <img src={pitch} />
          </div>
        )}
        {playerLines &&
          playerLines.map((line, lineIndex) => {
            return (
              <div
                key={`line-${++lineIndex}`}
                className={`line line-${++lineIndex}`}
              >
                {line.map((p) => {
                  const filteredCards = _.filter(cards, {
                    playerId: p?.playerId,
                  });
                  const substitute = _.find(substitutes, (e) => {
                    return (
                      e?.playerOffId === p.playerId ||
                      e?.playerOnId === p.playerId
                    );
                  });

                  let totalGoals = _.filter(goals, (goal) => {
                    return goal.scorerId === p?.playerId ? 1 : 0;
                  });

                  const isCaptain = Boolean(p?.captain);

                  return (
                    <div
                      key={`p-${p?.playerId}`}
                      className={`lineup-player fp-${p?.formationPlace}`}
                    >
                      <div className={"shirt-number"}>{p?.shirtNumber}</div>
                      {/*{trikot && <img src={trikot}/>}*/}
                      <PlayerTriko
                        className={"left-player"}
                        player={p}
                        teamId={teamId}
                        side={side}
                        bgColor={kits?.[side]?.colour1}
                      />
                      <div className={"player-name"}>
                        {p?.shortFirstName?.[0]}. {p?.shortLastName}{" "}
                        {isCaptain ? (lng === "de" ? "(K)" : "(C)") : ""}
                      </div>

                      {filteredCards?.length > 0 ? (
                        <Card
                          type={filteredCards?.length === 1 ? "yellow" : "red"}
                        />
                      ) : null}

                      {totalGoals?.length > 0 ? (
                        <div className={"goal-balls"}>
                          {_.map(totalGoals, (g, i) => {
                            if (i < 3) {
                              return (
                                <img
                                  src={
                                    g?.type === "OG"
                                      ? goalIconRed
                                      : goalIconWhite
                                  }
                                  className={`goal-icon ${g?.type === "OG" ? "own-goal" : ""}`}
                                />
                              );
                            }
                          })}
                        </div>
                      ) : null}

                      {substitute ? (
                        <div className={"substituted"}>
                          <img src={arrowIn} className={"arrow-pitch"} />
                          <img src={arrowOut} className={"arrow-pitch"} />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default PitchTactics;
