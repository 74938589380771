//

export default function Stats({ stats = [] }) {
  return (
    <div className="stats">
      {stats.map((stat) => {
        if (!stat) {
          return;
        }
        return (
          <div className="stat" key={stat.stat}>
            <div className="home value">{stat.home}</div>
            <div className="name green-glow">
              <span>{stat.stat}</span>
            </div>
            <div className="away value">{stat?.away}</div>
          </div>
        );
      })}
    </div>
  );
}
