import { useRef } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";

import "./style/style.scss";
import "./style/animation.scss";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import useAnimation from "../../../../shared/hooks/useAnimation";
import ProgressBar from "../../../../shared/components/ProgressBar";
import GreenGlow from "../../_partials/GreenGlow";
import InsightZoneLogo from "../../_partials/InsightZoneLogo";
import GoalLine from "./goalLine";
import Goals from "./goals";

const getMainBackground = () => {
  return assets.background_1920;
};

const Index = ({ animation, data, mode, format, _searchParams, ...props }) => {
  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const scoreCounter = { home: 0, away: 0 };
  const backgroundImage = getMainBackground();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useAnimation({
    mode,
    animation,
    id,
    animationScript,
    animationCleanUp,
    callback: prepareAnimation,
    apiSetup: props?.apiSetup,
    data
  });

  const { options, fields, dynamic } = data.data;

  const {
    matchDetails = {},
    goalShots,
    lineUp,
    matchInfo,
  } = data?.data?.dynamic;

  const subheadline = fields?.find(({ value }) => value === "subheadline");

  const matchStatus = matchDetails?.matchStatus;
  const scores = matchDetails?.scores;

  const homeTeam = data.event.home;
  const awayTeam = data.event.away;

  const homeKit = lineUp?.find(
    ({ contestantId }) => contestantId === homeTeam.id,
  )?.kit;
  const awayKit = lineUp?.find(
    ({ contestantId }) => contestantId === awayTeam.id,
  )?.kit;

  const goals = goalShots?.filter(({ typeId }) => typeId === 16);

  const goalsSortOrder = goals.map((goal, index) => {
    return {
      id: goal?.id,
      index,
    };
  });

  const competitionId = matchInfo?.competition?.id;

  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className="wc-content wc-content-v2">
          <div className="wc-bg-content" />
          <ProgressBar height="8px" />

          <div className="content">
            <div className="left-block-side">
              <MatchScoreWidget
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                scores={scores}
                matchStatus={matchStatus}
                matchDetails={matchDetails}
                competitionId={competitionId}
                matchInfo={matchInfo}
              />

              <div className="goals">
                <div className="goal-line highlight" />
                {goals.map((goal) => {
                  const isHome = goal.contestantId === homeTeam.id;
                  const isOwnGoal = goal?.isOwnGoal;
                  if (isHome) {
                    if(isOwnGoal) {
                      scoreCounter.away += 1;
                    } else {
                      scoreCounter.home += 1;
                    }
                  } else {
                    if(isOwnGoal) {
                      scoreCounter.home += 1;
                    } else {
                      scoreCounter.away += 1;
                    }
                  }

                  return (
                    <GoalLine
                      isOwnGoal={isOwnGoal}
                      key={goal.id}
                      goal={goal}
                      isHome={isHome}
                      score={{
                        home: scoreCounter.home,
                        away: scoreCounter.away,
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="right-block-side">
              <div className="header">
                <GreenGlow text="Goals" className="main-header" />
                <InsightZoneLogo />
              </div>
              {subheadline ? (
                <div className="subheadline">{subheadline.value}</div>
              ) : null}
              <div className="shots">
                <Goals
                  side={'home'}
                  team={homeTeam}
                  home={homeTeam}
                  away={awayTeam}
                  allGoals={goals}
                  kit={homeKit}
                  goalsSortOrder={goalsSortOrder}
                  competitionId={competitionId}
                />
                <Goals
                  side={'away'}
                  team={awayTeam}
                  allGoals={goals}
                  kit={awayKit}
                  home={homeTeam}
                  away={awayTeam}
                  goalsSortOrder={goalsSortOrder}
                  competitionId={competitionId}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
