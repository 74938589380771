//

import Image from "../../../../shared/components/Image";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";

export default function TeamLogo({ team, className = "team-logo", competitionId }) {
  return (
    <Image
      className={className}
      src={getDynamicLogoPath({team, competitionId})}
    />
  );
}
