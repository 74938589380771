import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/mvp";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { getPlayerStats } from "../../../../shared/utils/opta/statsHandler";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

import {
  positionTranslations,
  positionExtension,
} from "../../../../shared/utils/opta/player";
import { useTranslation } from "react-i18next";
import { getPlayerPosition } from "../../../../shared/utils/players";
import Image from "../../../../shared/components/Image";
import { getShortPlayerName } from "../../../../shared/feeds/worldcup/playerHelper";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import { PlayerTriko } from "../../_partials/PlayerTriko/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const position = getContentById("playerPosition", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const kits = _.get(dynamic, "kits");
  const playerStats = _.get(dynamic, "playerStats");
  const matchDetails = _.get(dynamic, "liveData.matchDetails");
  const winningTeamSide = _.get(dynamic, "winningTeamSide");
  const competitionId = matchInfo?.competition?.id;

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamHomeAlpha2 = getOptaCountryCode(
    homeTeam?.name,
    homeTeam?.shortName,
    homeTeam?.code,
  );
  const teamAwayAlpha2 = getOptaCountryCode(
    awayTeam?.name,
    awayTeam?.shortName,
    awayTeam?.code,
  );

  const targetAlpha2 = winningTeamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const playerHome = playerStats;
  const homeStats = _.get(playerHome, "stat");

  // const playerPositionMap = _.get(positionExtension, playerHome?.position, 'Midfielder');
  const playerPositionMap = _.get(
    positionExtension,
    playerHome?.position,
    _.get(positionExtension, playerHome?.subPosition, "Midfielder"),
  );

  const statsMapping = getPlayerStats(_.toLower(playerPositionMap), homeStats);

  const scores = _.get(matchDetails, "scores");
  const matchStatus = _.get(matchDetails, "matchStatus");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
            competitionId={competitionId}
            matchInfo={matchInfo}
          />

          <div className={"mvp-block"}>
            <div className={"main-headline green-glow"}>
              <span>M</span>
              <span>V</span>
              <span>P</span>
            </div>
            <div className={"team-stats"}>
              {statsMapping.map((statRow) => {
                return (
                  <div key={statRow.type} className={"stat-row"}>
                    <div className={"stat-label"}>
                      {t(statRow.type, { keyPrefix: "stats" })}
                    </div>
                    <div className={"stat-home"}>{statRow.value || "-"}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={"teams-block"}>
            <PlayerTriko
              className={"left-player"}
              player={playerHome}
              teamId={targetAlpha2}
              side={"home"}
              bgColor={kits?.[winningTeamSide]?.colour1}
              team={winningTeamSide === "home" ? homeTeam : awayTeam}
              competitionId={competitionId}
            />
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
