import React, { useEffect } from "react";

import getContentById from "../../../../../shared/utils/getContentById";
import _, { groupBy } from "lodash";
import Backgrounds from "../../../_partials/Backgrounds/Index";
import TemplateBlock from "../../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../../animations/worldcup/matchdayoverview";

import assets from "../assets";
import { format as dateFormat, parse } from "date-fns";
import { de, enUS } from "date-fns/locale";
import getOptaCountryCode from "../../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../../shared/backgrounds/WcBgCircle/Index";
import Image from "../../../../../shared/components/Image";
import { getTimeInZone } from "../../../../../shared/utils/dateHelpers";
import { LiveStatusBadge } from "../../../_partials/MatchScoreWidget/LiveStatusBadge";
import ProgressBar from "../../../../../shared/components/ProgressBar";

require("./style.scss");
require("../style/animation.scss");

const translations = {
  Group: "Gruppe",
};

function isCopa(id) {
  if (id === "45db8orh1qttbsqq9hqapmbit") {
    return true;
  }
  return false;
}

function competitionTitle(competition) {
  const id = competition?.id;
  if (id === "45db8orh1qttbsqq9hqapmbit") {
    return "COPA America";
  }
  if (id === "8tddm56zbasf57jkkay4kbf11") {
    return "Euro 2024";
  }
  if (id === "70excpe1synn9kadnbppahdn7") return "World Cup";
  return competition?.name;
}

function isCurrentStage(matchdays) {
  let isCurrent = false;
  if (!matchdays) return false;
  matchdays?.forEach((matchday) => {
    const matchStatus = _.toLower(
      matchday?.liveData?.matchDetails?.matchStatus,
    );
    if (matchStatus !== "fixture") {
      isCurrent = true;
    }
    if (matchStatus === "fixture") {
      const contestants = matchday?.matchInfo?.contestant;
      if (contestants) {
        const idExists = contestants.every((obj) => obj.hasOwnProperty("id"));

        if (idExists) {
          isCurrent = true;
        }
      }
    }
  });
  return isCurrent;
}

function getCurrentStage(competitiondId, groupByStage) {
  const stageNames = [
    "8th Finals",
    "Quarter-finals",
    "Semi-finals",
    "3rd Place Final",
    "Final",
  ];
  let currentStage = isCopa(competitiondId) ? stageNames[1] : stageNames[0];
  for (const stage of stageNames) {
    if (isCurrentStage(groupByStage?.[stage])) {
      currentStage = stage;
    }
  }
  return currentStage.replace("-", " ");
}

const MatchDayOverView2 = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t, i18n } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name
        });
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const lng = i18n?.language;
  const templateData = _.get(data, "data");

  const { fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchdays = _.get(dynamic, "matchdays");
  const competition = matchInfo?.competition;
  const competitionId = matchInfo?.competition?.id;

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const groupByStage = _.groupBy(matchdays, function (m) {
    return m?.matchInfo?.stage?.name;
  });

  const eight_finals = groupByStage?.["8th Finals"];
  const quarter_finals = groupByStage?.["Quarter-finals"];
  const semi_finals = groupByStage?.["Semi-finals"];
  const finals = groupByStage?.["Final"];
  const third_finals = groupByStage?.["3rd Place Final"];

  const tournamentData = {
    "8th Finals": eight_finals,
    "Quarter-finals": quarter_finals,
    "Semi-finals": semi_finals,
    Final: finals,
    "3rd Place Final": third_finals,
  };

  const finals8th = [];

  function get8thFinalsOrdering(tournamentData) {
    const matchOrdering = [];

    // Helper function to find matches by nextMatchWinnerId
    function findMatchesByNextMatchWinnerId(stage, nextMatchWinnerId) {
      return tournamentData[stage]?.filter(
        (match) => match.matchInfo.nextMatchWinnerId === nextMatchWinnerId,
      );
    }

    // Build the tree starting from the final match
    const finalMatch = tournamentData["Final"][0];
    const semiFinalMatches = findMatchesByNextMatchWinnerId(
      "Semi-finals",
      finalMatch.matchInfo.id,
    );

    semiFinalMatches.forEach((semiFinalMatch) => {
      const quarterFinalMatches = findMatchesByNextMatchWinnerId(
        "Quarter-finals",
        semiFinalMatch.matchInfo.id,
      );

      quarterFinalMatches.forEach((quarterFinalMatch) => {
        const eighthFinalMatches = findMatchesByNextMatchWinnerId(
          "8th Finals",
          quarterFinalMatch.matchInfo.id,
        );

        eighthFinalMatches?.forEach((eighthFinalMatch) => {
          matchOrdering.push(eighthFinalMatch.matchInfo.id);
        });
      });
    });

    matchOrdering.forEach((mid) => {
      const findMatch = _.find(eight_finals, (m) => {
        return m.matchInfo.id === mid;
      });
      finals8th.push(findMatch);
    });

    return finals8th;
  }

  const finalsOrdering = get8thFinalsOrdering(tournamentData);

  // Function to sort matches by date and time
  function sortMatchesByDateTime(matches) {
    return matches.sort((a, b) => {
      const dateA = new Date(a.matchInfo.date + "T" + a.matchInfo.time);
      const dateB = new Date(b.matchInfo.date + "T" + b.matchInfo.time);
      return dateA - dateB;
    });
  }

  // Function to group matches by nextMatchWinnerId
  function groupMatchesByNextMatchWinnerId(matches) {
    const groups = {};

    matches.forEach((match) => {
      const winnerId = match.matchInfo.nextMatchWinnerId;
      if (winnerId) {
        if (!groups[winnerId]) {
          groups[winnerId] = [];
        }
        groups[winnerId].push(match);
      }
    });

    return groups;
  }

  // Function to sort matches by nextMatchWinnerId and then by date within each group
  function sortMatchesByNextMatchWinnerIdAndDate(matches) {
    const groupedMatches = groupMatchesByNextMatchWinnerId(matches);

    // Flatten the grouped matches while maintaining the order
    return Object.values(groupedMatches)
      .flatMap((group) => sortMatchesByDateTime(group))
      .concat(matches.filter((match) => !match.matchInfo.nextMatchWinnerId));
  }

  // Function to get the ordering of nextMatchWinnerIds from 8th Finals
  function getNextMatchWinnerIdOrder(matches) {
    const orderedIds = [];
    matches.forEach((match) => {
      const winnerId = match.matchInfo.nextMatchWinnerId;
      if (winnerId && !orderedIds.includes(winnerId)) {
        orderedIds.push(winnerId);
      }
    });
    return orderedIds;
  }

  // Function to pair matches with the same nextMatchWinnerId in the current stage
  function pairMatchesByNextMatchWinnerId(
    currentStageMatches,
    previousStageMatches,
  ) {
    const groupedMatches =
      groupMatchesByNextMatchWinnerId(previousStageMatches);
    const nextMatchWinnerIds = Object.keys(groupedMatches);

    const pairedMatches = [];
    nextMatchWinnerIds.forEach((winnerId) => {
      currentStageMatches.forEach((match) => {
        if (match.matchInfo.id === winnerId) {
          pairedMatches.push(match);
        }
      });
    });

    return pairedMatches;
  }

  // Function to sort matches by stage and return the sorted matches
  function sortByStage(groupByStage) {
    const sortedStages = {};

    if (groupByStage["8th Finals"]) {
      sortedStages["8th Finals"] = finalsOrdering;
    }
    if (groupByStage["Quarter-finals"]) {
      const previousStageMatches = sortedStages["8th Finals"] || [];
      sortedStages["Quarter-finals"] = pairMatchesByNextMatchWinnerId(
        groupByStage["Quarter-finals"],
        previousStageMatches,
      );
    }
    if (groupByStage["Semi-finals"]) {
      const previousStageMatches = sortedStages["Quarter-finals"] || [];
      sortedStages["Semi-finals"] = pairMatchesByNextMatchWinnerId(
        groupByStage["Semi-finals"],
        previousStageMatches,
      );
    }
    if (groupByStage["Final"]) {
      const previousStageMatches = sortedStages["Semi-finals"] || [];
      sortedStages["Final"] = pairMatchesByNextMatchWinnerId(
        groupByStage["Final"],
        previousStageMatches,
      );
    }
    if (groupByStage["3rd Place Final"]) {
      const previousStageMatches = sortedStages["Semi-finals"] || [];
      sortedStages["3rd Place Final"] = pairMatchesByNextMatchWinnerId(
        groupByStage["3rd Place Final"],
        previousStageMatches,
      );
    }

    return sortedStages;
  }

  const is_copa = isCopa(competitionId);

  // Example usage
  const sortedStages = !eight_finals ? tournamentData : sortByStage(tournamentData);

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));


  // if copa, hide 8th finals lines

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")}_2 ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle/>*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content wc-bg-content--opasity"}></div>

          <img className={"qatar-logo"} src={assets.insight_zone_logo} />
          <div className="sub-header">{t("Tournament tree")}</div>
          <div className={"main-header green-glow"}>
            {competitionTitle(competition)}
          </div>
          <div className="logo-competition-wrap">
            <Image
              className={"logo-competition"}
              src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
            />
          </div>

          <div className={"left-lines"}>
            <div className={"final-line final-line-1"}></div>
            <div className={"final-line final-line-2"}></div>

            {!is_copa && <div className={`line line1`}></div>}
            {!is_copa && <div className={`line line2`}></div>}
            {!is_copa && <div className={`line line3`}></div>}
            {!is_copa && <div className={`line line4`}></div>}

            <div className={`line line5`}></div>
            <div className={`line line6`}></div>
          </div>

          <div className={"right-lines"}>
            {!is_copa && <div className={`line line1`}></div>}
            {!is_copa && <div className={`line line2`}></div>}
            {!is_copa && <div className={`line line3`}></div>}
            {!is_copa && <div className={`line line4`}></div>}

            <div className={`line line5`}></div>
            <div className={`line line6`}></div>
          </div>

          <div className={"group-table"}>
            <div className="wc-content-header"></div>
            <div className="wc-content-table">
              <div
                className={`group-rows stage-${"8th Finals".replace(/\s/g, "")}`}
              >
                {_.map(sortedStages?.["8th Finals"], (matchday, index) => {
                  const matchDate = new Date(
                    toIsoDateString(
                      matchday?.matchInfo?.date,
                      matchday?.matchInfo?.time,
                    ),
                  );

                  const time = matchday?.matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const rowHomeName =
                    matchday?.matchInfo?.contestant?.[0]?.name;
                  const rowAwayName =
                    matchday?.matchInfo?.contestant?.[1]?.name;

                  const rowHomeCode =
                    matchday?.matchInfo?.contestant?.[0]?.code;
                  const rowAwayCode =
                    matchday?.matchInfo?.contestant?.[1]?.code;

                  const teamHomeAlpha2 = getOptaCountryCode(
                    rowHomeName,
                    rowHomeCode,
                  );
                  const teamAwayAlpha2 = getOptaCountryCode(
                    rowAwayName,
                    rowAwayCode,
                  );

                  const matchStatus = _.toLower(
                    matchday?.liveData?.matchDetails?.matchStatus,
                  );
                  const hasScore =
                    matchStatus === "played" || matchStatus === "playing"
                      ? true
                      : false;
                  const scores =
                    matchday?.liveData?.matchDetails?.scores?.total;

                  return (
                    <div
                      key={matchday?.id}
                      className={`group-row group-row-index-${index}`}
                    >
                      <div className={"match-block"}>
                        <div className={"mid-indicator"}>
                          <small>MID:{matchday?.matchInfo.id}</small>
                          <br />
                          <small>
                            NID:{matchday?.matchInfo.nextMatchWinnerId}
                          </small>
                        </div>

                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-home ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamHomeAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowHomeName,
                                      code: rowHomeCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name home"}>
                              {t(rowHomeCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-home"}>
                            {hasScore ? `${scores?.home || 0}` : "-"}
                          </div>
                        </div>
                        <div className="team-info-divider" />
                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-away ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamAwayAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowAwayName,
                                      code: rowAwayCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name away"}>
                              {t(rowAwayCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-away"}>
                            {hasScore ? `${scores?.away || 0}` : "-"}
                          </div>
                        </div>
                        {matchStatus === "playing" ? (
                          <div className={"row-score-status"}>
                            <LiveStatusBadge />
                          </div>
                        ) : (
                          <div className={"match-time"}>
                            <div className={"row-day"}>
                              {dateFormat(
                                matchDate,
                                lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                                { locale: lng === "de" ? de : enUS },
                              )}

                              <>
                                &nbsp;{time}
                                <div className={"timezone-time"}>
                                  {t("TIME", { keyPrefix: "templates" })}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={`group-rows stage-${"Quarter-finals".replace(/\s/g, "")}`}
              >
                {_.map(sortedStages?.["Quarter-finals"], (matchday, index) => {
                  const matchDate = new Date(
                    toIsoDateString(
                      matchday?.matchInfo?.date,
                      matchday?.matchInfo?.time,
                    ),
                  );

                  const time = matchday?.matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";

                  const rowHomeName =
                    matchday?.matchInfo?.contestant?.[0]?.name;
                  const rowAwayName =
                    matchday?.matchInfo?.contestant?.[1]?.name;

                  const rowHomeCode =
                    matchday?.matchInfo?.contestant?.[0]?.code;
                  const rowAwayCode =
                    matchday?.matchInfo?.contestant?.[1]?.code;

                  const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                  const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                  const matchStatus = _.toLower(
                    matchday?.liveData?.matchDetails?.matchStatus,
                  );
                  const hasScore =
                    matchStatus === "played" || matchStatus === "playing"
                      ? true
                      : false;
                  const scores =
                    matchday?.liveData?.matchDetails?.scores?.total;

                  return (
                    <div
                      key={matchday?.id}
                      className={`group-row group-row-index-${index}`}
                    >
                      <div className={"match-block"}>
                        <div className={"mid-indicator"}>
                          <small>MID:{matchday?.matchInfo.id}</small>
                          <br />
                          <small>
                            NID:{matchday?.matchInfo.nextMatchWinnerId}
                          </small>
                        </div>
                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-home ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamHomeAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowHomeName,
                                      code: rowHomeCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name home"}>
                              {t(rowHomeCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-home"}>
                            {hasScore ? `${scores?.home || 0}` : "-"}
                          </div>
                        </div>
                        <div className="team-info-divider" />

                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-away ${!!teamAwayAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamAwayAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowAwayName,
                                      code: rowAwayCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name away"}>
                              {t(rowAwayCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-away"}>
                            {hasScore ? `${scores?.away || 0}` : "-"}
                          </div>
                        </div>
                        {matchStatus === "playing" ? (
                          <div className={"row-score-status"}>
                            <LiveStatusBadge />
                          </div>
                        ) : (
                          <div className={"match-time"}>
                            <div className={"row-day"}>
                              {dateFormat(
                                matchDate,
                                lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                                { locale: lng === "de" ? de : enUS },
                              )}

                              <>
                                &nbsp;{time}
                                <div className={"timezone-time"}>
                                  {t("TIME", { keyPrefix: "templates" })}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={`group-rows stage-${"Semi-finals".replace(/\s/g, "")}`}
              >
                {_.map(sortedStages?.["Semi-finals"], (matchday, index) => {
                  const matchDate = new Date(
                    toIsoDateString(
                      matchday?.matchInfo?.date,
                      matchday?.matchInfo?.time,
                    ),
                  );
                  const time = matchday?.matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const rowHomeName =
                    matchday?.matchInfo?.contestant?.[0]?.name;
                  const rowAwayName =
                    matchday?.matchInfo?.contestant?.[1]?.name;

                  const rowHomeCode =
                    matchday?.matchInfo?.contestant?.[0]?.code;
                  const rowAwayCode =
                    matchday?.matchInfo?.contestant?.[1]?.code;

                  const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                  const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                  const matchStatus = _.toLower(
                    matchday?.liveData?.matchDetails?.matchStatus,
                  );
                  const hasScore =
                    matchStatus === "played" || matchStatus === "playing"
                      ? true
                      : false;
                  const scores =
                    matchday?.liveData?.matchDetails?.scores?.total;

                  return (
                    <div
                      key={matchday?.id}
                      className={`group-row group-row-index-${index}`}
                    >
                      <div className={"match-block"}>
                        <div className={"mid-indicator"}>
                          <small>MID:{matchday?.matchInfo.id}</small>
                          <br />
                          <small>
                            NID:{matchday?.matchInfo.nextMatchWinnerId}
                          </small>
                        </div>
                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-home ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamHomeAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowHomeName,
                                      code: rowHomeCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name home"}>
                              {t(rowHomeCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-home"}>
                            {hasScore ? `${scores?.home || 0}` : "-"}
                          </div>
                        </div>
                        <div className="team-info-divider" />
                        <div className="team-info-wrap">
                          <div className="team-info-container">
                            <div
                              className={`team-away ${!!teamAwayAlpha2 ? "no-flag-border" : ""}`}
                            >
                              {teamAwayAlpha2 ? (
                                <img
                                  src={getDynamicLogoPath({
                                    team: {
                                      name: rowAwayName,
                                      code: rowAwayCode,
                                    },
                                    competitionId,
                                  })}
                                />
                              ) : (
                                "?"
                              )}
                            </div>
                            <div className={"team-name away"}>
                              {t(rowAwayCode, { ns: "countries" }) || "-"}
                            </div>
                          </div>
                          <div className={"score-away"}>
                            {hasScore ? `${scores?.away || 0}` : "-"}
                          </div>
                        </div>
                        {matchStatus === "playing" ? (
                          <div className={"row-score-status"}>
                            <LiveStatusBadge />
                          </div>
                        ) : (
                          <div className={"match-time"}>
                            <div className={"row-day"}>
                              {dateFormat(
                                matchDate,
                                lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                                { locale: lng === "de" ? de : enUS },
                              )}
                              <>
                                &nbsp;{time}
                                <div className={"timezone-time"}>
                                  {t("TIME", { keyPrefix: "templates" })}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={`group-rows stage-${"Final".replace(/\s/g, "")}`}>
                {_.map(sortedStages?.["Final"], (matchday, index) => {
                  const matchDate = new Date(
                    toIsoDateString(
                      matchday?.matchInfo?.date,
                      matchday?.matchInfo?.time,
                    ),
                  );
                  const time = matchday?.matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";

                  const rowHomeName =
                    matchday?.matchInfo?.contestant?.[0]?.name;
                  const rowAwayName =
                    matchday?.matchInfo?.contestant?.[1]?.name;
                  const rowHomeCode =
                    matchday?.matchInfo?.contestant?.[0]?.code;
                  const rowAwayCode =
                    matchday?.matchInfo?.contestant?.[1]?.code;
                  const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                  const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                  const matchStatus = _.toLower(
                    matchday?.liveData?.matchDetails?.matchStatus,
                  );
                  const hasScore =
                    matchStatus === "played" || matchStatus === "playing"
                      ? true
                      : false;
                  const scores =
                    matchday?.liveData?.matchDetails?.scores?.total;

                  const stageName = matchday?.matchInfo?.stage?.name;

                  return (
                    <div
                      key={matchday?.id}
                      className={`group-row group-row-index-${index}`}
                    >
                      <div className={"match-block"}>
                        <div className={"mid-indicator"}>
                          <small>MID:{matchday?.matchInfo.id}</small>
                        </div>
                        <div className={"match-label-final"}>
                          <div className="green-glow">{t('Final')}</div>
                        </div>

                        <div className="final-team-wrap">
                          <div className="final-team-info-wrap">
                            <div className="final-team-info-container">
                              <div
                                className={`team-home ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                              >
                                {teamHomeAlpha2 ? (
                                  <img
                                    src={getDynamicLogoPath({
                                      team: {
                                        name: rowHomeName,
                                        code: rowHomeCode,
                                      },
                                      competitionId,
                                    })}
                                  />
                                ) : (
                                  "?"
                                )}
                              </div>
                              <div className={"team-name home"}>
                                {t(rowHomeCode, { ns: "countries" }) || "-"}
                              </div>
                            </div>
                          </div>
                          <div className="final-caret">
                            {hasScore
                              ? `${scores?.home || 0} : ${scores?.away || 0}`
                              : "-:-"}
                          </div>
                          <div className="final-team-info-wrap">
                            <div className="final-team-info-container">
                              <div
                                className={`team-away ${!!teamAwayAlpha2 ? "no-flag-border" : ""}`}
                              >
                                {teamAwayAlpha2 ? (
                                  <img
                                    src={getDynamicLogoPath({
                                      team: {
                                        name: rowAwayName,
                                        code: rowAwayCode,
                                      },
                                      competitionId,
                                    })}
                                  />
                                ) : (
                                  "?"
                                )}
                              </div>
                              <div className={"team-name away"}>
                                {t(rowAwayCode, { ns: "countries" }) || "-"}
                              </div>
                            </div>
                          </div>
                        </div>

                        {matchStatus === "playing" ? (
                          <div className={"row-score-status"}>
                            <LiveStatusBadge />
                          </div>
                        ) : (
                          <div className={"match-time"}>
                            <div className={"row-day"}>
                              {dateFormat(
                                matchDate,
                                lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                                { locale: lng === "de" ? de : enUS },
                              )}

                              <>
                                &nbsp;{time}
                                <div className={"timezone-time"}>
                                  {t("TIME", { keyPrefix: "templates" })}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={`group-rows stage-${"3rd Place Final".replace(/\s/g, "")}`}
              >
                {_.map(sortedStages?.["3rd Place Final"], (matchday, index) => {
                  const isPlayed = matchday !== "";
                  const matchDate = isPlayed
                    ? new Date(
                        toIsoDateString(
                          matchday?.matchInfo?.date,
                          matchday?.matchInfo?.time,
                        ),
                      )
                    : new Date();
                  const time = matchday?.matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const rowHomeName =
                    matchday?.matchInfo?.contestant?.[0]?.name;
                  const rowAwayName =
                    matchday?.matchInfo?.contestant?.[1]?.name;

                  const rowHomeCode =
                    matchday?.matchInfo?.contestant?.[0]?.code;
                  const rowAwayCode =
                    matchday?.matchInfo?.contestant?.[1]?.code;

                  const teamHomeAlpha2 = getOptaCountryCode(
                    rowHomeName,
                    rowHomeCode,
                  );
                  const teamAwayAlpha2 = getOptaCountryCode(
                    rowAwayName,
                    rowAwayCode,
                  );

                  const matchStatus = isPlayed
                    ? _.toLower(matchday?.liveData?.matchDetails?.matchStatus)
                    : "None";
                  const hasScore =
                    matchStatus === "played" || matchStatus === "playing"
                      ? true
                      : false;
                  const scores =
                    matchday?.liveData?.matchDetails?.scores?.total;

                  const stageName = matchday?.matchInfo?.stage?.name;

                  return (
                    <div
                      key={matchday?.id}
                      className={`group-row group-row-index-${index}`}
                    >
                      <div className={"match-block"}>
                        <div className={"match-label"}>
                          <div className="green-glow">
                            3<sup>rd</sup> Place
                          </div>
                        </div>
                        <div className="final-team-wrap">
                          <div className="final-team-info-wrap">
                            <div className="final-team-info-container">
                              <div
                                className={`team-home ${!!teamHomeAlpha2 ? "no-flag-border" : ""}`}
                              >
                                {teamHomeAlpha2 ? (
                                  <img
                                    src={getDynamicLogoPath({
                                      team: {
                                        name: rowHomeName,
                                        code: rowHomeCode,
                                      },
                                      competitionId,
                                    })}
                                  />
                                ) : (
                                  "?"
                                )}
                              </div>
                              <div className={"team-name home"}>
                                {t(rowHomeCode, { ns: "countries" }) || "-"}
                              </div>
                            </div>
                          </div>
                          <div className="final-caret">
                            {hasScore
                              ? `${scores?.home || 0} : ${scores?.away || 0}`
                              : "-:-"}
                          </div>
                          <div className="final-team-info-wrap">
                            <div className="final-team-info-container">
                              <div
                                className={`team-away ${!!teamAwayAlpha2 ? "no-flag-border" : ""}`}
                              >
                                {teamAwayAlpha2 ? (
                                  <img
                                    src={getDynamicLogoPath({
                                      team: {
                                        name: rowAwayName,
                                        code: rowAwayCode,
                                      },
                                      competitionId,
                                    })}
                                  />
                                ) : (
                                  "?"
                                )}
                              </div>
                              <div className={"team-name away"}>
                                {t(rowAwayCode, { ns: "countries" }) || "-"}
                              </div>
                            </div>
                          </div>
                        </div>

                        {matchStatus === "playing" ? (
                          <div className={"row-score-status"}>
                            <LiveStatusBadge />
                          </div>
                        ) : (
                          <div className={"match-time"}>
                            <div className={"row-day"}>
                              {dateFormat(
                                matchDate,
                                lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                                { locale: lng === "de" ? de : enUS },
                              )}

                              <>
                                &nbsp;{time}
                                <div className={"timezone-time"}>
                                  {t("TIME", { keyPrefix: "templates" })}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default MatchDayOverView2;
