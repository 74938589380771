import React, { lazy, Suspense } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Loader, {
  AlternativeLoader,
} from "../components/shared/components/Loader";
import FrontendLayout from "./Frontend";
import { SocketProvider } from "../components/shared/contexts/websocket";

// const FrontendLayout = lazy(() => import('./Frontend'));

const BackendLayout = lazy(() => import("./Backend"));

const Login = lazy(() => import("../components/backend/pages/Login"));
const NotFoundPage = lazy(() => import("../components/shared/pages/NotFound"));

// const Loader = React.lazy(() => import(/* webpackChunkName: "Loader" */ '../components/shared/components/Loader'));
// const FrontendLayout = React.lazy(() => import(/* webpackChunkName: "Frontend" */'./Frontend'));

// const Login = React.lazy(() => import('../components/backend/pages/Login'));

// const PrivateRoute = ({component: Component, ...rest}) => (
//   <Route
//     {...rest}
//     render={props => true ? <Component {...props} /> : <Redirect to={{pathname: '/auth'}}/>}
//   />
// );

const LoaderWrapper = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "linear-gradient(90deg, #004d5c 0%, #00112c 100%)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <AlternativeLoader />
      </div>
    </div>
  );
};

export default function Layouts() {
  return (
    <SocketProvider>
      <Switch>
        <Route
          key={"/backend/login"}
          path={"/backend/login"}
          exact
          render={() => {
            const loggedInUser = localStorage.getItem("user");
            if (loggedInUser) {
              return <Redirect to={"/backend"} />;
            }
            return (
              <Suspense fallback={<LoaderWrapper />}>
                <Login />
              </Suspense>
            );
          }}
        />
        <Route
          path="/backend"
          render={(props) => (
            <Suspense fallback={<LoaderWrapper />}>
              <BackendLayout {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/frontend"
          render={(props) => <FrontendLayout {...props} />}
        />
        <Route path="/" exact render={() => <Redirect to={"/backend"} />} />
        <Route
          path="*"
          render={(props) => (
            <Suspense fallback={<LoaderWrapper />}>
              <NotFoundPage {...props} />
            </Suspense>
          )}
        />
      </Switch>
    </SocketProvider>
  );
}
