export const ratio = 1000 / 700; // svg view port 1000, actual image size 700

export const drawLines = (data) => {
  data?.forEach((venue, index) => {
    const c = document.getElementById(`canvas-${index}`);
    const ctx = c?.getContext("2d");
    if (!ctx) return;
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.moveTo(
      venue.cx / ratio + (venue.direction === "left" ? -12 : 12),
      venue.cy / ratio,
    );
    ctx.lineTo(venue.direction === "left" ? 50 : 700, venue.posY + 20);
    ctx.strokeStyle = "#d2f95a";
    ctx.stroke();
  });
};
