import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/facts_curiosity";
import assets from "./assets";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import { toIsoDateString } from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
        });
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const headline = getContentById("headline", fields);
  const subHeadline = getContentById("subHeadline", fields);
  const question = getContentById("question", fields);
  const answer = getContentById("answer", fields);
  const type = getContentById("type", fields);
  const matchInfo = _.get(dynamic, "matchInfo");

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const competitionId = matchInfo?.competition?.id;

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const scores = _.get(dynamic, "liveData.matchDetails.scores");

  const matchDetails = _.get(dynamic, "liveData.matchDetails");
  const matchStatus = _.get(matchDetails, "matchStatus");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={`wc-content wc-content-v2 type-${type}`}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          {type !== "global" && (
            <MatchScoreWidget
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              scores={scores}
              matchStatus={matchStatus}
              matchDetails={matchDetails}
              competitionId={competitionId}
              matchInfo={matchInfo}
            />
          )}

          <div className={"main-title green-glow"}>{headline}</div>
          {subHeadline && (
            <div className={"sub-title green-glow"}>{subHeadline}</div>
          )}

          <div className={"info-block"}>
            <div className={"facts-block"}>
              <div
                className="question fact-1"
                dangerouslySetInnerHTML={{ __html: question }}
              ></div>
              <div className="answer-wrapper">
                <div
                  className="answer fact-2"
                  dangerouslySetInnerHTML={{ __html: answer }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
