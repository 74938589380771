import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/lastgames";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { format as formatDate, parse } from "date-fns";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import { useTranslation } from "react-i18next";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import {
  getDateInZone,
  getTimeInZone,
} from "../../../../shared/utils/dateHelpers";
import i18n from "i18next";
import ProgressBar from "../../../../shared/components/ProgressBar";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const getResultByScore = ({ home, away, side, matchSide, t }) => {
  if (matchSide === "home") {
    if (home > away) {
      return {
        result: t("W"),
        type: "WIN",
      };
    } else if (home == away) {
      return {
        result: t("D"),
        type: "DRAW",
      };
    } else {
      return {
        result: t("L"),
        type: "LOST",
      };
    }
  }

  if (matchSide === "away") {
    if (home < away) {
      return {
        result: t("W"),
        type: "WIN",
      };
    } else if (home == away) {
      return {
        result: t("D"),
        type: "DRAW",
      };
    } else {
      return {
        result: t("L"),
        type: "LOST",
      };
    }
  }
};

const MatchResultIndicator = (props) => {
  const { game, side, matchSide, t } = props;
  const { result, type } = getResultByScore({
    home: Number(game?.scoreHome),
    away: Number(game?.scoreAway),
    side,
    matchSide,
    t,
  });

  return (
    <div className={`result-indicator result-${_.toLower(type)}`}>{result}</div>
  );
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const selectedSide = getContentById("selectedSide", fields);
  const gameType = getContentById("gameType", fields) || "last";

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");

  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const liveData = _.get(dynamic, "liveData");
  const lastGames =
    gameType === "last"
      ? [..._.get(dynamic, "lastGames", [])].reverse()
      : [..._.get(dynamic, "lastGames", [])];
  const competitionId = matchInfo?.competition?.id;

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];
  const selectedTeam = selectedSide === "home" ? homeTeam : awayTeam;

  const teamHomeAlpha2 = getOptaCountryCode(
    homeTeam?.name,
    homeTeam?.shortName,
    homeTeam?.code,
  );
  const teamAwayAlpha2 = getOptaCountryCode(
    awayTeam?.name,
    awayTeam?.shortName,
    awayTeam?.code,
  );

  const scores = _.get(liveData, "matchDetails.scores.total");

  const textHead =
    gameType === "last"
      ? t("Last 5 Games", { keyPrefix: "templates" })
      : t("Next 5 Games", { keyPrefix: "templates" });
  const lng = i18n?.language;

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle/>*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.qatar_logo} />
          </div>

          <div className={"left-block-side"}>
            <div className={"score green-glow"}>{textHead}</div>

            <div className={"teams-block " + selectedSide}>
              {selectedSide === "home" ? (
                <div className={"team-logo-container home"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: homeTeam, competitionId })}
                  />
                  <div className={"team-name home"} data-id={homeTeam?.id}>
                    {t(homeTeam?.shortName || homeTeam?.name, {
                      ns: "countries",
                    })}
                  </div>
                </div>
              ) : (
                <div className={"team-logo-container away"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: awayTeam, competitionId })}
                  />

                  <div className={"team-name away"}  data-id={awayTeam?.id}>
                    {t(awayTeam?.shortName || awayTeam?.name, {
                      ns: "countries",
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`right-block-side game-type-${gameType}`}>
            <div className={"team-stats"}>
              <div className={"group-rows"}>
                {lastGames?.map((game, index) => {
                  const parsedDate = formatDate(
                    parse(
                      game?.date.replace("Z", ""),
                      "MM/dd/yyyy",
                      new Date(),
                    ),
                    "dd.MM.yy",
                  );

                  const matchDate = game?.matchDate
                    ? new Date(
                        toIsoDateString(game?.matchDate, game?.matchTime),
                      )
                    : null;

                  const time = game?.matchTime
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const date = game?.matchDate
                    ? getDateInZone(matchDate, lng)
                    : "-";
                  const matchSide =
                    game?.homeId === selectedTeam.id ? "home" : "away";

                  const matchDetails = game?.liveData?.matchDetails;

                  const hasPenalty = _.has(matchDetails, "scores.pen");
                  const hasExtraTime =
                    _.has(matchDetails, "scores.et") && !hasPenalty;
                  const matchStatus = _.toLower(matchDetails?.matchStatus);

                  return (
                    <div key={parsedDate + index} className={"group-row"}>
                      {/*<div className={"match-label"}>*/}
                      {/*  <div className={"match-label-competition"}>*/}
                      {/*    <div className={'competition-logo-container'}>*/}
                      {/*      <Image src={`/images/dynamic/soccer/competition/${game?.competitionId}.svg`}/>*/}
                      {/*    </div>*/}
                      {/*    /!*{game?.competition}*!/*/}
                      {/*  </div>*/}
                      {/*  <div className={"match-label-date"}>{date} {time}</div>*/}
                      {/*</div>*/}
                      <div className={"match-row"}>
                        <div
                          className={"competition-logo-container"}
                          data-comp-id={game?.competitionId}
                        >
                          <Image
                            src={`/images/dynamic/soccer/competition/${game?.competitionId}.png`}
                          />
                        </div>
                        <div className={"match-label-date"}>
                          {date}
                          <br />
                          <span className={"timestamp"}>{time}</span>
                        </div>
                        <div className={"team-name home"} data-id={game?.homeId}>
                          {t(game?.homeName, { ns: "countries" })}
                        </div>
                        <div className={"team-home"}>
                          <Image
                            className="team-logo"
                            src={getDynamicLogoPath({
                              team: {
                                name: game?.homeName,
                                id: game?.homeId,
                              },
                              competitionId,
                            })}
                          />
                        </div>
                        <div className={"row-score"}>
                          {`${
                            game?.scoreHome || (gameType === "last" ? 0 : "-")
                          } : ${game?.scoreAway || (gameType === "last" ? 0 : "-")}`}

                          <div className={"score-type"}>
                            {_.toLower(matchStatus) === "played"
                              ? (hasPenalty && (
                                  <span className={"won-by-status"}>
                                    ({t("WonByPenalties")})
                                  </span>
                                )) ||
                                (hasExtraTime && (
                                  <span className={"won-by-status"}>
                                    ({t("WonOnExtraTime")})
                                  </span>
                                ))
                              : null}
                          </div>
                        </div>
                        <div className={"team-away"}>
                          <Image
                            className="team-logo"
                            src={getDynamicLogoPath({
                              team: {
                                name: game?.awayName,
                                id: game?.awayId,
                              },
                              competitionId,
                            })}
                          />
                        </div>
                        <div className={"team-name away"} data-id={game?.awayId}>
                          {t(game?.awayName, { ns: "countries" })}
                        </div>
                        {gameType === "last" && (
                          <div>
                            <MatchResultIndicator
                              t={t}
                              game={game}
                              side={selectedSide}
                              matchSide={matchSide}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};
const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
