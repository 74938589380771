import {
  clearTimeouts,
  engine as anime,
} from "../../../../../animations/index";
import { getTemplateDurationOveride } from "../../../../shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, data, templateName } = props;

  const goalData = data?.data?.dynamic?.goalData;

  const timeline = anime.timeline({
    autoplay: false,
  });

  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .subheadline, #${id} .main-header`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .versus-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .comp-player-triko`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .team-logo-container.home`],
    duration: 1000,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .team-logo-container.away`],
    duration: 1000,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  let delay = 0;

  const circleDuration = 300;
  const pathDuration = 750;

  // anime({
  //   targets: ".circle",
  //   opacity: 1,
  //   easing: "easeInOutSine",
  //   scale: [1.5, 1],
  //   duration: 300,
  //   delay: anime.stagger(500),
  // });

  // anime({
  //   targets: ".path",
  //   opacity: 1,
  //   strokeDashoffset: [anime.setDashoffset, 0],
  //   easing: "easeInOutSine",
  //   duration: 450,
  //   delay: anime.stagger(500),
  // });

  const timeout1 = setTimeout(() => {
    for (const i in goalData) {
      const data = goalData[i];
      const targetPlayer = `#player-${data.eventId}`;
      const targetPath = `#path-${data.eventId}`;

      timeline.add(
        {
          targets: targetPlayer,
          opacity: 1,
          easing: "easeOutElastic",
          scale: [1.5, 1],
          duration: 400,
        },
        i === 0 ? undefined : "-=600",
      );

      timeline.add({
        targets: targetPath,
        opacity: 1,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 1100,
      });
    }

    timeline.add(
      {
        targets: "#goal",
        scale: [0.5, 1],
        opacity: 1,
        easing: "easeInOutElastic",
        duration: 600,
      },
      "-=800",
    );

    timeline.play();
  }, 500);

  return {
    timeline,
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
