import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  bg_blue: require("../../../assets/tm/images/bg_blue.png"),
  tm_logo: require("../../../assets/tm/images/transfermarkt.png"),
  tm_arrow_rumour: require("../../../assets/tm/images/arrow_rumour.png"),
  tm_arrow_done_deal: require("../../../assets/tm/images/arrow_done_deal.png"),
  tm_arrow_failed_deal: require("../../../assets/tm/images/arrow_failed_deal.png"),
};

export default assets;
