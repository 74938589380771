import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import parseMediaUrl from "../../functions/parseMediaUrl";
import MediaDescriptionRender from "./MediaDescriptionRender";
import StaticBox from "../../../frontend/templates/StaticSlide/Box";
import Backgrounds from "../../../frontend/templates/_partials/Backgrounds/Index";

const player_selected = require("../../../frontend/assets/tm/images/player_item_selected.png");
const player_normal = require("../../../frontend/assets/tm/images/player_item_normal.png");

export function Item(props) {
  const {
    id,
    index,
    item,
    isDragging,
    isSorting,
    handleRemovePlayer,
    isEven = true,
    showRank = false,
    rank = 0,
    isLast = false,
  } = props;
  return (
    <div
      key={item.uuid}
      className={`player-item ${showRank ? "" : "player-item-scale"}`}
    >
      <div
        className={`player-item-av ${showRank ? "" : "player-item-av-scale"}`}
        style={{
          borderTop: showRank && rank === 1 ? "3px solid #5ca6ff" : "none",
          borderBottom: showRank && isLast ? "3px solid #5ca6ff" : "none",
        }}
      >
        <div className="player-item-left">
          <Backgrounds bg1={isEven ? player_selected : player_normal} />
          <div className="player-item-rank">
            {showRank ? <div>{rank}</div> : <div></div>}
          </div>
          <img
            className="player-item-team"
            src={parseMediaUrl(item.team?.value?.url)}
          />
          <div className="player-item-name">{item.player}</div>
          <div className="player-item-age">{item.age}</div>
        </div>
        <div
          className="player-item-market"
          style={{
            opacity: 1 - rank / 30,
          }}
        >
          {item.marketValue}
        </div>
      </div>
    </div>
  );
}
