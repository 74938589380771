import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _, { filter } from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/countrystadium";
import PitchTactics from "../../_partials/PitchTactics/Index";

import assets, { stadiums } from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import arrowIn from "../../../assets/global/arrow-in.png";
import arrowOut from "../../../assets/global/arrow-out.png";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import i18n from "i18next";
import { genPreviewOperationsStyle } from "antd/es/image/style";
import { drawLines, ratio } from "./map";
import moment from "moment";
import { LiveStatusBadge } from "../../_partials/MatchScoreWidget/LiveStatusBadge";
import { format as dateFormat, parse, parseISO } from "date-fns";
import { formatDate } from "../LiveMatchday/helper";
import {
  getDateInZone,
  getTimeInZone,
} from "../../../../shared/utils/dateHelpers";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const filterStadiums = (data) => {
  if (data?.length < 4) {
    return data;
  }
  const leftVenues = data.filter((d) => d.direction === "left");
  const rightVenues = data.filter((d) => d.direction === "right");
  if (leftVenues.length > 1) {
    if (rightVenues.length > 1) {
      return [
        ...leftVenues[0],
        ...leftVenues[leftVenues.length - 1],
        ...rightVenues[0],
        ...rightVenues[rightVenues.length - 1],
      ];
    } else {
      const rightLen = rightVenues.length;
      const remainingLen = 4 - rightLen;
      // Shuffle array
      const shuffled = leftVenues.sort(() => 0.5 - Math.random());
      // Get sub-array of first n elements after shuffled
      const shuffledLeft = shuffled.slice(0, remainingLen);
      return [...rightVenues, ...shuffledLeft];
    }
  } else {
    if (rightVenues.length > 1) {
      const leftLen = leftVenues.length;
      const remainingLen = 4 - leftLen;
      const shuffled = rightVenues.sort(() => 0.5 - Math.random());
      const shuffledRight = shuffled.slice(0, remainingLen);
      return [...leftVenues, ...shuffledRight];
    }
  }
  return data;
};

const setPosToVenue = (data) => {
  if (data?.length < 1) return;
  data.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
  const firstVenue = data[0];
  let posYLeft = firstVenue.cy / ratio - firstVenue.cy / 6;
  if (firstVenue.cy > 500) {
    posYLeft = 200;
  }
  let posYRight = posYLeft;
  let firstDirection = firstVenue.direction;
  let leftInd = 0;
  let rightInd = 0;
  if (firstDirection === "left") {
    posYRight = posYLeft + 50;
  } else {
    posYLeft = posYLeft + 50;
  }
  data?.forEach((venue, ind) => {
    if (venue.direction === "left") {
      venue["posInd"] = leftInd;
      venue["posY"] = posYLeft + 250 * leftInd;
      leftInd++;
    } else {
      venue["posInd"] = rightInd;
      venue["posY"] = posYRight + 250 * rightInd;
      rightInd++;
    }
  });
  return data;
};

const isToday = (date) => {
  const today = new Date();
  const matchDate = parse(date, "yyyy-MM-dd", new Date());
  const formattedMatch = dateFormat(matchDate, "dd-MM-yyyy");
  const formattedNow = dateFormat(today, "dd-MM-yyyy");
  if (formattedMatch === formattedNow) {
    return true;
  }
  return false;
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const lng = i18n?.language;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;

  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const competitionId = matchInfo?.competition?.id;

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  let venues = _.map(matchday, (m) => {
    return m.matchInfo.venue.shortName;
  });
  let filteredStadiums = stadiums.filter((s) => {
    return (
      venues.filter((v) => {
        return s.knownName?.includes(v);
      }).length > 0
    );
  });
  // Logic to limit stadiums to 4
  filteredStadiums = filterStadiums(filteredStadiums);
  filteredStadiums = setPosToVenue(filteredStadiums);
  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          points: filteredStadiums,
        });
      }
    }
    drawLines(filteredStadiums);
    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  // add 5 random stadiums
  let randomStadiums = stadiums.filter(
    (obj1) =>
      !filteredStadiums?.find((obj2) => obj2.name === obj1.name) &&
      !!obj1.knownName,
  );
  // Get sub-array of first n elements after shuffled
  const shuffledStadiums = randomStadiums;
  const firstVenue = filteredStadiums?.length > 0 ? filteredStadiums[0] : null;
  const firstMatch = _.find(
    matchday,
    (m) => m.matchInfo.venue.shortName === firstVenue?.knownName,
  );
  const matchDateStr = firstMatch?.matchInfo?.date;
  const matchDate = parseISO(matchDateStr);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}>
            <img className={"qatar-logo"} src={assets.insight_zone_logo} />
            {firstMatch && (
              <div className="matchday-wrapper">
                <div className="matchday-title green-glow">
                  {t(firstMatch?.matchInfo?.stage?.name)}
                </div>
                <div className="matchday-date">
                  {isToday(firstMatch.matchInfo?.localDate)
                    ? `${t("Today")}, `
                    : ""}
                  {formatDate(matchDate, lng)}
                </div>
              </div>
            )}
            <div className={"left-block-side"}>
              <div className={"competition-positioner"}>
                <div className={"competition-logo-container"}>
                  <Image
                    src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
                  />
                </div>
              </div>
            </div>
            <div className={"right-block-side"}>
              <canvas id="lineCanvas" width={1750} height={900} />
              <svg
                className="germany-map"
                viewBox="0 0 1000 1000" // Adjust viewBox as per your SVG dimensions
                preserveAspectRatio="xMidYMid meet"
              >
                <image
                  href={require("./de.svg")}
                  width="400px"
                  height="400px"
                />
                {shuffledStadiums.map((pin) => (
                  <circle
                    key={`stadium-${pin.cx} + ${pin.cy}`}
                    cx={pin.cx / 2.5}
                    cy={pin.cy / 2.5}
                    r={6} // Adjust the radius as needed
                    fill="#3B7E89" // Adjust pin color
                    stroke="#3B7E89"
                    className={`stadium-pos-${pin.direction}`}
                  />
                ))}
                {/* Some random points as well */}
                {filteredStadiums?.map((pin, index) => (
                  <circle
                    key={`stadium-random-${pin.cx} + ${pin.cy}`}
                    cx={pin.cx / 2.5}
                    cy={pin.cy / 2.5}
                    r={6} // Adjust the radius as needed
                    fill="#3B7E89" // Adjust pin color
                    stroke="#3B7E89"
                    className={`stadium-pos-${pin.direction} points-${index}`}
                  />
                ))}
              </svg>
              <div className="match-content-wrapper">
                {filteredStadiums?.map((venue, index) => {
                  return (
                    <canvas
                      key={`canvas-${index}`}
                      id={`canvas-${index}`}
                      width={1750}
                      height={900}
                    />
                  );
                })}
                {filteredStadiums?.map((venue, index) => {
                  const match = _.find(
                    matchday,
                    (m) => m.matchInfo.venue.shortName === venue.knownName,
                  );

                  const contestants = match.matchInfo.contestant;
                  const { direction } = venue;
                  const matchStatus = match.liveData?.matchDetails?.matchStatus;
                  const hasScore =
                    matchStatus === "Played" || matchStatus === "Playing";
                  const scores = match.liveData?.matchDetails?.scores?.total;
                  const homeScore = scores?.home;
                  const awayScore = scores?.away;
                  const isLive = matchStatus === "Playing";

                  const matchDate = new Date(
                    toIsoDateString(
                      match.matchInfo?.date,
                      match.matchInfo?.time,
                    ),
                  );
                  const time = matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const date = matchInfo?.time
                    ? getDateInZone(matchDate, lng)
                    : "-";

                  return (
                    <div
                      key={index}
                      className={`match-content-container map-${direction} match-content-${index}`}
                      style={{
                        left: direction === "left" ? -150 : 700,
                        top: venue.posY,
                      }}
                    >
                      <div className="city-name">{venue.city}</div>
                      <div className="content-section">
                        <div className="content-time">{time}</div>
                        <div className="team-score-section">
                          <div className="team-info">
                            <img
                              src={getDynamicLogoPath({
                                team: contestants[0],
                                competitionId,
                              })}
                            />
                            {t(contestants[0].code, { ns: "countries" })}
                          </div>
                          <div className="team-score">
                            {hasScore ? homeScore + ":" + awayScore : "-:-"}
                          </div>
                          <div className="team-info">
                            <img
                              src={getDynamicLogoPath({
                                team: contestants[1],
                                competitionId,
                              })}
                            />
                            {t(contestants[1].code, { ns: "countries" })}
                          </div>
                          {isLive && <LiveStatusBadge />}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
