import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../shared/utils/getContentById";
import _ from "lodash";
import TemplateBlock from "../_partials/TemplateBlock/Index";
import Backgrounds from "../_partials/Backgrounds/Index";

import animationScript, {
  animationCleanUp,
} from "../../../../animations/staticslide";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

import assets from "../../templates/adler/Results/assets";
import prepareVideoBg from "../../../shared/functions/prepareVideoBg";
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
import { audioManager } from "../../../shared/utils/audio-manager";
import Image from "../../../shared/components/Image";
import { getDynamicLogoPath } from "../../../shared/utils/functions";
import { getGlobalMatchdays } from "../../../../services/backend/api.service";
import StaticBox from "./Box";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    _searchParams,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  const templateData = _.get(data, "data");
  const { options, fields, dynamic } = templateData;
  const eventData = _.get(dynamic, "game");

  const color1 = getContentById("color1", options);
  const customSlides = getContentById("slides", fields);
  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;
  const images = customSlides.map((slide) => parseMediaUrl(slide?.url));

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          points: customSlides,
        });
        //pause global audio
        audioManager.fadeOutAndPause();
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn, id, images);
        audioManager.fadeInAndPlay();
      }
    };
  }, [animation, mode]);

  const [countries, setCountries] = useState([]);
  const [feedType, setFeedType] = useState("opta");
  useEffect(() => {
    const feed_type = data?.event?.feedType;
    setFeedType(feed_type);
    if (feed_type === "heimspiel") {
      getGlobalMatchdays({ feedType: feed_type })
        .then((data) => {
          const apiDataTeams = _.get(data, "data.teams", []);
          setCountries(apiDataTeams);
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  }, data?.event);

  const sliderItem = (index) => {
    const url = images[index];
    const nextUrl = index === images.length - 1 ? images[0] : images[index + 1];
    const slide = customSlides[index];
    return (
      <>
        <div
          className={`custom-background-container custom-container-${index}`}
          style={{
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <StaticBox slide={slide} feedType={feedType} countries={countries} />
          <ImageOrVideo
            loop={true}
            className={"custom-background-origin"}
            src={url}
            searchParams={_searchParams}
            animation={animation}
          />

          <div className="custom-background">
            <ImageOrVideo
              loop={true}
              className={"custom-triangle-top"}
              src={url}
              searchParams={_searchParams}
              animation={animation}
            />
          </div>
          <div className="custom-background">
            <ImageOrVideo
              loop={true}
              className={"custom-triangle-bottom"}
              src={url}
              searchParams={_searchParams}
              animation={animation}
            />
          </div>
          <ImageOrVideo
            loop={true}
            className={"custom-triangle-transition-prev"}
            src={url}
            searchParams={_searchParams}
            animation={animation}
          />
          <div className={"custom-triangle-transition-green"} />

          <ImageOrVideo
            loop={true}
            className={"custom-triangle-transition-next"}
            src={nextUrl}
            searchParams={_searchParams}
            animation={animation}
          />
          <div className={"custom-triangle-transition-white"} />
          <ImageOrVideo
            loop={true}
            className={"custom-triangle-transition-next-bottom"}
            src={nextUrl}
            searchParams={_searchParams}
            animation={animation}
          />
          <ImageOrVideo
            loop={true}
            className={"custom-triangle-transition-next-full"}
            src={nextUrl}
            searchParams={_searchParams}
            animation={animation}
          />
        </div>
      </>
    );
  };
  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        {customSlides?.map((image, index) => sliderItem(index))}
      </TemplateBlock>
    </div>
  );
};

export default Index;
