import React, { useEffect } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

import "./style/style.scss";
import "./style/animation.scss";
import { PlayerTriko } from "../../_partials/PlayerTriko/Index";
import getContentById from "../../../../shared/utils/getContentById";

const getMainBackground = () => {
  return assets.background_1920;
};

const radius = 30;
const goalRadius = 50;
const width = 929;
const height = 669;
const wholeFieldHeight = height * 2;
const cutoff = height;

function getCssCoord(y, height, r) {
  const ratio = height / 100;

  return y * ratio - r * 2;
}

function getCssBottom(x, r = radius) {
  return getCssCoord(x, wholeFieldHeight, r) - cutoff;
}

function getCssRight(y, r = radius) {
  const value = getCssCoord(y, width, r);
  return value < 0 ? 0 : value;
}

function getSvgX(y, r) {
  return width - getCssRight(y, r) - r;
}

function getSvgY(x, r) {
  return height - getCssBottom(x, r) - r;
}

function getSvgPoint(x, y, r = radius) {
  return `${getSvgX(y, r)},${getSvgY(x, r)}`;
}

export default function Index({ animation, data, mode, format, ...props }) {
  const { t } = useTranslation();

  const id = `goal_alarm_${data?.id}`;
  const playerId = data?.playerId;
  const matchInfo = data?.matchInfo;
  const matchDetails = data?.matchDetails;
  const attackingZones = data?.attackingZones;
  const contestantId = data?.contestantId;
  const contestantTeam = data?.matchInfo?.contestant.find(
    (c) => c.id === contestantId,
  );
  const teamSide = contestantTeam?.position ?? "home";
  const goalData = data?.goalData?.goal || [];
  const lineUps = data?.goalData?.lineUp || [];
  const goalTeamLineUp = lineUps.find((l) => l.contestantId === contestantId);
  const kit = goalTeamLineUp?.kit;
  const goalPlayer = goalTeamLineUp.player.find(
    (player) => player.playerId === playerId,
  );

  const competitionId = matchInfo?.competition?.id;

  const matchStatus = matchDetails?.matchStatus;
  const scores = matchDetails?.scores;

  const backgroundImage = require("../../../assets/wc/videos/wipe.webm");

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          data,
          animation,
          mode,
          id,
          format,
          apiSetup: props?.apiSetup,
        });

        animationReturn.timeline.play();
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const goal = goalData?.[goalData?.length - 1];
  const goalPos = goal?.coords?.dest;
  const goalEventId = goal?.eventId;

  return (
    <div
      id={id}
      className={`T_goal_alarm ${animation ? "animation" : ""} side-${teamSide}`}
    >
      <Backgrounds bg1={backgroundImage} loop={false} />
      {matchStatus && (
        <div className="wc-content wc-content-v2-alarm">
          <div className="wc-bg-content" />
          <div className="qatar-logo">
            <img src={assets.insight_zone_logo} alt="" />
          </div>
          <ProgressBar height="8px" />
          <div className="content">
            <div className="container">
              <div className={"left-block-side"}>
                <div className="left">
                  <div className="main-header green-glow header-v1 alarm-goal-headline">{t('Goal', {keyPrefix: 'templates'})}!</div>
                  <div className="main-header green-glow header-v2 alarm-goal-headline">{t('Goal', {keyPrefix: 'templates'})}!</div>
                  <div className="main-header green-glow header-v3 alarm-goal-headline">{t('Goal', {keyPrefix: 'templates'})}!</div>

                  <PlayerTriko
                    className={"left-player"}
                    player={{
                      knownName: goalPlayer?.matchName,
                      ...goalPlayer,
                    }}
                    teamId={contestantId}
                    side={teamSide}
                    bgColor={kit?.colour1}
                    team={teamSide === "home" ? homeTeam : awayTeam}
                    competitionId={competitionId}
                  />
                </div>
              </div>

              <div className={"right-block-side"}>
                <div className="bottom-block">
                  <MatchScoreWidget
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    scores={scores}
                    matchStatus={"Playing"}
                    matchDetails={matchDetails}
                    competitionId={competitionId}
                    isAlarm
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
