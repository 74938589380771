import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, templateName, data } = props;

  const timeline = anime.timeline();
  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  $(
    `#${id} .qatar-logo, #${id} .main-headline, #${id} .pitch-container, #${id} .arrows-right > img, #${id} .stat-row, #${id} .stat-separator`,
  ).css("opacity", 0);
  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .main-headline`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .pitch-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .player`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .team-home`],
    duration: 1000,
    easing: "easeOutQuint",
    translateY: [-2000, 0],
    opacity: [1, 1],
    delay: 1000,
  });

  anime({
    targets: [`#${id} .team-away`],
    duration: 1000,
    easing: "easeOutQuint",
    translateY: [2000, 0],
    opacity: [1, 1],
    delay: 1200,
  });

  const timeout2 = setTimeout(() => {
    anime({
      targets: [`#${id} .stat-row, #${id} .stat-separator`],
      duration: 1000,
      easing: "easeOutQuint",
      translateX: [2000, 0],
      opacity: [1, 1],
      delay: anime.stagger(200),
    });
  }, 1500);

  const timeout1 = setTimeout(() => {
    anime({
      targets: [$(`#${id} .arrows-right > img`).toArray().reverse()],
      duration: 1000,
      easing: "easeOutQuint",
      translateX: [-1500, 0],
      opacity: [0, 1],
      delay: anime.stagger(150),
    });
  }, 2500);

  timeouts.push(timeout1);
  timeouts.push(timeout2);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
