import React, { useState, useRef, useEffect } from "react";

import {
  Form,
  Input,
  Button as CheckButton,
  Checkbox,
  Button,
  Layout,
  Alert,
} from "antd";
import AuthService from "../../../services/auth.service";

import Image from "../../shared/components/Image";
import "../../../layouts/backend/main.scss";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import CONFIG from "../../../config/backend";
import { notification, Space } from "antd";
import _ from "lodash";
import { useWebsocket } from "../../shared/contexts/websocket";

const { Header, Footer, Sider, Content } = Layout;

const VigitalLogo = require("../../../assets/logo/vigital_solutions.png");

const Login = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { login } = useWebsocket();
  const { t } = useTranslation();

  const validateMessages = {
    required: `'\${label}' ${t("is required.")}`,
    // ...
  };

  const onFinish = (values) => {
    setMessage("");
    setLoading(true);

    // form.current.validateAll();s
    const { username, password } = values;
    AuthService.login(username, password).then(
      (response) => {
        if (response.data.token) {
          login(response.data);
        }

        history.push("/backend");
        // window.location.reload();
      },
      (error) => {
        if (error.message !== "Network Error") {
          let errResponse = error.toString();
          const errors = _.get(error, "response.data.errors.msg", []);

          if (typeof errors === "object" && errors.length > 0) {
            errResponse = errors.map((err) => {
              return <div key={err.msg}>{t(err.msg)}</div>;
            });
          } else if (typeof errors === "string") {
            errResponse = <div>{t(errors)}</div>;
          }

          notification["error"]({
            message: t("Server response"),
            description: errResponse,
          });
        }

        setLoading(false);
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  // const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e, a) => {};

  const currentYear = new Date().getFullYear();

  return (
    <Layout className={"main-login-page"}>
      <Content className={"main-login-content"}>
        <div className="card card-container">
          <div className={"main-login-header"}>
            <Image src={VigitalLogo} />
            <div className={"product-name"}>{CONFIG.appName}</div>
          </div>

          <Form
            layout={"vertical"}
            validateMessages={validateMessages}
            autoComplete="on"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            name="control-hooks"
          >
            <Form.Item
              name="username"
              label={t("Username")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type={"password"} />
            </Form.Item>

            <Form.Item className={"submit-button"}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>

            {message && (
              <>
                <br />
                <Alert message={message} type="error" showIcon />
              </>
            )}

            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </Content>
      <Footer className={"login-footer"}>
        Vigital Solutions GmbH © {currentYear}
      </Footer>
    </Layout>
  );
};

export default Login;
