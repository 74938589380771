import Image from "../../../../shared/components/Image";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Trikot } from "../../../assets/svg/Trikot";
import { getShortPlayerName } from "../../../../shared/feeds/worldcup/playerHelper";
import { getPlayerPosition } from "../../../../shared/utils/players";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";

export const PlayerTriko = ({
  player,
  teamId,
  className = "",
  side = "home",
  bgColor,
  team,
  competitionId,
  ownGoal = false
}) => {
  const { t } = useTranslation();
  // const fallbackBg = side ==='home' ? '#007df8' : '#f52724'
  const fallbackBg =
    side === "home" ? "rgba(255,255,255,0.4)" : "rgba(255,255,255,0.4)";
  const playerStyle = {
    "--triko-svg-color": bgColor ? bgColor : fallbackBg,
  };

  return (
    <div style={playerStyle} className={`comp-player-triko ${className}`}>
      <div className={`player player-home`}>
        <div className={`trikot`}>
          <Trikot className={`triko-svg`} side={side} />
          <div className={"player-number"}>{player?.shirtNumber}</div>
          <div className={"player-info"}>
            <div className={"player-name"}>
              <div className={"player-firstname"}>
                {getShortPlayerName(player, true)}
              </div>
              <div className={"player-lastname"}>
                {getShortPlayerName(player, false, true)}
              </div>
            </div>
            <div className={"player-position"}>
              {t(getPlayerPosition(player), { keyPrefix: "templates", defaultValue: '-' })}
            </div>
          </div>
        </div>

        <div className={"team-logo-container home"}>
          <Image
            className="team-logo"
            src={getDynamicLogoPath({ team, competitionId })}
          />
        </div>
      </div>
    </div>
  );
};
