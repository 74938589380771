import React, { useEffect, Fragment, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";

import "./style/style.scss";
import "./style/animation.scss";
import getContentById from "../../../../shared/utils/getContentById";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import TeamLogo from "../../_partials/TeamLogo";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import Stats from "./stats";
import ProgressBar from "../../../../shared/components/ProgressBar";
import _ from "lodash";
import { getGlobalMatchdays } from "../../../../../services/backend/api.service";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";
import Image from "../../../../shared/components/Image";
import sportWorldShadow from "../../../assets/wc/images/sportworld_image_shadow.png";

const getMainBackground = () => {
  return assets.bg_1;
};

const getBlueBg = () => {
  return assets.bg_blue;
};

const Index = ({ animation, data, mode, format, _searchParams, ...props }) => {
  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const backgroundImage = getMainBackground();
  const blueBg = getBlueBg();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          id,
          data,
          format,
          apiSetup: props?.apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const { options, fields, dynamic } = data.data || {};

  const headline = fields.find(({ id }) => id === "headline");
  const selectedType = getContentById("type", fields);
  const typeColor =
    selectedType === "rumour"
      ? "#f6977c"
      : selectedType === "done"
        ? "#0CD97A"
        : "#ec376e";
  const selectedTypeText = getContentById("typeText", fields);

  const customBackground = getContentById("player", fields, "self");
  const selectedTitle = getContentById("title", fields);
  const selectedMarketValue = getContentById("marketValue", fields);
  const selectedFee = getContentById("fee", fields);
  const selectedAddOns = getContentById("addOns", fields);
  const selectedPosition = getContentById("position", fields);

  const selectedTransferFrom = getContentById("transfer_from", fields);
  const selectedTransferTo = getContentById("transfer_to", fields);
  const selectedBackground = getContentById("background", fields);

  const customBackgroundStyles = _.get(
    customBackground,
    `styles.${format}`,
    {},
  );
  const transfer_arrow =
    selectedType === "rumour"
      ? assets.tm_arrow_rumour
      : selectedType === "done"
        ? assets.tm_arrow_done_deal
        : assets.tm_arrow_failed_deal;
  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <div className={"comp-backgrounds-container"}>
          <Backgrounds bg1={parseMediaUrl(selectedBackground)} loop={true} />
        </div>
        <div className={`wc-content content`}>
          <ProgressBar height={"8px"} />
          <div className={"tm-logo"}>
            <img src={assets.tm_logo} />
          </div>
          <div className={"block-side"}>
            <div className="main-header-container">
              <img
                className={"main-header-transfer-to"}
                src={parseMediaUrl(_.get(selectedTransferTo, "url"))}
              />
              <div className="main-header">{selectedTypeText}</div>
            </div>
            <div className={"custom-background-container"}>
              <div
                id={_.get(customBackground, "id", null)}
                style={customBackgroundStyles}
                className={"custom-background-container"}
              >
                <img
                  className={"custom-background"}
                  src={parseMediaUrl(_.get(customBackground, "value.url"))}
                />
              </div>
            </div>
            <div className={`information-box`}>
              <div
                className={`box-container`}
                style={{
                  borderTop: `2px solid ${typeColor}`,
                  borderBottom: `2px solid ${typeColor}`,
                }}
              >
                <div
                  className={"box-background-blue"}
                  style={{
                    backgroundImage: `url(${blueBg})`,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: selectedTitle }}
                    className="box-title"
                  />
                  <div className="box-row">
                    <div className="box-row-item">
                      <div className="box-row-item-title">Market Value</div>
                      <div className="box-row-item-value">
                        {selectedMarketValue || "-"}
                      </div>
                    </div>
                    <div
                      className="box-row-item-right"
                      style={{
                        borderRight: `2px solid ${typeColor}`,
                      }}
                    />

                    <div className="box-row-item">
                      <div className="box-row-item-title">Fee</div>
                      <div className="box-row-item-value">
                        {selectedFee || "-"}
                      </div>
                    </div>

                    <div className="box-row-item-empty">
                      <div className="box-row-item-title"></div>
                      <div className="box-row-item-value">+</div>
                    </div>

                    <div className="box-row-item">
                      <div className="box-row-item-title">Add-ons</div>
                      <div className="box-row-item-value">
                        {selectedAddOns || "-"}
                      </div>
                    </div>

                    <div
                      className="box-row-item-right"
                      style={{
                        borderRight: `2px solid ${typeColor}`,
                      }}
                    />

                    <div className="box-row-item">
                      <div className="box-row-item-title">Position</div>
                      <div className="box-row-item-value-small">
                        {selectedPosition || "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="box-transfer-container"
                  style={{
                    borderLeft: `2px solid ${typeColor}`,
                    borderRight: `2px solid ${typeColor}`,
                    backgroundImage: `url(${blueBg})`,
                  }}
                >
                  <img
                    className={"header-transfer-from"}
                    src={parseMediaUrl(_.get(selectedTransferFrom, "url"))}
                  />
                  <img
                    className={"header-transfer-arrow"}
                    src={transfer_arrow}
                  />
                  <img
                    className={"header-transfer-to"}
                    src={parseMediaUrl(_.get(selectedTransferTo, "url"))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
