import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, templateName, data } = props;

  const timeline = anime.timeline();

  $(
    `#${id} .main-title, #${id} .sub-title, #${id} .table-row, #${id} .teams-block > div`,
  ).css("opacity", 0);
  $(`#${id} .facts-block > div`).css("opacity", 0);
  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .sub-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .teams-block > div`],
      duration: 1500,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: anime.stagger(100),
    });

    anime({
      targets: [$(`#${id} .facts-block > div`).toArray()],
      duration: 1200,
      easing: "easeOutQuint",
      translateY: [2000, 0],
      opacity: [1, 1],
      delay: anime.stagger(500),
    });
  }, 800);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
