import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/appteaser";

import assets from "./assets";
import { format as dateFormat } from "date-fns";

import { de } from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { utcToZonedTime } from "date-fns-tz";
import countries from "../../../../../language/de/countries.json";
import { toIsoDateString } from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const openerType = getContentById("openerType", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDateTmp =
    new Date(toIsoDateString(matchInfo?.date, matchInfo?.time)) || "";

  const timeZone = "Europe/Berlin";
  const thisGameDate = utcToZonedTime(thisGameDateTmp, timeZone);

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamHomeAlpha2 = getOptaCountryCode(
    homeTeam?.name,
    homeTeam?.shortName,
    homeTeam?.code,
  );
  const teamAwayAlpha2 = getOptaCountryCode(
    awayTeam?.name,
    awayTeam?.shortName,
    awayTeam?.code,
  );

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        {/*<Backgrounds bg1={backgroundImage} loop={true} />*/}
        <WcBgCircle />

        <div
          className={"teaser_bg"}
          style={{ backgroundImage: `url(${assets.world_bg})` }}
        >
          <div
            className={"teaser_bg_layer2"}
            style={{ backgroundImage: `url(${assets.line_bg_layer})` }}
          ></div>
          <img className={"powered-by"} src={assets.samsung_tippspiel} />
          <div className={"slide slide1"}>
            <div className={"teaser-text text-1"}>
              Gewinne Samsung-Produkte
              <br />
              im Wert von bis zu 40.000€
            </div>
            <div className={"teaser-text green-glow text-2"}>
              Mach mit beim Samsung <br />
              Tippspiel!
            </div>
            <img className={"winner-circle"} src={assets.winner_circle} />
          </div>

          <div className={"slide slide2"}>
            <div className={"teaser-text text-1"}>
              Ganz gleich, wann du <br />
              einsteigst: du kannst <br />
              immer gewinnen.
            </div>
            <div className={"teaser-text green-glow text-2"}>
              Tippe täglich die <br />
              Partien des Tages!
            </div>
            <img className={"devices-magenta"} src={assets.devices_magenta} />
          </div>
          <div className={"slide slide3"}>
            <div className={"teaser-text text-1"}>
              Öffne die Sportworld App <br />
              auf deinem Handy - oder <br />
              <span className={"green-glow"}>
                <b>scanne den QR-Code</b>
              </span>
            </div>
            <img className={"qr-code"} src={assets.qr_code} />
            <img className={"s21-mockup"} src={assets.s21_mockup} />
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
