import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import Backgrounds from "../_partials/Backgrounds/Index";

import assets from "../../templates/adler/Results/assets";
import Image from "../../../shared/components/Image";
import { getDynamicLogoPath } from "../../../shared/utils/functions";

require("./style/boxStyle.scss");

const getMainBackground = () => {
  return assets.background_1920;
};

const getCountryFromId = (id, teams) => {
  const team = _.find(teams, { id: id });
  return team?.name;
};

const getTeamLogoById = (id, teams, competitionId, feedType = "opta") => {
  const logoUrl = _.get(_.find(teams, { id: id }), "logo.url");
  if (logoUrl) {
    return <Image className="team-logo" src={`${config.apiUrl}${logoUrl}`} />;
  }

  const team = _.find(teams, { id: id });
  const countryCode = _.get(team, "name");
  const countryCodeShortcut = _.get(team, "shortcut");
  const opta3Alpha = _.get(team, "code");

  const src = getDynamicLogoPath({
    team: {
      name: countryCode,
      shortName: countryCodeShortcut,
      code: opta3Alpha,
      id,
    },
    competitionId,
    feedType,
  });
  return <Image className="team-logo" src={src} />;
};
const StaticBox = (props) => {
  const { data, slide, feedType, countries, isPreview = false } = props;

  const backgroundImage = getMainBackground();

  const box = slide["box"];
  const selectedCountryId = slide["country"];
  const selectedDiscipline = slide["discipline"];
  const boxTitle = slide["boxTitle"];
  const boxDesc = slide["boxDesc"];

  return (
    <div
      className={`
              ${isPreview ? "" : "static-slide-box-container"}
              ${
                box === "left"
                  ? "static-slide-box-left"
                  : box === "right"
                    ? "static-slide-box-right"
                    : "static-slide-box-no"
              }
            `}
    >
      <Backgrounds bg1={backgroundImage} loop={true} />
      <div className="static-slide-main-box">
        <div className={"qatar-logo"}>
          <img src={assets.insight_zone_logo} />
        </div>

        <div className="heimspiel-country-container">
          <div className="heimspiel-country">
            {selectedCountryId ? (
              getTeamLogoById(selectedCountryId, countries, null, feedType)
            ) : (
              <Image
                src={`/images/dynamic/soccer/competition/olympic_2024.svg`}
              />
            )}
          </div>

          <div className={"heimspiel-info"}>
            <div className="heimspiel-info-country">
              {selectedCountryId &&
                getCountryFromId(selectedCountryId, countries)}
            </div>
            <div className="heimspiel-info-discipline">
              {selectedDiscipline}
            </div>
          </div>
        </div>
        <div className="box-content-container">
          <div className="box-title-green">{boxTitle}</div>
          <div className="box-desc">{boxDesc}</div>
        </div>
      </div>
    </div>
  );
};

export default StaticBox;
