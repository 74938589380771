import React, { useEffect, Fragment, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";

import "./style/style.scss";
import "./style/animation.scss";
import getContentById from "../../../../shared/utils/getContentById";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import TeamLogo from "../../_partials/TeamLogo";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import Stats from "./stats";
import ProgressBar from "../../../../shared/components/ProgressBar";
import _ from "lodash";
import { getGlobalMatchdays } from "../../../../../services/backend/api.service";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";
import Image from "../../../../shared/components/Image";
import sportWorldShadow from "../../../assets/wc/images/sportworld_image_shadow.png";
import { Item } from "../../../../shared/components/MultipleInput/Item";
import TopPlayerHeader from "./top-player-header";

const getMainBackground = () => {
  return assets.bg_1;
};

const getBlueBg = () => {
  return assets.bg_blue;
};

const Index = ({ animation, data, mode, format, _searchParams, ...props }) => {
  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const backgroundImage = getMainBackground();
  const blueBg = getBlueBg();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          id,
          data,
          format,
          apiSetup: props?.apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const { options, fields, dynamic } = data.data || {};

  const headline = fields.find(({ id }) => id === "headline");
  const selectedType = getContentById("type", fields);
  const typeColor =
    selectedType === "rumour"
      ? "#f6977c"
      : selectedType === "done"
        ? "#0CD97A"
        : "#ec376e";
  const selectedTypeText = getContentById("typeText", fields);

  const customBackground = getContentById("player", fields, "self");

  const selectedMarketValue = getContentById("marketValue", fields);
  const selectedFee = getContentById("fee", fields);
  const selectedAddOns = getContentById("addOns", fields);
  const selectedPosition = getContentById("position", fields);

  const selectedTransferFrom = getContentById("transfer_from", fields);
  const selectedTransferTo = getContentById("transfer_to", fields);
  const selectedBackground = getContentById("background", fields);
  const selectedLogo = getContentById("logo", fields);
  const selectedTitle = getContentById("title", fields);
  const selectedDesc = getContentById("desc", fields);
  const selectedPlayer = getContentById("player", fields, "self");
  const topPlayers = getContentById("players", fields);

  const customBackgroundStyles = _.get(
    customBackground,
    `styles.${format}`,
    {},
  );
  const transfer_arrow =
    selectedType === "rumour"
      ? assets.tm_arrow_rumour
      : selectedType === "done"
        ? assets.tm_arrow_done_deal
        : assets.tm_arrow_failed_deal;
  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <div className={"comp-backgrounds-container"}>
          <Backgrounds bg1={parseMediaUrl(selectedBackground)} loop={true} />
        </div>
        <div className={`wc-content content`}>
          <ProgressBar height={"8px"} />

          <div className={"tm-logo"}>
            <img src={assets.tm_logo} />
          </div>
          <div className={"block-side"}>
            <div className="main-header-container">
              <img
                className={"main-header-logo"}
                src={parseMediaUrl(_.get(selectedLogo, "url"))}
              />
              <div className="main-header-wrap">
                <div className="main-header">{selectedTitle}</div>
                <div className="main-header-desc">{selectedDesc}</div>
              </div>
            </div>
            <div className={`information-box`}>
              <div className="box-player">
                <img src={parseMediaUrl(_.get(selectedPlayer, "value.url"))} />
              </div>
              <div className={`box-container`}>
                <TopPlayerHeader />
                <div className="box-player-content">
                  {topPlayers?.map((player, index) => (
                    <Item
                      item={player}
                      showRank={true}
                      isEven={index % 2 === 0}
                      rank={index + 1}
                      isLast={index === topPlayers?.length - 1}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
