import React, { useState, useEffect } from "react";
import frontendCfg from "../../../config/frontend";

export const usePlayoffsVideo = ({ mode, isTimeline, animation, id }) => {
  useEffect(() => {
    if (mode === "video" && !isTimeline) {
      const outroVideo = $(`#${id} video.outro-optional-video`);

      const handler = (event) => {
        const element = outroVideo?.get(0);
        setTimeout(() => {
          outroVideo.css("opacity", 1);
          element.currentTime = 0;
          element.play();
        }, frontendCfg.showOutroAfter);
      };

      outroVideo.get(0)?.addEventListener("loadedmetadata", handler, false);
      return () => window.removeEventListener("loadedmetadata", handler);
    }
  }, [animation, mode]);
};
