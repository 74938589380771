import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  pitch: require("../../../assets/wc/images/pitch.png"),
  trikot: require("../../../assets/wc/images/trikot.png"),
  germanStadiumsDe: require("./videos/german_de.webm"),
  germanStadiumsEn: require("./videos/german_en.webm"),
  insight_zone_logo: require("../../../assets/wc/images/insight_zone_white.png"),
};

export const stadiums = [
  {
    name: "Imtech Arena",
    y: 53.587158,
    x: 9.898617,
    cx: 460.7123840476238,
    cy: 230.34952509058056,
    city: "Hamburg",
    index: 1,
    direction: "left",
    knownName: "Volksparkstadion",
  },
  {
    name: "Millerntor-Stadion",
    y: 53.554444,
    x: 9.967778,
    cx: 465.7538261745256,
    cy: 234.3652045785299,
    city: "Hamburg",
    index: 2,
    direction: "left",
  },
  {
    name: "Weserstadion",
    y: 53.066394,
    x: 8.837628,
    cx: 383.3732908452164,
    cy: 293.9098788281128,
    city: "Bremen",
    index: 3,
    direction: "left",
  },
  {
    name: "Olympiastadion",
    y: 52.514722,
    x: 13.239444,
    cx: 704.2391182914707,
    cy: 360.4068210469138,
    city: "Berlin",
    index: 4,
    direction: "right",
    knownName: "Olympiastadion Berlin",
  },
  {
    name: "Volkswagen Arena",
    y: 52.431944,
    x: 10.803889,
    cx: 526.7022080305479,
    cy: 370.3141039571486,
    city: "Wolfsburg",
    index: 5,
    direction: "right",
  },
  {
    name: "HDI-Arena",
    y: 52.360067,
    x: 9.731197,
    cx: 448.5096213838198,
    cy: 378.90104452150047,
    city: "Hanover",
    index: 6,
    direction: "left",
  },
  {
    name: "Eintracht-Stadion",
    y: 52.29,
    x: 10.521667,
    cx: 506.1300878006719,
    cy: 387.2573016704452,
    city: "Braunschweig",
    index: 7,
    direction: "right",
  },
  {
    name: "Veltins-Arena",
    y: 51.554503,
    x: 7.067589,
    cx: 241.84969673859166,
    cy: 461.6931333026703,
    city: "Gelsenkirchen",
    index: 8,
    direction: "left",
    knownName: "Arena AufSchalke",
  },
  {
    name: "Signal Iduna Park",
    y: 51.492569,
    x: 7.451842,
    cx: 252.859432994155,
    cy: 498.94888173444815,
    city: "Dortmund",
    index: 9,
    direction: "left",
    knownName: "BVB Stadion Dortmund",
  },
  {
    name: "Leipzig Stadium",
    x: 51.2044,
    y: 12.2054,
    cx: 616.3648405440528,
    cy: 502.57809068794245,
    city: "Leipzig",
    index: 10,
    direction: "right",
    knownName: "Leipzig Stadium",
  },
  {
    name: "Esprit Arena",
    y: 51.261667,
    x: 6.733056,
    cx: 217.4645571910166,
    cy: 495.91436724150833,
    city: "Düsseldorf",
    index: 11,
    direction: "left",
    knownName: "Düsseldorf Arena",
  },
  {
    name: "Borussia-Park",
    y: 51.174583,
    x: 6.385464,
    cx: 204.62731425801212,
    cy: 518.5491761859007,
    city: "Mönchengladbach",
    index: 12,
    direction: "left",
  },
  {
    name: "BayArena",
    y: 51.038256,
    x: 7.002206,
    cx: 249.5841342046874,
    cy: 534.3758068878034,
    city: "Leverkusen",
    index: 13,
    direction: "left",
  },
  {
    name: "RheinEnergieStadion",
    y: 50.933497,
    x: 6.874997,
    cx: 227.81147215349665,
    cy: 534.0064299699184,
    city: "Cologne",
    index: 14,
    direction: "left",
    knownName: "Cologne Stadium",
  },
  {
    name: "Commerzbank-Arena",
    y: 50.068572,
    x: 8.645458,
    cx: 369.3679453628561,
    cy: 645.6307710887752,
    city: "Frankfurt",
    index: 15,
    direction: "left",
    knownName: "Frankfurt Arena",
  },
  {
    name: "Coface Arena",
    y: 49.984167,
    x: 8.224167,
    cx: 338.6584942155899,
    cy: 655.2080161433787,
    city: "Mainz",
    index: 16,
    direction: "left",
  },
  {
    name: "Trolli Arena",
    y: 49.486944,
    x: 10.999167,
    cx: 540.9393581831548,
    cy: 711.2857221059347,
    city: "Fürth",
    index: 17,
    direction: "right",
  },
  {
    name: "Fritz-Walter-Stadion",
    y: 49.434722,
    x: 7.776667,
    cx: 306.0389649834602,
    cy: 717.1441985893164,
    city: "Kaiserslautern",
    index: 18,
    direction: "left",
  },
  {
    name: "Grundig-Stadion",
    y: 49.426111,
    x: 11.125833,
    cx: 550.1725825309423,
    cy: 718.1074724496281,
    city: "Nuremberg",
    index: 19,
    direction: "right",
  },
  {
    name: "Rhein-Neckar Arena",
    y: 49.239008,
    x: 8.888281,
    cx: 387.0689571718208,
    cy: 739.0376048764243,
    city: "Sinsheim",
    index: 20,
    direction: "left",
  },
  {
    name: "Mercedes-Benz Arena",
    y: 48.792269,
    x: 9.232031,
    cx: 412.12659511071143,
    cy: 788.6900458895029,
    city: "Stuttgart",
    index: 21,
    direction: "left",
    knownName: "Stuttgart Arena",
  },
  {
    name: "SGL arena",
    y: 48.3225,
    x: 10.882222,
    cx: 532.4157655617967,
    cy: 840.4268387096342,
    city: "Augsburg",
    index: 22,
    direction: "right",
  },
  {
    name: "Allianz Arena",
    y: 48.218775,
    x: 11.624753,
    cx: 586.5417963745836,
    cy: 851.7857612070167,
    city: "Munich",
    index: 23,
    direction: "right",
    knownName: "Fußball Arena München",
  },
  {
    name: "MAGE SOLAR Stadion",
    y: 47.988889,
    x: 7.893056,
    cx: 314.52421831101077,
    cy: 876.8817845686058,
    city: "Freiburg",
    index: 24,
    direction: "left",
  },
];

export default assets;
