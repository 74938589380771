import React, { useEffect, Fragment, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";

import "./style/style.scss";
import "./style/animation.scss";
import getContentById from "../../../../shared/utils/getContentById";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import TeamLogo from "../../_partials/TeamLogo";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import Stats from "./stats";
import ProgressBar from "../../../../shared/components/ProgressBar";
import _ from "lodash";
import { getGlobalMatchdays } from "../../../../../services/backend/api.service";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";
import Image from "../../../../shared/components/Image";
import sportWorldShadow from "../../../assets/wc/images/sportworld_image_shadow.png";

const getMainBackground = () => {
  return assets.background_1920;
};

const getCountryFromId = (id, teams) => {
  const team = _.find(teams, { id: id });
  return team?.name;
};

const getTeamLogoById = (id, teams, competitionId, feedType = "opta") => {
  const logoUrl = _.get(_.find(teams, { id: id }), "logo.url");
  const fallback = "/images/preview/worldcup/ball.png";
  if (logoUrl) {
    return <Image className="team-logo" src={`${config.apiUrl}${logoUrl}`} />;
  }

  const team = _.find(teams, { id: id });
  const countryCode = _.get(team, "name");
  const countryCodeShortcut = _.get(team, "shortcut");
  const opta3Alpha = _.get(team, "code");

  const src = getDynamicLogoPath({
    team: {
      name: countryCode,
      shortName: countryCodeShortcut,
      code: opta3Alpha,
      id,
    },
    competitionId,
    feedType,
  });
  return <Image className="team-logo" src={src} />;
};

const Index = ({ animation, data, mode, format, _searchParams, ...props }) => {
  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const backgroundImage = getMainBackground();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          id,
          data,
          format,
          apiSetup: props?.apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const { options, fields, dynamic } = data.data || {};

  const { matchDetails = {}, teamSide } = data?.data?.dynamic || {};

  const matchStatus = matchDetails?.matchStatus;
  const scores = matchDetails?.scores;

  const teamId = data?.event[teamSide]?.id;

  const headline = fields.find(({ id }) => id === "headline");
  const stats = fields.find(({ id }) => id === "stats")?.value || [];

  const type = fields.find(({ id }) => id === "type")?.value || "player-facts";

  const facts = fields.filter(({ type }) => type === "html");

  const selectedSide = getContentById("selectedSide", fields);
  const imageSide = getContentById("imageSide", fields) || "left";
  const isLeft = imageSide === "left";
  const isRight = imageSide === "right";
  const isTop = imageSide === "top";
  const isBottom = imageSide === "bottom";
  const selectedCountryId = getContentById("facts_country", fields);
  const selectedDiscipline = getContentById("facts_discipline", fields);
  const homeTeam = data?.event?.home;
  const awayTeam = data?.event?.away;
  const matchInfo = _.get(dynamic, "matchInfo");
  const competitionId = data?.event?.competitionId;

  const customBackground = getContentById("background", fields, "self");
  const customBackgroundStyles = _.get(
    customBackground,
    `styles.${format}`,
    {},
  );

  const [countries, setCountries] = useState([]);
  const [feedType, setFeedType] = useState("opta");
  useEffect(() => {
    const feed_type = data?.event?.feedType;
    setFeedType(feed_type);
    if (feed_type === "heimspiel") {
      getGlobalMatchdays({ feedType: feed_type })
        .then((data) => {
          const apiDataTeams = _.get(data, "data.teams", []);
          setCountries(apiDataTeams);
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  }, data?.event);
  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""} image-side-${imageSide}`}
    >
      <TemplateBlock>
        <div
          className={
            isLeft
              ? "comp-backgrounds-container"
              : isRight
                ? "comp-backgrounds-container-content"
                : isTop
                  ? "comp-backgrounds-container-top"
                  : isBottom
                    ? "comp-backgrounds-container-bottom"
                    : ""
          }
        >
          <Backgrounds bg1={backgroundImage} loop={true} />
        </div>
        <div className={`wc-content content`}>
          <ProgressBar height={"8px"} />
          {/*<div className={"wc-bg-content"}></div>*/}

          <img className={"sportworld-image-shadow"} src={sportWorldShadow} />

          {/* <ProgressBar height="8px" /> */}
          <div
            className={
              isLeft
                ? "left-block-side"
                : isRight
                  ? "left-block-content-side"
                  : isTop
                    ? "top-block-side"
                    : isBottom
                      ? "bottom-block-side"
                      : ""
            }
          >
            <div className={"custom-background-container"}>
              <div
                id={_.get(customBackground, "id", null)}
                style={customBackgroundStyles}
                className={"custom-background-container"}
              >
                <img
                  className={"custom-background"}
                  src={parseMediaUrl(_.get(customBackground, "value.url"))}
                  style={{
                    objectFit: _.isEmpty(customBackgroundStyles) ? "cover" : "",
                  }}
                />
              </div>

              {/*<ImageOrVideo*/}
              {/*  loop={true}*/}
              {/*  className={"custom-background"}*/}
              {/*  src={parseMediaUrl(customBackground.url)}*/}
              {/*  searchParams={_searchParams}*/}
              {/*  animation={animation}*/}
              {/*/>*/}
            </div>
          </div>
          <div
            className={
              isLeft
                ? "right-block-side"
                : isRight
                  ? "right-block-content-side"
                  : isTop
                    ? "top-block-content-side"
                    : "bottom-block-content-side"
            }
          >
            <div className={isLeft || isRight ? "box" : "box-row"}>
              {(isLeft || isRight) && (
                <div className="qatar-logo">
                  <img src={assets.insight_zone_logo} alt="" />
                </div>
              )}

              {type === "game-stats" ? (
                <MatchScoreWidget
                  homeTeam={homeTeam}
                  awayTeam={awayTeam}
                  scores={scores}
                  matchStatus={matchStatus}
                  matchDetails={matchDetails}
                  competitionId={competitionId}
                  matchInfo={matchInfo}
                />
              ) : feedType === "heimspiel" ? (
                <div
                  className={
                    isTop || isBottom ? "heimspiel-country-wrapper" : ""
                  }
                >
                  <div className="heimspiel-country-container">
                    <div className="heimspiel-country">
                      {selectedCountryId ? (
                        getTeamLogoById(
                          selectedCountryId,
                          countries,
                          null,
                          feedType,
                        )
                      ) : (
                        <Image
                          src={`/images/dynamic/soccer/competition/olympic_2024.svg`}
                        />
                      )}
                    </div>

                    <div
                      className={
                        isLeft || isRight
                          ? "heimspiel-info"
                          : "heimspiel-info-top"
                      }
                    >
                      <div className="heimspiel-info-country">
                        {selectedCountryId &&
                          getCountryFromId(selectedCountryId, countries)}
                      </div>
                      <div className="heimspiel-info-discipline">
                        {selectedDiscipline}
                      </div>
                    </div>
                  </div>
                  {(isTop || isBottom) && (
                    <div className="main-header green-glow">
                      {headline?.value}
                    </div>
                  )}
                </div>
              ) : (
                <TeamLogo
                  team={selectedSide === "home" ? homeTeam : awayTeam}
                  competitionId={competitionId}
                  className="team-logo-single"
                />
              )}

              {(isLeft || isRight) && (
                <div className="main-header green-glow">{headline?.value}</div>
              )}

              {type === "player-facts" ? (
                <div
                  className={isTop || isBottom ? "fact-content-wrapper" : ""}
                >
                  {facts.map((fact) => {
                    return fact?.value ? (
                      <div key={fact.id}>
                        <div className="green-line" />
                        <div
                          dangerouslySetInnerHTML={{ __html: fact.value }}
                          className="player-facts-fact"
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              ) : null}

              {type === "player-fact-stats" ? (
                <>
                  <div className="player-fact-stats">
                    <div
                      dangerouslySetInnerHTML={{ __html: facts[0].value }}
                      className="fact"
                    />
                  </div>
                  <Stats stats={stats} />
                </>
              ) : null}

              {type === "game-stats" ? (
                <div className="game-stats">
                  <Stats stats={stats} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
