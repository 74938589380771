import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import "./language";
import App from "./App";
import configFrontend from "./config/frontend";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom";
import {
  createReactRouterV5Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterVersion,
} from "@grafana/faro-react";
// import { TracingInstrumentation } from "@grafana/faro-web-tracing";

import { faro } from "@grafana/faro-react";

// Store the original console.error function
const originalConsoleError = console.error;

console.log("INDEX - NODE_ENV", process.env.NODE_ENV);

if (
  process.env.NODE_ENV === "production" &&
  configFrontend?.client === "adler_live"
) {
  // initializeFaro({
  //   url: "https://faro-collector-prod-eu-west-2.grafana.net/collect/521dd200c1ae9219253e4859d27bbe46",
  //   app: {
  //     name: "Adler",
  //     version: "1.0.0",
  //     environment: "production",
  //   },
  //   sessionTracking: {
  //     enabled: false,
  //   },
  //   instrumentations: [
  //     // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
  //     ...getWebInstrumentations({
  //       captureConsole: true,
  //       captureConsoleDisabledLevels: [
  //         LogLevel.DEBUG,
  //         LogLevel.TRACE,
  //         LogLevel.LOG,
  //       ],
  //     }),
  //     // Initialization of the tracing package.
  //     // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
  //     // new TracingInstrumentation(),
  //   ],
  // });

  console.log("INDEX - INIT MONITORING 1 - READY");
}

if (
  process.env.NODE_ENV === "production" &&
  configFrontend?.client === "womansworldcup_live"
) {
  initializeFaro({
    url: "https://faro-collector-prod-eu-west-2.grafana.net/collect/d7488ba19a90c34598e6348cb4ff4c89",
    app: {
      name: "sportworld-spovizz-app",
      version: "1.0.0",
      environment: "production",
    },

    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),
      // React integration for React applications.
      new ReactIntegration({
        router: createReactRouterV5Options({
          history, // the history object used by react-router
          Route, // Route component imported from react-router package
        }),
      }),
    ],
  });
  console.log(
    "INDEX - INIT MONITORING 2 - READY, overriding console.error....",
  );

  // Override console.error
  console.error = function (message, ...optionalParams) {
    // If the message is a string and optionalParams is not empty, concatenate them into a single error message
    let combinedMessage = message;

    if (optionalParams.length > 0) {
      combinedMessage +=
        " " +
        optionalParams
          .map((param) => {
            if (param instanceof Error) {
              return param.message; // If it's an Error object, use its message
            } else if (typeof param === "object") {
              return JSON.stringify(param); // If it's an object, stringify it
            } else {
              return String(param); // For other types, convert to string
            }
          })
          .join(" ");
    }

    // Convert the combined message into an Error object if it's not already
    const error =
      message instanceof Error ? message : new Error(combinedMessage);

    // Send the error to Faro
    faro.api.pushError(error);

    // Call the original console.error to preserve the default behavior
    originalConsoleError.apply(console, [message, ...optionalParams]);
  };
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
