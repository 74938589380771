import React, { useEffect, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

import "./style/style.scss";
import "./style/animation.scss";
import Arrow from "./arrow";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";

const getMainBackground = () => {
  return assets.background_1920;
};

export default function Index({ animation, data, mode, format, ...props }) {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { t } = useTranslation();

  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const { matchInfo, matchDetails, attackingZones, teamSide } =
    data?.data?.dynamic || {};
  const { matchStatus, scores } = matchDetails || {};

  const backgroundImage = getMainBackground(format, teamSide);


  const competitionId = matchInfo?.competition?.id
  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup: props?.apiSetup,
          templateName: data?.template_name
        });
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const team = teamSide === "home" ? homeTeam : awayTeam;

  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""} side-${teamSide}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        {attackingZones ? (
          <div className="wc-content wc-content-v2">
            <ProgressBar height="8px" />
            <div className="wc-bg-content"></div>

            <div className="qatar-logo">
              <img src={assets.insight_zone_logo} />
            </div>

            <MatchScoreWidget
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              scores={scores}
              matchStatus={matchStatus}
              matchDetails={matchDetails}
              competitionId={competitionId}
              matchInfo={matchInfo}
            />

            <div className={"left-block-side"}>
              <div className="teams-block">
                <div className={`team-logo-container ${teamSide}`}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({team: team, competitionId})}
                  />
                  <div className={`team-name ${teamSide}`}>
                    {t(team?.name, {
                      ns: "countries",
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={"right-block-side"}>
              <div className="main-header green-glow">
                {t("AttackingZonesHeadline", { keyPrefix: "templates" })}
              </div>

              <div
                className={`attacking-zones`}
                style={{ backgroundImage: `url(${assets.pitch})` }}
              >
                <div className="arrows">
                  <Arrow id={"leftZone"} value={attackingZones?.leftZone} />
                  <Arrow
                    id={"centralZone"}
                    value={attackingZones?.centralZone}
                  />
                  <Arrow id={"rightZone"} value={attackingZones?.rightZone} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </TemplateBlock>
    </div>
  );
}
