import React, { useEffect, useRef, useState } from "react";
import {
  startAnimation,
  endAnimation,
  animationCleanUp,
} from "../../../../animations/worldcup/alarm";

import GoalAlarm from "../../../frontend/templates/worldcup/GoalAlarm";

const AlarmTemplate = (props) => {
  const { showAlarm, mode, data, Template } = props;
  useEffect(() => {
    if(showAlarm) {
      let animationStart = null;
      let animationEnd = null;

      if (showAlarm === "start" && mode === "animation") {
        animationStart = startAnimation({});
      }
      if (showAlarm === "end" && mode === "animation") {
        animationEnd = endAnimation({});
      }
      return function cleanUp() {
        if (animationStart) {
          animationCleanUp(animationStart);
        }
        if (animationEnd) {
          animationCleanUp(animationEnd);
        }
      };
    }

  }, [showAlarm, mode]);
  // if (!Template) {
  //   return;
  // }

  return (
    <div className="alarm-container">
      {/**
       * Need to select correct template according to event data
       * For example: Goal Template, Yellow Card Template, Shot template etc
       * For now just use dummy template
       */}
      {/* {showAlarm && <Template {...props}></Template>} */}

      <div className={"template-container"}>
        {showAlarm && (
          <GoalAlarm data={data} mode={mode} animation={true}></GoalAlarm>
        )}
      </div>

      <div className="child-template-container">{props.children}</div>
    </div>
  );
};

export default AlarmTemplate;
