import _ from "lodash";
import { utcToZonedTime, format } from "date-fns-tz";
import moment from "moment-timezone";
import { compareAsc, eachDayOfInterval, parseISO, startOfDay } from "date-fns";

const timezones = {
  en: "UTC",
  de: "Europe/Berlin",
};

const timezoneStrings = {
  en: "UTC",
  de: "", // Note: This is a simplification and doesn't account for daylight saving time changes.
};

export const getTimeInZone = (
  isoDateString,
  language,
  showTimezone = false,
) => {
  // console.log("Original ISO Date String:", isoDateString);
  // console.log("Language:", language);

  const timezone = timezones[language] || timezones["en"];
  // console.log('Using Timezone:', timezone);

  try {
    // Parse the ISO date string with Moment.js
    const date = moment(isoDateString);

    // Convert the date to the desired timezone
    const zonedDate = date.tz(timezone);
    // console.log('Converted Zoned Date:', zonedDate.format());

    // Format the zoned date
    let formattedDate = zonedDate.format("HH:mm");
    // console.log('Formatted Date:', formattedDate);

    // Optionally append specific timezone information
    if (showTimezone) {
      const timezoneString = timezoneStrings[language];
      formattedDate += timezoneString ? ` (${timezoneString})` : "";
    }

    return formattedDate;
  } catch (error) {
    console.error("Error parsing date:", error);
    return null;
  }
};

export const getDateInZone = (isoDateString, language) => {
  if (!isoDateString) {
    return "-";
  }

  const timezone = timezones[language] || timezones["en"];
  const formatString = language === "de" ? "DD.MM.YYYY" : "DD.MM.YYYY";

  try {
    // Parse the ISO date string with Moment.js
    const date = moment(isoDateString);

    // Convert the date to the desired timezone
    const zonedDate = date.tz(timezone);

    // Format the zoned date
    return zonedDate.format(formatString);
  } catch (error) {
    console.error("Error parsing date:", error);
    return "-";
  }
};

export function displayDays(startDateISO, endDateISO) {
  // Parse ISO date strings to moment objects
  const startDate = moment.utc(startDateISO);
  const endDate = moment.utc(endDateISO);

  // Get the current date in UTC
  const currentDate = moment.utc().startOf('day');

  // Initialize dayCounter and currentDayNumber
  let dayCounter = 1;
  let currentDayNumber = null;

  // Loop through the range of dates
  const tempDate = startDate.clone();

  while (tempDate.isSameOrBefore(endDate, 'day')) {
    if (tempDate.isSame(currentDate, 'day')) {
      currentDayNumber = dayCounter;
    }
    tempDate.add(1, 'day');
    dayCounter++;
  }

  return currentDayNumber;
}
