import i18n from "i18next";

export default function GoalLine({
  goal,
  isHome,
  score = { home: 0, away: 0 },
  isOwnGoal = false,
}) {
  const lng = i18n?.language;

  return (
    <div className={`goal-line ${isOwnGoal ? "own-goal" : ""}`}>
      <div className="home">
        {isHome ? (
          <>
            <span className="name">{goal.playerName}</span>
            <span className="time">{goal.timeMin}'</span>
          </>
        ) : (
          ""
        )}
      </div>

      <div className={`score`}>
        {score.home}:{score.away}
        <div className={"penalty"}>
          {goal?.isPenalty ? (lng === "de" ? "(11m)" : "(pen)") : ""}
        </div>
      </div>

      <div className="away">
        {!isHome ? (
          <>
            <span className="time">{goal.timeMin}'</span>
            <span className="name">{goal.playerName}</span>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
