import React, { createContext, useContext, useEffect, useState } from "react";
import { connect } from "socket.io-client";
import frontendConfig from "../../../config/frontend";

const SocketContext = createContext(null);

export const useWebsocket = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (!event || event?.key === "user") {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        setUser(storedUser);
      }
    };

    handleStorageChange(); // Initial load
    window.addEventListener("storage", handleStorageChange); // Listen for storage changes

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (user && user.token) {
      console.log("Attempting to connect socket...");
      // Extract hostname from the backendConfig.apiUrl
      const url = new URL(frontendConfig.apiUrl);
      const hostname = url.hostname;
      const port = url.port;

      // Construct the WebSocket URL
      const wsProtocol = url.protocol === "https:" ? "wss" : "ws";
      const wsUrl = port
        ? `${wsProtocol}://${hostname}:${port}?token=${user.token}`
        : `${wsProtocol}://${hostname}?token=${user.token}`;
      const newSocket = connect(wsUrl, {
        transports: ["websocket"],
      });

      newSocket.on("connect", () => console.log("Socket connected"));
      newSocket.on("disconnect", () => console.log("Socket disconnected"));

      setSocket(newSocket);

      // return () => {
      //   newSocket.close();
      // };
    } else {
      socket?.disconnect();
      setSocket(null);
    }
  }, [user]);

  const login = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  const logout = (history) => {
    localStorage.removeItem("user");
    setUser(null);
    history?.push("/backend/login");
  };

  return (
    <SocketContext.Provider value={{ socket, user, login, logout }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
