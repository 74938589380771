import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, templateName, data } = props;

  const timeline = anime.timeline();

  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });

  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  $(
    `#${id} .main-header, #${id} .table-row, #${id} .teams-block, #${id} .score-container`,
  ).css("opacity", 0);
  $(`#${id} .competition-positioner`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .main-header`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .global-title`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .powered-by`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .competition-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 600,
  });

  anime({
    targets: [`#${id} .competition-positioner`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 1000,
  });

  anime({
    targets: [`#${id} .teams-block`],
    duration: 1200,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .gameInfo`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 800,
  });

  anime({
    targets: [`#${id} .venue-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 900,
  });

  anime({
    targets: [`#${id} .score-container`],
    duration: 900,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 900,
  });

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
