import {
  clearTimeouts,
  engine as anime,
} from "../../../../../animations/index";
import { getTemplateDurationOveride } from "../../../../shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const { id, format, data, templateName } = props;

  const timeouts = [];

  const timeline = anime.timeline();

  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .insight-zone-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .subheadline, #${id} .main-header`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .custom-background`],
    duration: 800,
    easing: "easeOutQuint",
    //translateX: [-2000, 0],
    opacity: [0, 1],
    delay: 600,
  });

  anime({
    targets: [`#${id} .goals`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .goal-shots`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: anime.stagger(300, { start: 700 }),
  });

  const topValue = 75;

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .highlight`],
      duration: 800,
      easing: "easeOutQuint",
      translateX: [-2000, 0],
      opacity: [0, 1],
    });

    const $goals = $(`#${id} .goalmouth .goal`).sort((a, b) => {
      return $(a).data("sort-index") - $(b).data("sort-index");
    });

    $goals.each(function (index) {
      const side = $(this).hasClass("away") ? "away" : "home";
      anime({
        targets: this,
        duration: 400,
        easing: "easeInOutCubic",
        scale: [0, 1],
        // skewY: ["60deg", 0],
        opacity: [1, 1],
        delay: index * 800,
      });

      if (index === 0) {
        const $highlight = $(`#${id} .goal-line.highlight`);
        const currentTop = parseInt($highlight.css("top"), 10);
        $highlight.css("top", currentTop + topValue + "px");
      } else {
        setTimeout(() => {
          const $highlight = $(`#${id} .goal-line.highlight`);
          const currentTop = parseInt($highlight.css("top"), 10);
          $highlight.css("top", currentTop + topValue + "px");
        }, index * 800);
      }
    });
  }, 1000);

  timeouts.push(timeout1);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
