import { statsPerTemplate } from "../../feeds/worldcup/stats.js";
import _ from "lodash";

const versusId = "#VERSUS#";

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

const calculateStat = (
  statCfg,
  stats,
  predefinedStatsCfg = null,
  versusStats,
) => {
  try {
    const depsValues = [];
    _.each(statCfg.deps, (dep) => {
      let findValue = _.find(stats, { type: dep })?.value || 0;
      const isVersusDep = dep.indexOf(versusId) > -1;

      if (isVersusDep) {
        findValue =
          _.find(versusStats, { type: dep.replace(versusId, "") })?.value || 0;
      }

      depsValues.push({
        id: isVersusDep ? dep.replace(versusId, "") : dep,
        value: findValue,
        type: isVersusDep ? versusId : null,
      });
    });

    let finalFormula = statCfg.calc;

    _.each(depsValues, (dep) => {
      const isVersusDep = dep.type === versusId;
      const versusReplacment = isVersusDep ? "#VERSUS#" : "##";
      finalFormula = finalFormula.replaceAll(
        `${versusReplacment}${dep.id}##`,
        parseFloat(dep.value),
      );
    });

    const result = eval(finalFormula);

    return _.isFinite(result) ? result : 0;
  } catch (err) {
    return 0;
  }
};

const calculateStatSeason = (
  statCfg,
  stats,
  predefinedStatsCfg = null,
  versusStats,
) => {
  try {
    const depsValues = [];
    _.each(statCfg.deps, (dep) => {
      let findValue = _.find(stats, { name: dep })?.value || 0;
      const isVersusDep = dep.indexOf(versusId) > -1;

      if (isVersusDep) {
        findValue =
          _.find(versusStats, { name: dep.replace(versusId, "") })?.value || 0;
      }

      depsValues.push({
        id: isVersusDep ? dep.replace(versusId, "") : dep,
        value: findValue,
        name: isVersusDep ? versusId : null,
      });
    });

    let finalFormula = statCfg.calc;

    _.each(depsValues, (dep) => {
      const isVersusDep = dep.name === versusId;
      const versusReplacment = isVersusDep ? "#VERSUS#" : "##";
      finalFormula = finalFormula.replaceAll(
        `${versusReplacment}${dep.id}##`,
        parseFloat(dep.value),
      );
    });

    const result = eval(finalFormula);

    return _.isFinite(result) ? result : 0;
  } catch (err) {
    return 0;
  }
};

export const getPlayerStats = (statsType = "midfielder", stats) => {
  const predefinedStats = statsPerTemplate.playerCompare;
  const targetPos = predefinedStats?.[statsType];

  let output = _.map(targetPos, (stat, statIndex) => {
    let findValue = null;

    if (stat.calc) {
      findValue = calculateStat(stat, stats, targetPos);
    } else {
      findValue = _.find(stats, { type: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
    };
  });

  return output;
};

export const getPlayerStatsSeason = (statsType = "midfielder", stats) => {
  const predefinedStats = statsPerTemplate.playerCompareSeason;
  const targetPos =
    predefinedStats?.[statsType] || predefinedStats?.["midfielder"];

  let output = _.map(targetPos, (stat, statIndex) => {
    let findValue = null;

    if (stat.calc) {
      findValue = calculateStatSeason(stat, stats, targetPos);
    } else {
      findValue = _.find(stats, { name: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
    };
  });

  return output;
};

export const getPlayerHeatmapStats = (stats) => {
  const predefinedStats = statsPerTemplate.playerHeatmap;
  const targetPos = predefinedStats;

  let output = _.map(targetPos, (stat, statIndex) => {
    let findValue = null;

    if (stat.calc) {
      findValue = calculateStat(stat, stats, targetPos);
    } else {
      findValue = _.find(stats, { type: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
    };
  });

  return output;
};

export const getTeamStats = (stats, type = "offensive") => {
  const predefinedStats = statsPerTemplate.teamCompare[type];

  let output = _.map(predefinedStats, (stat, statIndex) => {
    let findValue = null;

    if (stat.calc) {
      findValue = calculateStat(stat, stats, predefinedStats);
    } else {
      findValue = _.find(stats, { type: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
    };
  });

  return output;
};

export const getTeamStatsSeason = (stats, type = "offensive") => {
  const predefinedStats = statsPerTemplate.teamCompareSeason[type];

  let output = _.map(predefinedStats, (stat, statIndex) => {
    let findValue = null;

    if (stat.calc) {
      findValue = calculateStatSeason(stat, stats, predefinedStats);
    } else {
      findValue = _.find(stats, { name: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
    };
  });

  return output;
};

export const getTeamGoalmouth = (stats, versusStats) => {
  const predefinedStats = statsPerTemplate.teamGoalmouth;

  let output = _.map(predefinedStats, (stat, statIndex) => {
    let findValue = null;
    const deps = {};

    if (stat.calc) {
      findValue = calculateStat(stat, stats, predefinedStats, versusStats);
      _.each(stat.deps, (dep) => {
        let findValue = _.find(stats, { type: dep })?.value || 0;

        const isVersusDep = dep.indexOf(versusId) > -1;

        if (isVersusDep) {
          findValue =
            _.find(versusStats, { type: dep.replace(versusId, "") })?.value ||
            0;
        }

        deps[dep] = parseFloat(findValue);
      });
    } else {
      findValue = _.find(stats, { type: stat.key })?.value || 0;
    }

    return {
      type: stat.key,
      value: round(findValue, 1),
      title: stat.title,
      deps,
    };
  });

  return output;
};
