import {
  clearTimeouts,
  engine as anime,
} from "../../../../../animations/index";
import { getTemplateDurationOveride } from "../../../../shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id } = props;

  const timeline = anime.timeline({
    autoplay: false,
  });

  anime({
    targets: [`#${id} .subheadline, #${id} .header-v1`],
    duration: 1500,
    easing: "easeOutQuint",
    translateX: [-500, 0],
    delay: 1000,
  });

  anime({
    targets: [`#${id} .subheadline, #${id} .header-v2`],
    duration: 1500,
    easing: "easeOutQuint",
    translateX: [-500, 0],
    delay: 1200,
  });

  anime({
    targets: [`#${id} .subheadline, #${id} .header-v3`],
    duration: 1500,
    easing: "easeOutQuint",
    translateX: [-500, 0],
    delay: 1400,
  });

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 2000,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 2200,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 2000,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 1800,
  });

  anime({
    targets: [`#${id} .comp-player-triko`],
    duration: 1500,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [-200, 0],
    translateY: [200, 0],
    scale: [0, 1],
    delay: 1800,
  });

  return {
    timeline,
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
