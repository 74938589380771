import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  pitch: require("../../../assets/wc/images/attacking_zones_pitch.png"),
  medalBronze: require("./assets/Medal_Bronze.png"),
  medalSilver: require("./assets/Medal_Silver.png"),
  medalGold: require("./assets/Medal_Gold.png"),
  medalTotal: require("./assets/Medal_All.png"),
};

export default assets;
