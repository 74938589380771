//import Image from "../../../../shared/components/Image";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { LiveStatusBadge } from "./LiveStatusBadge";
import _ from "lodash";
import TeamLogo from "../TeamLogo";
import { AlarmLiveStatusBadge } from "./AlarmLiveStatusBadge";
import { differenceInMinutes, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const calculateElapsedMinutes = ({ matchDetails, matchInfo }) => {
  const currentTime = _.get(matchInfo, "time", "");
  const periodId = _.get(matchDetails, "periodId", 16);
  const halfTimeDuration = 15; // Assuming half-time duration is 15 minutes by default

  if (currentTime) {
    const currentDate = new Date();
    const currentISODateString = `${currentDate.toISOString().substring(0, 10)}T${currentTime}`;
    const parsedStartTime = new Date(currentISODateString);

    // Get the current local time
    const now = new Date();

    // Calculate the difference in minutes
    let elapsedMinutes = differenceInMinutes(now, parsedStartTime);

    // Adjust the elapsed minutes based on the periodId
    switch (periodId) {
      case 1: // First half
        // No adjustment needed
        break;
      case 2: // Second half
        elapsedMinutes -= halfTimeDuration; // Subtract half-time duration
        break;
      case 3: // Extra time - first half
        elapsedMinutes -= halfTimeDuration + 15; // Subtract half-time and break between halves
        break;
      case 4: // Extra time - second half
        elapsedMinutes -= halfTimeDuration + 30; // Subtract half-time and breaks between halves and extra time
        break;
      case 5: // Penalty shootout
        elapsedMinutes -= halfTimeDuration + 30; // Subtract half-time and breaks, assuming penalties start right after extra time ends
        break;
      case 10: // Half time
        elapsedMinutes = 45; // Set to end of first half
        break;
      case 11: // End of second half - before extra time
        elapsedMinutes = 90; // Set to end of second half
        break;
      case 12: // Extra time - half time
        elapsedMinutes = 105; // Set to end of first extra time half
        break;
      case 13: // End of extra time - before penalties
        elapsedMinutes = 120; // Set to end of extra time
        break;
      case 14: // Full time
        elapsedMinutes = 120; // Set to full time
        break;
      case 16: // Pre-match
      default:
        elapsedMinutes = 0; // Set to 0 for pre-match and unknown states
        break;
    }

    return elapsedMinutes;
  }

  return null;
};

const MatchTime = ({ matchInfo, matchDetails, t }) => {
  const matchTime = _.get(matchDetails, "matchTime", null);
  const elapsedMinutes = calculateElapsedMinutes({ matchDetails, matchInfo });
  let time =
    _.isFinite(elapsedMinutes) && elapsedMinutes > 0
      ? Math.abs(elapsedMinutes)
      : null;

  return (
    matchTime && (
      <span className={"won-by-status"}>
        {matchTime} {t("min")}
      </span>
    )
  );
};

export const MatchScoreWidget = ({
  homeTeam,
  awayTeam,
  scores,
  matchStatus,
  matchDetails = {},
  competitionId,
  matchInfo = {},
  hideLive = false,
  isAlarm = false,
}) => {
  const hasPenalty = _.has(matchDetails, "scores.pen");
  const hasExtraTime = _.has(matchDetails, "scores.et") && !hasPenalty;
  const hasHalfTime = _.has(matchDetails, "scores.ht");

  const { t } = useTranslation();
  return (
    <div className={"comp-match-score-widget"}>
      {isAlarm && matchStatus === "Playing" && <LiveStatusBadge className={"alarm-live-status-badge"}/>}
      <div className={"widget-container"}>
        <div className={`logo-container home ${isAlarm ? "isAlarm" : ""}`}>
          <TeamLogo team={homeTeam} competitionId={competitionId} />
          {/* <Image
            className="team-logo"
            src={`/images/dynamic/soccer/team/${homeTeam?.id}.png`}
          /> */}
          <div className={"team-name home"}>
            {t(homeTeam?.name, { ns: "countries" })}
          </div>
        </div>

        {matchStatus === "Played" || matchStatus === "Playing" ? (
          <div className={"score"}>
            <div className={"score-home"}>{scores?.total?.home || 0}</div>
            <div className={"separator"}>:</div>
            <div className={"score-away"}>{scores?.total?.away || 0}</div>
            <div className={"score-type"}>
              {matchStatus === "Played" && !hideLive
                ? (hasPenalty && (
                    <span className={"won-by-status"}>
                      ({t("WonByPenalties")})
                    </span>
                  )) ||
                  (hasExtraTime && (
                    <span className={"won-by-status"}>
                      ({t("WonOnExtraTime")})
                    </span>
                  ))
                : null}

              {matchStatus === "Playing" && !hideLive ? (
                <MatchTime
                  matchDetails={matchDetails}
                  matchInfo={matchInfo}
                  t={t}
                />
              ) : null}
            </div>
            {(!isAlarm && matchStatus === "Playing" && !hideLive) && <LiveStatusBadge />}
            {/*{hasHalfTime && matchStatus === "Played" ? (*/}
            {/*  <div className={"half-time-score"}>*/}
            {/*    <div className={"score-home"}>({scores?.home || 0}</div>*/}
            {/*    <div className={"separator"}>:</div>*/}
            {/*    <div className={"score-away"}>{scores?.away || 0})</div>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
        ) : (
          <div className={"team-vs"}>
            <div className={"versus"}>{t("templates.vs")}.</div>
          </div>
        )}
        <div className={`logo-container away ${isAlarm ? "isAlarm" : ""}`}>
          <TeamLogo team={awayTeam} competitionId={competitionId} />
          {/* <Image
            className="team-logo"
            src={`/images/dynamic/soccer/team/${awayTeam?.id}.png`}
          /> */}
          <div className={"team-name away"}>
            {t(awayTeam?.name, { ns: "countries" })}
          </div>
        </div>
      </div>
    </div>
  );
};
