import {
  clearTimeouts,
  engine as anime,
} from "../../../../../animations/index";
import { getTemplateDurationOveride } from "../../../../shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  console.log({ props });
  const { id, format, data, templateName } = props;

  const timeouts = [];

  const timeline = anime.timeline();

  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event,
  });

  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .tm-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 1000,
  });

  anime({
    targets: [`#${id} .main-header-logo`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [0, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .main-header-wrap`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [0, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .custom-background`],
    duration: 600,
    easing: "linear",
    translateY: [2000, 0],
    opacity: [0, 1],
    delay: 300,
  });

  anime({
    targets: [`#${id} .information-box`],
    duration: 800,
    easing: "easeOutQuint",
    translateY: [2000, 0],
    opacity: [0, 1],
    delay: 800,
  });

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
