import React from "react";

const TopPlayerHeader = () => {
  return (
    <div>
      <div className="box-player-header">
        <div className="box-player-header-prefix" />
        <div className="box-player-header-name">player</div>
        <div className="box-player-header-age">age</div>
        <div className="box-player-header-market">
          market <br /> value
        </div>
      </div>
    </div>
  );
};

export default TopPlayerHeader;
