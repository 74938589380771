import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/teamcompare_season";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {
  getPlayerStats,
  getTeamStats,
  getTeamStatsSeason,
} from "../../../../shared/utils/opta/statsHandler";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const { t } = useTranslation();

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const liveData = _.get(dynamic, "liveData");
  const matchday = _.get(dynamic, "matchday");
  const teamCompare = _.get(dynamic, "teamCompare");
  const teamRanking = _.get(dynamic, "ranking");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const competitionId = matchInfo?.competition?.id;

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);
  const teamHomeAlpha2 = homeTeam?.id;
  const teamAwayAlpha2 = awayTeam?.id;

  const homeStats = _.get(teamCompare, "home");
  const awayStats = _.get(teamCompare, "away");
  const scores = _.get(liveData, "matchDetails.scores");

  const statsMappingHome = getTeamStatsSeason(homeStats);
  const statsMappingAway = getTeamStatsSeason(awayStats);

  const matchDetails = _.get(liveData, "matchDetails");
  const matchStatus = _.get(matchDetails, "matchStatus");

  const competitionCfg = _.find(apiSetup?.enabledCompetitions, {
    competitionIdReal: competitionId,
  });

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
            competitionId={competitionId}
            matchInfo={matchInfo}
          />

          <div className={"main-header green-glow"}>
            {competitionCfg?.competitionType === "international" &&
            competitionCfg?.competitionFormat === "International cup"
              ? t("Team Compare Tournament Headline", {
                  keyPrefix: "templates",
                })
              : t("Team Compare Season Headline", { keyPrefix: "templates" })}
          </div>

          <div className={"versus-info"}>
            {/*<div className={"group"}>*/}
            {/*  {matchInfo?.series?.name.replace(*/}
            {/*    "Group",*/}
            {/*    t("Group", { keyPrefix: "templates" }),*/}
            {/*  ) || ""}*/}
            {/*</div>*/}
            {/*<div className={'vs-team-info'}>*/}
            {/*  Spieltag {matchInfo?.week}*/}
            {/*</div>*/}
          </div>

          <div className={"teams-block"}>
            <div className={"team-logo-container home"}>
              <Image
                className="team-logo"
                src={getDynamicLogoPath({ team: homeTeam, competitionId })}
              />
              <div className={"team-name home"}>
                {t(homeTeam?.name, { ns: "countries" })}
              </div>
            </div>
            <div className={"team-logo-container away"}>
              <Image
                className="team-logo"
                src={getDynamicLogoPath({ team: awayTeam, competitionId })}
              />
              <div className={"team-name away"}>
                {t(awayTeam?.name, { ns: "countries" })}
              </div>
            </div>
          </div>

          <div className={"team-stats"}>
            {/*<div className={"score green-glow"}><div className={"score-home"}>{scores?.home || 0}</div><div className={"separator"}>:</div><div className={"score-away"}>{scores?.away || 0}</div></div>*/}

            {statsMappingHome.map((statRowHome, index) => {
              const statRowAway = statsMappingAway?.[index];

              let statRankingHome = null;
              let statRankingAway = null;

              if (statRowHome.type === "Goals") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total goals ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total goals ranking",
                })?.value;
              }

              if (statRowHome.type === "calcTotalShotsAndBlocked") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total scoring att ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total scoring att ranking",
                })?.value;
              }

              if (statRowHome.type === "Shots On Target ( inc goals )") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total ontarget scoring att ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total ontarget scoring att ranking",
                })?.value;
              }

              if (statRowHome.type === "Corners Taken (incl short corners)") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total won corners ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total won corners ranking",
                })?.value;
              }

              if (statRowHome.type === "Total Fouls Conceded") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total was fouled ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total was fouled ranking",
                })?.value;
              }

              if (statRowHome.type === "calcPassquote") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total accurate pass ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total accurate pass ranking",
                })?.value;
              }

              if (statRowHome.type === "Total touches in opposition box") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total touches in opposition box ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total touches in opposition box ranking",
                })?.value;

                statRowHome.value = _.find(teamRanking?.home, {
                  type: "total touches in opposition box",
                })?.value;
                statRowAway.value = _.find(teamRanking?.away, {
                  type: "total touches in opposition box",
                })?.value;
              }

              if (statRowHome.type === "calcZweikampfquote") {
                statRankingHome = _.find(teamRanking?.home, {
                  type: "total duels won ranking",
                })?.value;
                statRankingAway = _.find(teamRanking?.away, {
                  type: "total duels won ranking",
                })?.value;
              }

              return (
                <div key={statRowHome.type} className={"stat-row"}>
                  <div className={"stat-home"}>{statRowHome?.value || "0"}</div>
                  <div className={"stat-label"}>
                    {t(statRowHome?.type, { keyPrefix: "stats" })}
                  </div>
                  <div className={"stat-away"}>{statRowAway?.value || "0"}</div>
                </div>
              );
            })}

            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'possessionPercentage'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Ballbesitz (%)</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'possessionPercentage'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'totalPass'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Anzahl Pässe</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'totalPass'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'accuratePass'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Passquote</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'accuratePass'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'wonTackle'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Zweikämpfe (gewonnen)</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'wonTackle'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'totalScoringAtt'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Schüsse aufs Tor</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'totalScoringAtt'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'totalOffside'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Abseits</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'totalOffside'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'shotOffTarget'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Schuss vom Ziel</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'shotOffTarget'})?.value || '-'}</div>*/}
            {/*</div>*/}
            {/*<div className={"stat-row"}>*/}
            {/*  <div className={"stat-home"}>{_.find(homeStats, {type: 'goalKicks'})?.value || '-'}</div>*/}
            {/*  <div className={"stat-label"}>Torschuss</div>*/}
            {/*  <div className={"stat-away"}>{_.find(awayStats, {type: 'goalKicks'})?.value || '-'}</div>*/}
            {/*</div>*/}
          </div>

          {/*<div className={"asterix-info"}>*/}
          {/*  {t("League ranking asterix", { keyPrefix: "templates" })}*/}
          {/*</div>*/}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
