import TeamLogo from "../../_partials/TeamLogo";
import React from "react";
import { useTranslation } from "react-i18next";

const goalmouth = require("../../../assets/wc/images/goalmouth.png");

const goalCoordsParams = {
  maxHigh: 42.0,
  maxRight: 44.2,
  maxLeft: 55.8,
}; //included BARS

function getCoords({ y, z }) {
  const bottom = (z / goalCoordsParams.maxHigh) * 100;

  const right =
    ((y - goalCoordsParams.maxRight) /
      (goalCoordsParams.maxLeft - goalCoordsParams.maxRight)) *
    100;

  return {
    bottom,
    right,
  };
}

function getSide({ goal, away }) {
  let side = away ? "away" : "home";

  if (side === "away" && goal?.isOwnGoal) {
    return "home";
  }

  if (side === "home" && goal?.isOwnGoal) {
    return "away";
  }

  return side;
}

export default function Goals({
  team,
  allGoals,
  kit,
  side,
  home,
  away,
  goalsSortOrder,
  competitionId,
}) {
  const { t } = useTranslation();
  const homeId = home?.id;
  const awayId = away?.id;

  const goals = allGoals.filter(({ contestantId, isOwnGoal }) => {
    if (isOwnGoal) {
      if (side === "home") {
        return contestantId === awayId;
      }

      if (side === "away") {
        return contestantId === homeId;
      }
    }

    return contestantId === team.id;
  });

  return (
    <div className="goal-shots">
      <div className={"team-logo-container"}>
        <TeamLogo team={team} competitionId={competitionId} />
        <div className={"team-name"}>{t(team?.name, { ns: "countries" })}</div>
      </div>

      <div className={`goalmouth ${side}`}>
        <img src={goalmouth} />

        {goals.map((goal, index) => {
          const { bottom, right } = getCoords({
            y: Number.parseFloat(goal.goalY),
            z: Number.parseFloat(goal.goalZ),
          });

          const sortOrder = _.find(goalsSortOrder, { id: goal?.id })?.index;

          const side = getSide({ goal, away });

          return (
            <div
              className={`goal ${side} ${goal?.isOwnGoal ? "own-goal" : ""}`}
              style={{
                right: `${right}%`,
                bottom: `${bottom}%`,
                backgroundColor: "#75d418",
              }}
              key={goal.id}
              data-sort-index={sortOrder}
            />
          );
        })}
      </div>
    </div>
  );
}
