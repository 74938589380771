import "./style.css";
import { globalAssets } from "../../../assets/global/assets";

export default function InsightZoneLogo({ className }) {
  return (
    <div className={`insight-zone-logo ${className ?? ""}`}>
      <img src={globalAssets.insight_zone_logo} alt="" />
    </div>
  );
}
