import React, { useEffect, useLayoutEffect, useState } from "react";
import TimelinePreview from "../../../shared/components/TimelinePreview/Index";
import { getTimelineById } from "../../../../services/frontend/template.service";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { debouncedUpdateScale } from "../../../shared/utils/scalingUtils";
import { audioManager } from "../../../shared/utils/audio-manager";
import { useGlobalStore } from "../../../../stores/globalBackendStore";
import { useGlobalFrontendStore } from "../../../../stores/globalFrontendStore";
import FrontendCfg from "../../../../config/frontend";
import { AlternativeLoader } from "../../../shared/components/Loader";
import TimelineLiveIndicator from "../../templates/_partials/TimelineLiveIndicator/Index";
import { SocketProvider } from "../../../shared/contexts/websocket";

// import Templates from "../../templates/Index";
const handleResize = () => debouncedUpdateScale("root");

export default function PageComponent(props) {
  const apiSetup = useGlobalFrontendStore((state) => state.apiSetup);
  const { _urlParams } = props;
  const id = _.get(_urlParams, "id");
  const { t, i18n } = useTranslation();
  const lng = i18n?.language;
  const [timelineData, setTimelineData] = useState(null);
  const [timelineStats, setTimelineStats] = useState({});
  const [refreshTimeline, setRefreshTimeline] = useState(false);
  const search = useLocation()?.search;
  const searchParams = new URLSearchParams(search);
  const queryLng = searchParams.get("lng");
  const ads = searchParams.get("ads")?.toLowerCase() === "true";
  const queryFormat = searchParams.get("format") || "1920x1080";
  const params = {
    lng: queryLng,
    format: queryFormat,
    ads,
  };

  const [isLoading, setIsLoading] = useState(true);

  const refreshTimelineCall = async () => {
    setIsLoading(true);
    try {
      const timelineData = await getTimelineById({ id, lng: queryLng });
      const apiData = _.get(timelineData, "data", []);
      setTimelineData(apiData);
    } catch (err) {
      console.error("Timeline err", err);
    }
  };

  useEffect(() => {
    const asyncCall = async () => {
      await refreshTimelineCall();
    };
    asyncCall();
  }, [id]);

  useEffect(() => {
    const asyncCall = async () => {
      if (refreshTimeline) {
        setRefreshTimeline(false);
        await refreshTimelineCall();
      }
    };
    asyncCall();
  }, [refreshTimeline]);

  const queryResponsive = new URLSearchParams(useLocation()?.search).get(
    "responsive",
  );
  const queryStream = new URLSearchParams(useLocation()?.search).get("stream");

  useEffect(() => {
    if (queryResponsive === "scale" && timelineData) {
      // Call on component mount
      handleResize();

      // Attach the event listener for window resize
      window.addEventListener("resize", handleResize);

      // Add event listener for postMessage from parent
      window.addEventListener("message", handleMessageFromParent);

      // Clean up the event listeners on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("message", handleMessageFromParent);
      };
    }
  }, [queryResponsive, timelineData]);

  useEffect(() => {
    if (apiSetup?.timelineExtras?.music?.length) {
      _.forEach(apiSetup?.timelineExtras?.music, (track) => {
        const name = track?.uuid;
        const url = FrontendCfg.apiUrl + track?.url;
        audioManager.loadTrack(name, url);
      });
    } else if (apiSetup?.video?.timelineaudio) {
      const audioUrl = FrontendCfg.apiUrl + apiSetup?.video?.timelineaudio?.url;
      audioManager.loadTrack(
        "TimelineGlobal",
        FrontendCfg.apiUrl + apiSetup?.video?.timelineaudio?.url,
      );
    }
    // Assuming 'globalTrack' is the name of your track
    // Replace 'path_to_your_audio.mp3' with the actual path to your audio file
  }, [apiSetup]);

  useEffect(() => {
    if (queryStream === "audio" && timelineData && apiSetup) {
      // Play the global track
      if (apiSetup?.timelineExtras?.music?.length) {
        const currentIndexTrack =
          audioManager.currentTrackIndex > 0
            ? audioManager.currentTrackIndex
            : 0;
        audioManager.playTrack(audioManager.trackOrder?.[currentIndexTrack]);
      } else if (
        audioManager.tracks["TimelineGlobal"] &&
        !audioManager.tracks["TimelineGlobal"]?.playing()
      ) {
        audioManager.playTrack("TimelineGlobal");
      }
    }

    // Cleanup: Stop the audio when the component unmounts or conditions change
    return () => {
      // console.log('unmount!')
      // audioManager.stopTrack('TimelineGlobal');
    };
  }, [queryStream, timelineData, apiSetup]);

  useEffect(() => {
    if (timelineData) {
      setIsLoading(false);
    }
  }, [timelineData]);
  const handleMessageFromParent = (event) => {
    console.log("#DEBUG postMessage event", event);
    // Check if the event origin is trusted, if necessary
    // if (event.origin !== 'http://parent-domain.com') {
    //   return;
    // }

    // Check the message content, if needed
    // const data = event.data;
    // if (data.someCondition) {
    //   // Handle the message
    // }

    // Handle the message from the parent
    // Do something with event.data
    // event.data will contain the message sent by the parent
  };

  return (
    <>
      {/*{(isLoading) ? (*/}
      {/*  <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',     background: 'linear-gradient(90deg, #004d5c 0%, #00112c 100%)'}}>*/}
      {/*    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>*/}
      {/*      <AlternativeLoader />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {timelineData && (
        <div
          id="timeline-preview-container"
          style={{
            transform: `scale(var(--timeline-scale))`,
            transformOrigin: "top left",
            width: "inherit",
            height: "inherit",
          }}
        >
          <div style={{ width: "inherit", height: "inherit" }}>
            <TimelinePreview
              {...props}
              changeEmitter={(data) => {
                // setTimelineStats({
                //     ...data
                // })
              }}
              previewFormat={queryFormat}
              timelineData={timelineData}
              setRefreshTimeline={setRefreshTimeline}
              animation={true}
            />
          </div>
        </div>
      )}
      {/*)}*/}
    </>
  );
}
