import { useEffect } from "react";
import { audioManager } from "../utils/audio-manager";

export default function useAnimation({
  mode,
  animation,
  id,
  animationScript,
  animationCleanUp,
  callback,
  stopAudio = false,
                                       apiSetup, data
}) {
  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        //prepareAnimation();
        callback();
        animationReturn = animationScript({
          id,
          apiSetup,
          data
        });
        //pause global audio
        if (stopAudio) {
          audioManager.fadeOutAndPause();
        }
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
        if (stopAudio) {
          audioManager.fadeInAndPlay();
        }
      }
    };
  }, [animation, mode]);
}
