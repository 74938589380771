import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import Player from "../../_partials/Player/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

import { engine, fadeIn, slide } from "../../../../../animations/index";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/gameday";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import outroVideo4x5 from "../../../assets/outros/2024/outro_playoffs_4x5.webm";
import outroVideo16x9 from "../../../assets/outros/2024/outro_playoffs_16x9.webm";
import outroVideo9x16 from "../../../assets/outros/2024/outro_playoffs_9x16.webm";

import assets from "./assets";

import frontendCfg from "../../../../../config/frontend";
import { addDays, format as dateFormat } from "date-fns";

import { de } from "date-fns/locale";

import { usePlayoffsVideo } from "../../../../shared/hooks/usePlayoffsVideo";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  // handle outro video BEGIN
  usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        ?.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const thisGame = _.get(dynamic, "thisGame");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDate = new Date(thisGame?.start_date);

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const customBackground = getContentById("background", fields, "self");
  const customBackgroundStyles = _.get(
    customBackground,
    `styles.${format}`,
    {},
  );
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <div
          id={_.get(customBackground, "id", null)}
          style={customBackgroundStyles}
          className={"custom-background-container"}
        >
          <img
            className={"custom-background"}
            src={parseMediaUrl(_.get(customBackground, "value.url"))}
          />
        </div>

        <Backgrounds bg1={backgroundImage} />

        {/*handle outro video BEGIN*/}
        {format === "1920x1080" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo16x9} />
          </video>
        )}

        {format === "1200x1500" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo4x5} />
          </video>
        )}

        {format === "1080x1920" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo9x16} />
          </video>
        )}
        {/*handle outro video END*/}

        <img className={"sponsors sponsor-sap"} src={assets.sap_presents} />
        <img className={"sponsors sponsor-del"} src={assets.penny_del} />

        <div className={"teams-block"}>
          <div className={"team-logo-container home"}>
            <img src={parseMediaUrl(_.get(teamHome, "url", null))} />
          </div>
          <div className={"team-logo-container away"}>
            <img src={parseMediaUrl(_.get(teamAway, "url", null))} />
          </div>
        </div>

        <div className={"team-vs"}>VS</div>

        <div className={"headline"}>
          <div className={"main-line"}>GAMEDAY</div>
          <div className={"ghost-line"}>GAMEDAY</div>
        </div>
        <div className={"gameInfo"}>
          <div className={"date-container"}>
            <div className={"date"}>
              {dateFormat(thisGameDate, "eeeeee", { locale: de })}{" "}
              {dateFormat(thisGameDate, "dd.MM.", { locale: de })}
            </div>
            <div className={"separator"}>|</div>
            <div className={"time"}>
              {String(thisGameDate.getHours()).padStart(2, "0")}:
              {String(thisGameDate.getMinutes()).padStart(2, "0")} UHR
            </div>
          </div>

          <div className={"hashtag"}>
            #{_.get(thisGame, "home.shortcut")}
            <span>VS</span>
            {_.get(thisGame, "guest.shortcut")}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200_${side}`);
  }

  if (format === "1080x1920") {
    return _.get(assets, `mainbackground_1080_${side}`);
  }
};

export default Index;
