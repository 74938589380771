import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  qatar_logo: require("../../../assets/wc/images/sport_world_logo.png"),
  trophy: require("../../../assets/wc/images/trophy.png"),
  insight_zone_logo: require("../../../assets/wc/images/insight_zone_white.png"),
};

export default assets;
