import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, points, data } = props;
  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    event: data?.event
  });
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  const timeline = anime.timeline();

  const pointsStart = 3500;

  anime({
    targets: [
      `#${id} .qatar-logo, .competition-logo-container, .matchday-wrapper`,
    ],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .germany-map`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 1500,
  });

  anime({
    targets: [`#${id} .stadium-pos-left, .stadium-pos-right`],
    duration: 1200,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: 2500,
  });

  points?.forEach((point, index) => {
    anime({
      targets: [`#${id} .points-${index}`],
      duration: 1500,
      easing: "easeOutQuint",
      fill: "#0D3A4B",
      stroke: "#D1F669",
      delay: pointsStart + 1500 * index,
    });
    anime({
      targets: [`#${id} #canvas-${index}`],
      duration: 1500,
      easing: "easeOutQuint",
      fill: "#0D3A4B",
      stroke: "#D1F669",
      opacity: 1,
      delay: pointsStart + 1500 * index,
    });
    anime({
      targets: [`#${id} .match-content-${index}`],
      duration: 1500,
      easing: "easeOutQuint",
      opacity: 1,
      translateY: [-50, 0],
      delay: pointsStart + 1500 * index,
    });
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .lineup-player`],
      duration: 500,
      easing: "easeOutQuint",
      // translateX: [2000, 0],
      opacity: [1, 1],
      scale: [0, 1],
      delay: anime.stagger(80),
    });
  }, 500);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
