import _ from "lodash";

export const getTemplateDurationOveride = ({
  apiSetup,
  tournamentId,
  fallback,
  templateName = "",
  event
}) => {
  const feedType = event?.feedType || 'opta'
  let templateDuration = fallback;
  const templateTimeOverride =
    apiSetup?.timeline?.settings?.timings?.[tournamentId];
  let templateTypeTimeOverride =
    apiSetup?.timeline?.settings?.templatesTimings?.[templateName];

  if(feedType === 'heimspiel') {
    templateTypeTimeOverride = apiSetup?.timeline?.settings?.templatesTimings?.heimspiel?.[templateName] || '15';
  }

  if (templateTimeOverride) {
    if (!["staticmedia", "staticslide"].includes(templateName)) {
      const parsed = parseInt(templateTimeOverride) + 1;
      templateDuration = _.isNaN(parsed) ? fallback : parsed;
    }
  }

  if (templateTypeTimeOverride) {
    if (!["staticmedia", "staticslide"].includes(templateName)) {
      const parsed = parseInt(templateTypeTimeOverride) + 1;
      templateDuration = _.isNaN(parsed) ? fallback : parsed;
    }
  }

  return templateDuration;
};
