import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import { engine, fadeIn, slide } from "../../../../../animations/index";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/livematchday";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "./assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import { useTranslation } from "react-i18next";
import {
  format as dateFormat,
  isAfter,
  isBefore,
  parse,
  parseISO,
  subDays,
} from "date-fns";
import { de, enUS } from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { TeamOutlined, CalendarOutlined } from "@ant-design/icons";
import Image from "../../../../shared/components/Image";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import {
  getDateInZone,
  getTimeInZone,
} from "../../../../shared/utils/dateHelpers";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import { formatDate, formatNumber } from "./helper";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { LiveStatusBadge } from "../../_partials/MatchScoreWidget/LiveStatusBadge";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode, format } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t, i18n } = useTranslation();
  const lng = i18n?.language;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name
        });
      }
    }

    if (mode === "screenshot") {
      // $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
      //   this.currentTime = 3;
      //   this.pause();
      // }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const currentMatchday = _.get(dynamic, "currentMatchday");

  const currentRound = _.get(currentMatchday, "round");
  const matchday = _.get(dynamic, "matchday");
  const apiAssets = _.get(dynamic, "assets");
  // const teamDataStyles = _.get(teamData, "styles", {});
  // const playerData = getContentById('player', fields, "self");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById("background", fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const currentEvent = _.get(data, "event");

  const selectedTeamId = _.get(currentEvent, "selectedTeamId");
  const competitionId = dynamic.matchInfo?.competition?.id;

  const isLeague = _.toLower(dynamic?.matchInfo?.competition?.competitionFormat) === 'domestic league'
  // const firstMatchDate = new Date(
  //   matchday?.[0]?.matchInfo?.localDate +
  //     "T" +
  //     matchday?.[0]?.matchInfo?.localTime +
  //     "Z",
  // );

  const scoreclassName = (matchStatus) => {
    if (matchStatus === "playing") {
      return "score-playing";
    }
    return "";
  };

  const today = new Date();
  const seriesId = _.get(dynamic, "matchInfo.series.id", null);
  const stageName = _.get(dynamic, "matchInfo.stage.name", null);
  const todaysMatches = matchday?.filter((e) => {
    if (e?.matchInfo.localDate) {
      const matchDate = parse(e?.matchInfo.localDate, "yyyy-MM-dd", new Date());
      const formattedMatch = dateFormat(matchDate, "dd-MM-yyyy");
      const formattedNow = dateFormat(today, "dd-MM-yyyy");

      if (formattedMatch === formattedNow) {
        return true;
      }
      return false;
    }
  });

  const todayGameDate = _.get(dynamic, "matchInfo.stage.name", null);

  const sevenDaysAgo = subDays(new Date(), 7);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle/>*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content wc-bg-content--opasity"}></div>

          <img className={"qatar-logo"} src={assets.insight_zone_logo} />
          <div className={"competition-positioner"}>
            <div className={"competition-logo-container"}>
              <Image
                src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
              />
            </div>
            <div className="second-title">
              {dynamic?.matchInfo?.week &&
                (lng === "de" ? (
                  <span>
                    {dynamic.matchInfo.week}. {t("Matchday")}{" "}
                  </span>
                ) : (
                  <span>
                    {t("Matchday")} {dynamic.matchInfo.week}
                  </span>
                ))}

              {!dynamic?.matchInfo?.week && dynamic?.matchInfo?.stage?.name && (
                <span>{t(dynamic.matchInfo.stage?.name)}</span>
              )}
            </div>
          </div>

          <div className="main-title green-glow">{t("Games")}</div>

          <div className={"live-matchdays-positioner"}>
            <div className={"live-matchdays-container"}>
              {_.map(
                (todaysMatches?.length > 0 && !isLeague
                  ? todaysMatches
                  : matchday)?.filter((e) => {
                      const thisSeriesId = e?.matchInfo?.series?.id;
                      const thisStageName = e?.matchInfo?.stage?.name;

                      // if (seriesId && thisSeriesId) {
                      //   return thisSeriesId === seriesId;
                      // }

                      if (matchday.length > 30) {
                        const hasMatchDate =
                          e?.matchInfo?.time && e?.matchInfo?.date;

                        if (!hasMatchDate) {
                          return false;
                        }

                        const matchDateStr = e?.matchInfo?.date;
                        const matchDate = parseISO(matchDateStr);

                        if (
                          isBefore(matchDate, sevenDaysAgo) ||
                          isAfter(matchDate, today)
                        ) {
                          return false;
                        }
                      }

                      return true;
                    }),
                (match, matchIndex) => {
                  const matchInfo = match?.matchInfo || {};
                  const liveData = match?.liveData || {};
                  const liveDataExtra =
                    match?.liveData?.matchDetailsExtra || {};
                  let matchStatus = _.toLower(
                    liveData?.matchDetails?.matchStatus,
                  );

                  const matchDate = new Date(
                    toIsoDateString(matchInfo?.date, matchInfo?.time),
                  );
                  const time = matchInfo?.time
                    ? getTimeInZone(matchDate, lng, true)
                    : "-";
                  const date = matchInfo?.time
                    ? getDateInZone(matchDate, lng)
                    : "-";

                  const home = _.find(matchInfo?.contestant, {
                    position: "home",
                  });
                  const away = _.find(matchInfo?.contestant, {
                    position: "away",
                  });
                  const homeId = _.get(home, "id");
                  const awayId = _.get(away, "id");

                  // const teamHomeAlpha2 = getOptaCountryCode(home?.name, home?.shortName, home?.code);
                  // const teamAwayAlpha2 = getOptaCountryCode(away?.name, away?.shortName, away?.code);

                  const teamHomeAlpha2 = homeId;
                  const teamAwayAlpha2 = awayId;

                  const isAdlerMatch = false;
                  let matchStatusClass = "";
                  let statusString = "";
                  let timeString = "";

                  if (matchStatus === "played") {
                    matchStatusClass = "after";
                    statusString = t("Finished");
                  } else if (matchStatus === "fixture") {
                    matchStatusClass = "before";
                    statusString = <div className="time">{time}</div>;
                  } else if (matchStatus === "playing") {
                    matchStatusClass = "live";
                    statusString = <LiveStatusBadge />;
                  } else if (matchStatus === "postponed") {
                    matchStatusClass = "before";
                    statusString = t("Postponed", { keyPrefix: "templates" });
                  } else {
                    matchStatusClass = "before";
                    statusString = _.upperFirst(matchStatus);
                  }

                  return (
                    <div
                      key={`m-${matchInfo.id}`}
                      className={`match ${isAdlerMatch ? "adler-match" : ""} status-${matchStatusClass}`}
                    >
                      {/*<div className={"match"}>*/}

                      <div className={"home-name"}>
                        {t(home?.shortName || home?.name, { ns: "countries" })}
                      </div>

                      <div className={"team-logo-container home"}>
                        <Image
                          className="team-logo"
                          src={getDynamicLogoPath({
                            team: home,
                            competitionId,
                          })}
                        />
                      </div>

                      <div className={"score-container"}>
                        <div
                          className={`score-data-container ${scoreclassName(matchStatus)}`}
                        >
                          <div className={"score-home"}>
                            {showScoreMinus({ matchStatus })
                              ? "-"
                              : _.get(
                                  liveData,
                                  "matchDetails.scores.total.home",
                                  0,
                                )}
                          </div>
                          <div
                            className={
                              "score-separator score-separator--margin"
                            }
                          >
                            :
                          </div>
                          <div className={"score-away"}>
                            {showScoreMinus({ matchStatus })
                              ? "-"
                              : _.get(
                                  liveData,
                                  "matchDetails.scores.total.away",
                                  0,
                                )}
                          </div>
                        </div>

                        <div className={"match-datetime"}>
                          <div>{statusString}</div>
                        </div>

                        <div
                          className={
                            "match-datetime left-side match-datetime--align"
                          }
                        >
                          <div className={"status-string-date"}>
                            {formatDate(matchDate, lng)}
                          </div>
                        </div>

                        <div className={"attendance-info right-side"}>
                          <TeamOutlined />{" "}
                          {formatNumber(liveDataExtra?.attendance) || "-"}
                        </div>
                      </div>

                      {matchInfo?.series?.name.replace(
                        "Group",
                        t("Group", { keyPrefix: "templates" }),
                      ) && (
                        <div className={"group-info"}>
                          {matchInfo?.series?.name.replace(
                            "Group",
                            t("Group", { keyPrefix: "templates" }),
                          ) || ""}
                        </div>
                      )}

                      <div className={"team-logo-container away"}>
                        <Image
                          className="team-logo"
                          src={getDynamicLogoPath({
                            team: away,
                            competitionId,
                          })}
                        />
                      </div>
                      <div className={"away-name"}>
                        {t(away?.shortName || away?.name, { ns: "countries" })}
                      </div>
                      {/*</div>*/}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

const showScoreMinus = ({ matchStatus }) => {
  return ["fixture", "postponed"].includes(matchStatus);
};

export default Index;
