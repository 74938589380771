import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/opener";

import configFrontend from "../../../../../config/frontend";

import assets from "./assets";
import { format as dateFormat } from "date-fns";

import { de, enUS } from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { utcToZonedTime } from "date-fns-tz";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import {
  getDateInZone,
  getTimeInZone,
} from "../../../../shared/utils/dateHelpers";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const { t, i18n } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const lng = i18n?.language;

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headerEn = getContentById("headline-en", fields);
  const headerDe = getContentById("headline-de", fields);
  const textDe = getContentById("text-de", fields);
  const textEn = getContentById("text-en", fields);
  const openerType = getContentById("openerType", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const event = data?.event;
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  // const teamDataStyles = _.get(teamData, "styles", {});

  // const thisGameDateTmp = new Date(toIsoDateString(matchInfo?.date, matchInfo?.time)) || '';

  // const timeZone = 'Europe/Berlin';
  // const thisGameDate = utcToZonedTime(thisGameDateTmp, timeZone);

  const feedType = _.get(data, "event.feedType", "opta");

  const isValidDate = Boolean(matchInfo?.date);

  const isoMatchDate = toIsoDateString(matchInfo?.date, matchInfo?.time);
  const matchDate = matchInfo?.time
    ? new Date(isoMatchDate)
    : new Date(matchInfo?.date);
  const time = matchInfo?.time ? getTimeInZone(matchDate, lng, true) : "-:-";
  const date = matchInfo?.time ? getDateInZone(matchDate, lng) : "-";
  let competitionId = dynamic?.matchInfo?.competition?.id;

  if (feedType === "heimspiel") {
    competitionId = "olympic_2024";
  }

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];
  const scores = _.get(dynamic, "liveData.matchDetails.scores.total");
  const penalties = _.get(dynamic, "liveData.matchDetails.scores.pen");
  const hasPenalty = _.has(dynamic, "liveData.matchDetails.scores.pen");
  const hasExtraTime =
    _.has(dynamic, "liveData.matchDetails.scores.et") && !hasPenalty;
  const matchStatus = _.toLower(dynamic?.liveData?.matchDetails?.matchStatus);

  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);

  // const teamHomeAlpha2 = configFrontend.flagsCompetitions.includes(event?.competitionId) ? getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code) : homeTeam?.id;
  // const teamAwayAlpha2 = awayTeam?.id || getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code)

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />

          <img className={"qatar-logo"} src={assets.world_logo} />

          <div className={"main-header"}>
            {openerType === "global" ? (
              <>
                <div className={"competition-name-global"}>
                  {!headerEn
                    ? t(
                        matchInfo?.competition?.knownName ||
                          matchInfo?.competition?.name,
                      )
                    : null}
                </div>
                {!headerEn ? (
                  <span className={"green-glow"}>
                    {t("templates.In Numbers")}
                  </span>
                ) : (
                  <span className={"green-glow"}>
                    {lng === "de" ? headerDe : headerEn}
                  </span>
                )}{" "}
                <br />
                {textDe ? (
                  <div
                    className={"extra-custom-multiline"}
                    dangerouslySetInnerHTML={{
                      __html: lng === "de" ? textDe : textEn,
                    }}
                  ></div>
                ) : null}
              </>
            ) : (
              <>
                {/*{t("templates.Your")}*/}
                {/*<br />*/}
                <span className={"green-glow"}>
                  {t("templates.Insight")}
                </span>{" "}
                <span className={"green-glow"}>{t("templates.Zone")}</span>{" "}
                <br />
                {openerType === "customZone" ? (
                  <div className={"competition-name"}>{headerEn}</div>
                ) : (
                  <div className={"competition-name"}>
                    {t(
                      matchInfo?.competition?.knownName ||
                        matchInfo?.competition?.name,
                    )}
                  </div>
                )}
              </>
            )}
            {/*{t("templates.Your")} <br/><span className={"green-glow"}>{t("templates.Insight")}</span><br/> <span*/}
          </div>

          {openerType !== "global" && openerType !== "customZone" ? (
            <div className={"competition-positioner"}>
              <div className={"competition-logo-container"}>
                <Image
                  src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
                />
              </div>
              <div className="second-title">
                {dynamic?.matchInfo?.week &&
                  (lng === "de" ? (
                    <span>
                      {dynamic.matchInfo.week}. {t("Matchday")}{" "}
                    </span>
                  ) : (
                    <span>
                      {t("Matchday")} {dynamic.matchInfo.week}
                    </span>
                  ))}

                {!dynamic?.matchInfo?.week &&
                  dynamic?.matchInfo?.stage?.name && (
                    <span>{t(dynamic.matchInfo.stage?.name)}</span>
                  )}
              </div>
              <div className={"extra-info-line-separator"}></div>
              <div className={"extra-info"}>
                <div className={"gameInfo"}>
                  {isValidDate ? (
                    <div className={"date-container"}>
                      <div className={"date"}>
                        {dateFormat(matchDate, "eee, dd.MM.yyyy", {
                          locale: lng === "de" ? de : enUS,
                        })}
                        ,
                      </div>
                      &nbsp;&nbsp;
                      <div className={"time"}>
                        {time}{" "}
                        {t("TIME", {
                          keyPrefix: "templates",
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={"venue-container"}>
                  {matchInfo?.venue?.longName?.replace(
                    "Stadium",
                    t("Stadium", { keyPrefix: "templates" }),
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={"competition-logo-container-global"}>
              <Image
                src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
              />
            </div>
          )}

          {/*<img className={"powered-by"} src={assets.poweredBy}/>*/}

          {openerType === "global" || openerType === "customZone" ? null : (
            <div className={"info-block"}>
              <div className={"competition-info"}>
                <div>
                  <b>
                    {matchInfo?.series?.name.replace(
                      "Group",
                      t("Group", {
                        keyPrefix: "templates",
                      }),
                    )}
                  </b>
                </div>
              </div>

              <div className={"teams-block"}>
                <div className={"team-vs"}>
                  <div className={"versus"}>{t("templates.vs")}.</div>
                </div>
                <div className={"team-logo-container home"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: homeTeam, competitionId })}
                  />
                  <div className={"team-name home"}>
                    {t(homeTeam?.name, { ns: "countries" })}
                  </div>
                </div>
                <div className={"team-logo-container away"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: awayTeam, competitionId })}
                  />
                  <div className={"team-name away"}>
                    {t(awayTeam?.name, { ns: "countries" })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {openerType !== "global" &&
          openerType !== "customZone" &&
          (matchStatus === "playing" || matchStatus === "played") ? (
            <div className={"score"}>
              <div className={"score-container"}>
                <div className={"score-home"}>
                  {scores?.home || (matchStatus === "fixture" ? "-" : 0)}
                </div>
                <div className={"separator"}>:</div>
                <div className={"score-away"}>
                  {scores?.away || (matchStatus === "fixture" ? "-" : 0)}
                </div>

                <div className={"score-type"}>
                  {_.toLower(matchStatus) === "played"
                    ? (hasPenalty && (
                        <span className={"won-by-status"}>
                          ({t("WonByPenalties")})
                        </span>
                      )) ||
                      (hasExtraTime && (
                        <span className={"won-by-status"}>
                          ({t("WonOnExtraTime")})
                        </span>
                      ))
                    : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
