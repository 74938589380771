import React, { useEffect } from "react";

import _ from "lodash";
import MatchDayOverView2 from "./overview_2";

const Index = (props) => {
  const { data, format } = props;

  const templateData = _.get(data, "data");

  const { dynamic } = templateData;
  const matchdays = _.get(dynamic, "matchdays");
  // const groupByStage = _.groupBy(matchdays, function (m) {
  //   return m?.matchInfo?.stage?.name;
  // });
  //


  return MatchDayOverView2(props);
};

export default Index;
