import React, { useEffect, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

import "./style/style.scss";
import "./style/animation.scss";
import Arrow from "./arrow";
import { getDynamicLogoPath } from "../../../../shared/utils/functions";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { displayDays } from "../../../../shared/utils/dateHelpers";

const getMainBackground = () => {
  return assets.background_1920;
};

export default function Index({ animation, data, mode, format, ...props }) {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { t } = useTranslation();

  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const { medals } = data?.data?.dynamic || {};

  const backgroundImage = getMainBackground(format);

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup: props?.apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const startDateISO = "2024-07-26";
  const endDateISO = "2024-08-11";
  const todayDayNumber = displayDays(startDateISO, endDateISO);

  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className="wc-content wc-content-v2">
          <ProgressBar height="8px" />
          <div className="wc-bg-content"></div>

          <div className="qatar-logo">
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"left-block-side"}>
            <div className={"olympic-matchday"}>
              {t("Day")} {todayDayNumber}
            </div>
            <div className={`competition-logo-container`}>
              <Image
                className={"team-logo"}
                src={`/images/dynamic/soccer/competition/olympic_2024.svg`}
              />
            </div>
          </div>

          <div className={"right-block-side"}>
            <div className="main-header green-glow">
              {t("CountryMedalsHeadline", { keyPrefix: "templates" })}
            </div>

            <div className={"group-table"}>
              <div className={"group-content"}>
                <div className={"table-head"}>
                  <div className={"col-pos col"}></div>
                  <div className={"col-team col"}></div>
                  <div className={"col-points col col-gold"}>
                    <img className={"col-medal-icon"} src={assets.medalGold} />
                  </div>
                  <div className={"col-points col col-silver"}>
                    <img
                      className={"col-medal-icon"}
                      src={assets.medalSilver}
                    />
                  </div>
                  <div className={"col-points col col-bronze"}>
                    <img
                      className={"col-medal-icon"}
                      src={assets.medalBronze}
                    />
                  </div>
                  <div className={"col-points col col-total"}>
                    <img className={"col-medal-icon"} src={assets.medalTotal} />
                  </div>
                </div>
                {medals?.ranking.map((entry, index) => {
                  return (
                    <div key={"table-" + index} className={"table-row"}>
                      <div className={"col-pos col"}>{++index}.</div>
                      <div className={"col-team col"}>
                        <div className={"col-logo"}>
                          <Image
                            className="team-logo"
                            src={getDynamicLogoPath({
                              team: {
                                id: entry?.country?.name,
                                code: entry?.country?.code,
                                name: entry?.country?.name,
                              },
                              competitionId: "olympic_2024",
                              feedType: "heimspiel",
                            })}
                          />
                        </div>
                        {t(entry?.country?.name, {
                          ns: "countries",
                        })}
                      </div>
                      <div className={"col-points col col-gold"}>
                        {entry?.first || 0}
                      </div>
                      <div className={"col-points col col-silver"}>
                        {entry?.second || 0}
                      </div>
                      <div className={"col-points col col-bronze"}>
                        {entry?.third || 0}
                      </div>
                      <div className={"col-points col col-total"}>
                        {Number(entry?.third || 0) +
                          Number(entry?.second || 0) +
                          Number(entry?.first || 0)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
}
