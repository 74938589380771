import React, { useEffect, useLayoutEffect, useState } from "react";
import TimelinePreview from "../../../../shared/components/TimelinePreview/Index";
import { getDynamicTimelineById } from "../../../../../services/frontend/template.service";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SocketProvider } from "../../../../shared/contexts/websocket";

import { debouncedUpdateScale } from "../../../../shared/utils/scalingUtils";
// import Templates from "../../templates/Index";

const handleResize = () => debouncedUpdateScale("root");

export default function PageComponent(props) {
  const { _urlParams } = props;
  const cid = _.get(_urlParams, "cid");
  const tid = _.get(_urlParams, "tid");
  const mid = _.get(_urlParams, "mid");
  const { t, i18n } = useTranslation();
  const lng = i18n?.language;
  const [timelineData, setTimelineData] = useState(null);
  const [timelineStats, setTimelineStats] = useState({});
  const [refreshTimeline, setRefreshTimeline] = useState(false);
  const search = useLocation()?.search;
  const searchParams = new URLSearchParams(search);
  const queryLng = searchParams.get("lng");
  const queryFormat = searchParams.get("format") || "1920x1080";
  const params = {
    cid,
    tid,
    mid,
    lng: queryLng,
    format: queryFormat,
  };

  const refreshTimelineCall = async () => {
    const timelineData = await getDynamicTimelineById(params).catch((err) => {
      console.error("Timeline err", err);
    });

    const apiData = _.get(timelineData, "data", null);
    const newTimelineUuid = apiData?.uuid;
    const currentTimelineUuid = _.get(timelineData, "data", null)?.uuid;

    if (
      newTimelineUuid &&
      currentTimelineUuid &&
      newTimelineUuid !== currentTimelineUuid
    ) {
      window.location.reload();
    }
    setTimelineData(apiData);
  };

  useEffect(() => {
    const asyncCall = async () => {
      await refreshTimelineCall();
    };
    asyncCall();
  }, [tid]);

  useEffect(() => {
    const asyncCall = async () => {
      if (refreshTimeline) {
        setRefreshTimeline(false);
        await refreshTimelineCall();
      }
    };
    asyncCall();
  }, [refreshTimeline]);

  const queryResponsive = new URLSearchParams(useLocation()?.search).get(
    "responsive",
  );

  useEffect(() => {
    if (queryResponsive === "scale" && timelineData) {
      // Call on component mount
      handleResize();

      // Attach the event listener for window resize
      window.addEventListener("resize", handleResize);

      // Add event listener for postMessage from parent
      window.addEventListener("message", handleMessageFromParent);

      // Clean up the event listeners on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("message", handleMessageFromParent);
      };
    }
  }, [queryResponsive, timelineData]);

  const handleMessageFromParent = (event) => {
    console.log("#DEBUG postMessage event", event);
    // Check if the event origin is trusted, if necessary
    // if (event.origin !== 'http://parent-domain.com') {
    //   return;
    // }

    // Check the message content, if needed
    // const data = event.data;
    // if (data.someCondition) {
    //   // Handle the message
    // }

    // Handle the message from the parent
    // Do something with event.data
    // event.data will contain the message sent by the parent
  };

  return (
    <>
      {timelineData && (
        <div
          id="timeline-preview-container"
          style={{ width: "100%", height: "100%" }}
        >
          <div
            style={{
              transform: `scale(var(--timeline-scale))`,
              transformOrigin: "top left",
            }}
          >
            <TimelinePreview
              {...props}
              changeEmitter={(data) => {
                // setTimelineStats({
                //     ...data
                // })
              }}
              previewFormat={queryFormat}
              timelineData={timelineData}
              setRefreshTimeline={setRefreshTimeline}
              animation={true}
            />
          </div>
        </div>
      )}
    </>
  );
}
