export const asyncTimeout = (delay) => {
  let timeoutId = null;
  const promise = new Promise(
    (resolve) => (timeoutId = setTimeout(resolve, delay)),
  );
  promise.clear = () => {
    clearTimeout(timeoutId);
  };
  return promise;
};

export const syncTimeout = (fn, delay) => {
  return setTimeout(() => {
    fn();
  }, delay);
};

export const sleepFn = async function sleep(delay, fn, ...args) {
  await asyncTimeout(delay);
  return fn(...args);
};
