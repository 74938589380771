import { duration } from "moment";
import { clearTimeouts, engine as anime } from "./index";

const main = (props) => {
  const timeouts = [];
  const { id, points } = props;

  const allAnimations = [];
  const delayBetweenTransition = 5500;
  const blurTransition = (
    _class,
    i,
    init = true,
    isLast = false,
    boxPosition,
  ) => {
    const animations = [];
    if (!init) {
      animations.push(
        anime({
          targets: `#${id} .${_class}`,
          duration: 100,
          opacity: 1,
          easing: "linear",
          delay: 500 + i * delayBetweenTransition,
        }),
      );
    }
    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-top`],
        duration: init ? 1000 : 1500,
        filter: "blur(20px)",
        "clip-path": "polygon(0% 0%, 16% 0%, 0% 50%, 0% 0%)",
        opacity: 1,
        easing: "linear",
        delay: 0 + i * delayBetweenTransition,
      }),
    );
    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-bottom`],
        duration: init ? 1000 : 1500,
        filter: "blur(20px)",
        "clip-path": [
          "polygon(114.336% -33.3%, 166.25% -33.3%, 116.25% 133.33%, 61.6666% 133.33%)",
          "polygon(124.336% -33.3%, 166.25% -33.3%, 116.25% 133.33%, 71.6666% 133.33%)",
        ],
        opacity: 1,
        easing: "linear",
        delay: 0 + i * delayBetweenTransition,
      }),
    );

    const boxOpacities = [
      {
        value: 1,
        duration: 0,
        delay: 500 + i * delayBetweenTransition,
      },
    ];

    const boxClipPath = [];
    boxClipPath.push({
      value: [
        "polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)",
        "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ],
      duration: 500,
      delay: 600 + i * delayBetweenTransition,
    });
    if (!isLast) {
      boxClipPath.push({
        value: [
          "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          "polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%)",
        ],
        duration: 500,
        delay: boxPosition === "left" ? 3100 : 3400,
      });
    }

    animations.push(
      anime({
        targets: [`#${id} .${_class} .static-slide-box-container`],
        opacity: boxOpacities,
        "clip-path": boxClipPath,
        easing: "linear",
      }),
    );

    if (isLast) return animations;

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-background-origin`],
        duration: 3000,
        scale: 1.02,
        easing: "linear",
        delay: 1500 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-prev`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 4000 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-prev`],
        duration: 1500,
        "clip-path": [
          "polygon(-20.83% -33.3%, 8.33% -33.3%, -45.916% 133.33%, -70.33% 133.3%)",
          "polygon(173.336% -33.3%, 206.25% -33.3%, 156.25% 133.33%, 121.6666% 133.33%)",
        ],
        easing: "linear",
        delay: 4100 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-green`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 4000 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-green`],
        duration: 1500,
        "clip-path": [
          "polygon(-25.83% -33.3%, -16.65% -33.3%, -66.66% 133.33%, -78.33% 133.3%)",
          "polygon(188.336% -33.3%, 193.336% -33.3%, 141.6666% 133.33%, 132.6666% 133.33%)",
        ],
        easing: "linear",
        delay: 4300 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 4200 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next`],
        duration: 1500,
        "clip-path": [
          "polygon(-75.33% -33.3%, -25.33% -33.3%, -78.33% 133.3%, -128.33% 133.3%)",
          "polygon(138.33% -33.3%, 188.33% -33.3%, 132.33% 133.33%, 82.33% 133.33%)",
        ],
        easing: "linear",
        delay: 4300 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-white`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 5150 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-white`],
        duration: 500,
        "clip-path": [
          "polygon(65% -33.3%, 65% -33.3%, 12% 133%, 12% 133%)",
          "polygon(145% -33.3%, 197% -33.3%, 142% 133%, 90% 133%)",
        ],
        easing: "linear",
        delay: 5300 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next-bottom`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 5200 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next-bottom`],
        duration: 500,
        "clip-path": [
          "polygon(75% -33.3%, 75% -33.3%, 22% 133%, 22% 133%)",
          "polygon(124.336% -33.3%, 166.25% -33.3%, 116.25% 133.33%, 71.6666% 133.33%)",
        ],
        easing: "linear",
        delay: 5350 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next-full`],
        duration: 100,
        opacity: 1,
        easing: "linear",
        delay: 4400 + i * delayBetweenTransition,
      }),
    );

    animations.push(
      anime({
        targets: [`#${id} .${_class} .custom-triangle-transition-next-full`],
        duration: 1700,
        "clip-path": [
          "polygon(-55.83% -33.3%, -25.83% -33.3%, -78.33% 133.3%, -108.33% 133.3%)",
          "polygon(-55.83% -33.3%, 182.336% -33.3%, 112.6666% 133.33%, -108.33%133.33%)",
          // "polygon(-51.83% -33.3%, 139.336% -64.3%, 79.6666% 89.33%, -57.33% 133.33%)",
        ],
        easing: "linear",
        delay: 4500 + i * delayBetweenTransition,
      }),
    );
    return animations;
  };
  points?.forEach((point, i) => {
    const _class = `custom-container-${i}`;
    allAnimations.push(
      ...blurTransition(_class, i, i === 0, i === points.length - 1, point.box),
    );
  });

  return {
    timeouts,
    animations: allAnimations,
  };
};

//clean up function, unmounting
export const animationCleanUp = async (animationReturn) => {
  const { timeouts, animations } = animationReturn;
  clearTimeouts(timeouts);
  animations.forEach((animation) => {
    animation.pause();
  });
};

export default main;
