import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, templateName, data } = props;

  const timeline = anime.timeline();
  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event
  });

  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  $(
    `#${id} .qatar-logo, #${id} .main-headline, #${id} .competition-logo-container, #${id} .player`,
  ).css("opacity", 0);

  anime({
    targets: [`#${id} .competition-logo-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-1000, 0],
    opacity: [1, 1],
    delay: 200,
  });
  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });
  anime({
    targets: [`#${id} .second-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });
  // anime({
  //   targets: [`#${id} .group-label`],
  //   duration: 800,
  //   easing: "easeOutQuint",
  //   translateY: [-1000, 0],
  //   opacity: [1, 1],
  //   delay: 400
  // });

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [1000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .group-table:nth-child(1) .group-content`],
    duration: 1000,
    easing: "easeInQuart",
    opacity: [1, 1],
    translateX: [-2000, 0],
    delay: 600,
  });

  anime({
    targets: [`#${id} .group-table:nth-child(2) .group-content`],
    duration: 1000,
    easing: "easeInQuart",
    opacity: [1, 1],
    translateX: [2000, 0],
    delay: 700,
  });

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
