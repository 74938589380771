import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

export const startAnimation = (props) => {
  const timeouts = [];

  anime({
    targets: [".child-template-container"],
    duration: 1000,
    easing: "easeInOutCubic",
    scale: [1, 0.75],
    // 315, -180 is calculated manually based on 1920 * 1080 screen size.
    translateX: 315,
    translateY: -180,
    position: "absolute",
    top: 0,
    delay: 100,
  });

  return {
    timeline: [],
    timeouts,
  };
};

export const endAnimation = (props) => {
  const timeouts = [];
  const { id } = props;

  anime({
    targets: [".child-template-container"],
    duration: 1000,
    easing: "easeInOutCubic",
    scale: 1,
    translateX: 0,
    translateY: 0
  });

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};
