import React, { useState, useRef, useEffect } from "react";
// import Templates from "../../templates/Index";
import VideoModeWrapper from "../../../shared/components/VideoModeWrapper/Index";
import Loader from "../../../shared/components/Loader";
import Config from "../../../../config/frontend";
import { getSingleTemplate } from "../../../../services/frontend/template.service";
import preloadImages from "../../../shared/functions/preloadImages";
import PlayerFacts from "../../templates/PlayerFacts/Index";
import PlayerStats from "../../templates/PlayerStats/Index";
import PlayerCompare from "../../templates/PlayerCompare/Index";
import TeamFacts from "../../templates/TeamFacts/Index";
import TeamCompare from "../../templates/TeamCompare/Index";

//adler templates
import AdlerStatistics from "../../templates/adler/Statistics/Index";
import AdlerLineup from "../../templates/adler/Lineup/Index";
import AdlerGoal from "../../templates/adler/Goal/Index";
import AdlerGameday from "../../templates/adler/Gameday/Index";
import AdlerResults from "../../templates/adler/Results/Index";
import AdlerTable from "../../templates/adler/Table/Index";
import AdlerReferee from "../../templates/adler/Referee/Index";
import AdlerTeamStats from "../../templates/adler/TeamStats/Index";
import AdlerTopScorer from "../../templates/adler/TopScorer/Index";
import AdlerStartingSix from "../../templates/adler/StartingSix/Index";
import AdlerLiveMatchdays from "../../templates/adler/LiveMatchdays/Index";
import StaticMedia from "../../templates/StaticMedia/Index";
import StaticSlide from "../../templates/StaticSlide/Index";
import AdlerSentence from "../../templates/adler/Sentence/Index";
import AdlerMatchStatsWise from "../../templates/adler/MatchStatsWise/Index";
import AdlerMatchRankWise from "../../templates/adler/MatchRankWise/Index";

//worldcup templates
import WcOpener from "../../templates/worldcup/Opener/Index";
import WcLineup from "../../templates/worldcup/Lineup/Index";
import WcLineupTactics from "../../templates/worldcup/LineupTactics/Index";
import WcTable from "../../templates/worldcup/Table/Index";
import WcNextGame from "../../templates/worldcup/NextGame/Index";
import WcTeamCompare from "../../templates/worldcup/TeamCompare/Index";
import WcTeamCompareSeason from "../../templates/worldcup/TeamCompareSeason/Index";
import WcPlayerCompare from "../../templates/worldcup/PlayerCompare/Index";
import WcPlayerCompareSeason from "../../templates/worldcup/PlayerCompareSeason/Index";
import WcMVP from "../../templates/worldcup/MVP/Index";
import WcTopPerformers from "../../templates/worldcup/TopPerformers/Index";
import WcTournamentRanking from "../../templates/worldcup/TournamentRanking/Index";
import WcFacts from "../../templates/worldcup/Facts/Index";
import WcFactsCuriosity from "../../templates/worldcup/FactsCuriosity/Index";

import WcHeatmap from "../../templates/worldcup/Heatmap/Index";
import WcCustomRanking from "../../templates/worldcup/CustomRanking/Index";
import WcMatchdayOverview from "../../templates/worldcup/MatchdayOverview/Index";
import WcLastGames from "../../templates/worldcup/LastGames/Index";
import WcSeasonTotals from "../../templates/worldcup/SeasonTotals/Index";
import WcAppTeaser from "../../templates/worldcup/AppTeaser/Index";
import WcLiveMatchday from "../../templates/worldcup/LiveMatchday/Index";
import WcSeasonGroupOverview from "../../templates/worldcup/SeasonGroupOverview/Index";
import WcGoalShots from "../../templates/worldcup/GoalShots/Index";
import WcStandings from "../../templates/worldcup/Standings/Index";
import WcAttackingZones from "../../templates/worldcup/AttackingZones/Index";
import WcCountryMedals from "../../templates/worldcup/CountryMedals/Index";
import WcGoal from "../../templates/worldcup/Goal/Index";
import WcStaticFacts from "../../templates/worldcup/StaticFacts/Index";
import TmDeal from "../../templates/transfermarkt/Deal/Index.jsx";
import TmTop10 from "../../templates/transfermarkt/Top10/Index.jsx";

import WcGoalOverview from "../../templates/worldcup/GoalOverview/Index";
import WcCountryStadiums from "../../templates/worldcup/CountryStadiums/Index";
import WcCountryStadiumsVideo from "../../templates/worldcup/CountryStadiumsVideo/Index";

import getImagesToPreload from "../../../shared/functions/getImagesToPreload";
import _ from "lodash";
import { useGlobalFrontendStore } from "../../../../stores/globalFrontendStore";
import { asyncTimeout } from "../../../shared/functions/timeout";
import FrontendConfig from "../../../../config/frontend";
import { getTemplateById } from "../../../../services/backend/api.service";
import frontendCfg from "../../../../config/frontend";

const templatesAssets = {
  adlerresults: require("../../templates/adler/Results/assets").default,
  adlergameday: require("../../templates/adler/Gameday/assets").default,
  adlergoal: require("../../templates/adler/Goal/assets").default,
  adlerstatistics: require("../../templates/adler/Statistics/assets").default,
  adlerlineup: require("../../templates/adler/Lineup/assets").default,
  adlertable: require("../../templates/adler/Table/assets").default,
  adlerreferee: require("../../templates/adler/Referee/assets").default,
  adlerteamstats: require("../../templates/adler/TeamStats/assets").default,
  adlertopscorer: require("../../templates/adler/TopScorer/assets").default,
  adlerstartingsix: require("../../templates/adler/StartingSix/assets").default,
  adlerlivematchdays: require("../../templates/adler/LiveMatchdays/assets")
    .default,
  staticmedia: require("../../templates/StaticMedia/assets").default,
  staticslide: require("../../templates/StaticSlide/assets").default,
  adlersentence: require("../../templates/adler/Sentence/assets").default,
  adlermatchstatswise: require("../../templates/adler/MatchStatsWise/assets")
    .default,
  adlermatchrankwise: require("../../templates/adler/MatchRankWise/assets")
    .default,
  //
  wc_opener: require("../../templates/worldcup/Opener/assets").default,
  wc_lineup: require("../../templates/worldcup/Lineup/assets").default,
  wc_lineuptactics: require("../../templates/worldcup/LineupTactics/assets")
    .default,
  wc_table: require("../../templates/worldcup/Table/assets").default,
  wc_nextgame: require("../../templates/worldcup/NextGame/assets").default,
  wc_teamcompare: require("../../templates/worldcup/TeamCompare/assets")
    .default,
  wc_teamcompare_season:
    require("../../templates/worldcup/TeamCompareSeason/assets").default,
  wc_playercompare: require("../../templates/worldcup/PlayerCompare/assets")
    .default,
  wc_playercompare_season:
    require("../../templates/worldcup/PlayerCompareSeason/assets").default,
  wc_mvp: require("../../templates/worldcup/MVP/assets").default,
  wc_topperformers: require("../../templates/worldcup/TopPerformers/assets")
    .default,
  wc_tournamentranking:
    require("../../templates/worldcup/TournamentRanking/assets").default,
  wc_facts: require("../../templates/worldcup/Facts/assets").default,
  wc_facts_curiosity: require("../../templates/worldcup/FactsCuriosity/assets")
    .default,
  wc_heatmap: require("../../templates/worldcup/Heatmap/assets").default,
  wc_customranking: require("../../templates/worldcup/CustomRanking/assets")
    .default,
  wc_matchdayoverview:
    require("../../templates/worldcup/MatchdayOverview/assets").default,
  wc_lastgames: require("../../templates/worldcup/LastGames/assets").default,
  wc_seasontotals: require("../../templates/worldcup/SeasonTotals/assets")
    .default,
  wc_appteaser: require("../../templates/worldcup/AppTeaser/assets").default,
  wc_livematchday: require("../../templates/worldcup/LiveMatchday/assets")
    .default,
  wc_seasongroupoverview:
    require("../../templates/worldcup/SeasonGroupOverview/assets").default,
  wc_goalshots: require("../../templates/worldcup/GoalShots/assets").default,
  wc_standings: require("../../templates/worldcup/Standings/assets").default,
  wc_attacking_zones: require("../../templates/worldcup/AttackingZones/assets")
    .default,
  wc_goal: require("../../templates/worldcup/Goal/assets").default,
  wc_static_facts: require("../../templates/worldcup/StaticFacts/assets")
    .default,
  wc_goal_overview: require("../../templates/worldcup/GoalOverview/assets")
    .default,
  wc_countrystadiums: require("../../templates/worldcup/CountryStadiums/assets")
    .default,
  wc_countrystadiums_video:
    require("../../templates/worldcup/CountryStadiumsVideo/assets").default,
  wc_country_medals: require("../../templates/worldcup/CountryMedals/assets")
    .default,
};

export const templates = {
  // Lineup,
  // PlayerCompare,
  playerfacts: PlayerFacts,
  playerstats: PlayerStats,
  playercompare: PlayerCompare,
  teamfacts: TeamFacts,
  teamcompare: TeamCompare,
  //adler templates
  adlerstatistics: AdlerStatistics,
  adlerlineup: AdlerLineup,
  adlergoal: AdlerGoal,
  adlergameday: AdlerGameday,
  adlerresults: AdlerResults,
  adlertable: AdlerTable,
  adlerreferee: AdlerReferee,
  adlerteamstats: AdlerTeamStats,
  adlertopscorer: AdlerTopScorer,
  adlerstartingsix: AdlerStartingSix,
  adlerlivematchdays: AdlerLiveMatchdays,
  staticmedia: StaticMedia,
  staticslide: StaticSlide,
  adlersentence: AdlerSentence,
  adlermatchstatswise: AdlerMatchStatsWise,
  adlermatchrankwise: AdlerMatchRankWise,
  //worldcup templates
  wc_opener: WcOpener,
  wc_lineup: WcLineup,
  wc_lineuptactics: WcLineupTactics,
  wc_table: WcTable,
  wc_nextgame: WcNextGame,
  wc_teamcompare: WcTeamCompare,
  wc_teamcompare_season: WcTeamCompareSeason,
  wc_playercompare: WcPlayerCompare,
  wc_playercompare_season: WcPlayerCompareSeason,
  wc_mvp: WcMVP,
  wc_topperformers: WcTopPerformers,
  wc_tournamentranking: WcTournamentRanking,
  wc_facts: WcFacts,
  wc_facts_curiosity: WcFactsCuriosity,
  wc_heatmap: WcHeatmap,
  wc_customranking: WcCustomRanking,
  wc_matchdayoverview: WcMatchdayOverview,
  wc_lastgames: WcLastGames,
  wc_seasontotals: WcSeasonTotals,
  wc_appteaser: WcAppTeaser,
  wc_livematchday: WcLiveMatchday,
  wc_seasongroupoverview: WcSeasonGroupOverview,
  wc_goalshots: WcGoalShots,
  wc_standings: WcStandings,
  wc_attacking_zones: WcAttackingZones,
  wc_goal: WcGoal,
  wc_static_facts: WcStaticFacts,
  wc_goal_overview: WcGoalOverview,
  wc_countrystadiums: WcCountryStadiums,
  wc_countrystadiums_video: WcCountryStadiumsVideo,
  wc_country_medals: WcCountryMedals,
  tm_deal: TmDeal,
  tm_top_10: TmTop10,
};

export default function PageComponent(props) {
  const { mode, _urlParams, _searchParams } = props;
  const apiSetup = useGlobalFrontendStore((state) => state.apiSetup);

  const [showTemplateVideo, setShowTemplateVideo] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [templateData, setTemplateData] = useState(null);

  const [videoShowStart, setVideoShowStart] = useState(true);
  const [videoShowEnd, setVideoShowEnd] = useState(false);
  const [formatSupported, setFormatSupported] = useState(true);

  const { id, template } = _urlParams;
  const { format } = _searchParams;

  const TemplateComponent = templates[template];

  const templateAssets = _.get(templatesAssets, template, null);
  let formatClass = format ? `${format}` : `1920x1080`;

  let Images = [];

  //get single template data
  useEffect(() => {
    const asyncCall = async () => {
      if (!_.isEmpty(apiSetup)) {
        const templatesSetup = _.get(apiSetup, `templates`, []);
        const templateFormats = _.get(
          _.find(templatesSetup, { name: template }),
          "formats",
          [],
        );

        if (format && templateFormats.indexOf(format) === -1) {
          setFormatSupported(false);
        }

        if (templateData === null) {
          const data = await getTemplateById({
            id,
            template_name: template,
          }).catch((err) => {
            console.error("err", err);
          });

          const apiData = _.get(data, "data", null);

          const conditionalAssets =
            templateAssets && typeof templateAssets._conditional === "function"
              ? templateAssets._conditional({
                  apiData,
                  format: formatClass,
                })
              : templateAssets;

          Images = getImagesToPreload(apiData, conditionalAssets);

          setTemplateData(apiData);

          const promises = preloadImages(Images);
          await Promise.all([promises]);
          setImagesLoaded(true);
        }
      }
    };
    asyncCall();
  }, [templateData, apiSetup]);

  useEffect(() => {
    const asyncCall = async () => {
      if (mode === "video" && imagesLoaded) {
        let templateDuration = _.get(
          _.find(_.get(templateData, "data.options", []), {
            id: "videoduration",
          }),
          "value",
          FrontendConfig.templateDuration,
        );

        if (frontendCfg.testMode) {
          templateDuration = frontendCfg.testData.templateDuration;
        }

        await asyncTimeout(FrontendConfig.videoBeginColorDuration);
        setShowTemplateVideo(true);
        await asyncTimeout(200);
        setVideoShowStart(false);
        await asyncTimeout(templateDuration * 1000);
        setVideoShowEnd(true);
        setShowTemplateVideo(false);
        await asyncTimeout(FrontendConfig.videoEndColorDuration);
        setVideoShowEnd(false);
      }
    };
    asyncCall();
  }, [imagesLoaded]);

  if (!formatSupported) {
    return (
      <div className={"format-not-supported"}>
        Format {format} is not supported for this template ({template})
      </div>
    );
  }

  if (mode === "video") {
    return (
      <React.Fragment>
        <VideoModeWrapper showStart={videoShowStart} showEnd={videoShowEnd} />
        {/*<VideoModeWrapper showTemplate={showTemplate} imagesLoaded={imagesLoaded} setShowTemplate={setShowTemplate}/>*/}
        <div className={"template-container video-mode"}>
          {/*{showTemplate && <Templates imagesLoaded={imagesLoaded}  setImagesLoaded={setImagesLoaded} {...props} />}*/}
          {showTemplateVideo && (
            <TemplateComponent
              {...props}
              format={formatClass}
              images={Images}
              apiSetup={apiSetup}
              data={templateData}
            />
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/*{!imagesLoaded ? <Loader type={"fullscreen"}/> : <div className={"template-container"}>*/}
        {/*        {templateData && <TemplateComponent format={formatClass} images={Images} apiSetup={apiSetup} data={templateData} {...props}  />}*/}
        {/*    </div>}*/}

        {!imagesLoaded ? null : (
          <div className={"template-container"}>
            {templateData && (
              <TemplateComponent
                {...props}
                format={formatClass}
                images={Images}
                apiSetup={apiSetup}
                data={templateData}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
